import React, { ChangeEvent, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import CartMap from "./CartMap";
import { Projects } from "../models/types";
import InputTextField from "./TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleBig: {
      marginBottom: 2,
      height: "31px",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      color: "#333333",
      textAlign: "left",
    },
    selectField: {
      width: "100%",
      height: "42px",
      fontSize: "16px",
      backgroundColor: "#ffffff",
      border: "1px solid #e3e3e3",
      margin: "0px",
      paddingLeft: "20px",
      fontWeight: "bold",
    },
  })
);

interface IProps {
  formState: any;
  projects: Projects[];
  handleFormChange: (e: ChangeEvent<HTMLInputElement>) => any;
  handleMapChange: (address: any) => any;
  handleSelectChange: (e: any) => any;
}

const CartTransport: React.FC<IProps> = ({
  formState,
  projects,
  handleFormChange,
  handleMapChange,
  handleSelectChange,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Grid md={12} className={classes.titleBig}>
        <FormattedMessage id="cart.transport" />
      </Grid>
      <Grid xs={12} md={12}>
        <Box pt={2} pb={2}>
          <InputTextField
            name="notes"
            title={intl.formatMessage({ id: "cart.notes" })}
            value={formState.notes || ""}
            formChange={handleFormChange}
          />
          </Box>
      </Grid>
      <RadioGroup
        row
        name="transportType"
        onChange={handleFormChange}
        value={formState.transportType}
      >
        <FormControlLabel
          value="PICKUP"
          id="pickup"
          control={<Radio />}
          label="Saņemt STATS filiālē"
        />
        <FormControlLabel
          value="DELIVERY"
          id="delivery"
          control={<Radio />}
          label="Piegāde uz adresi"
        />
      </RadioGroup>
      <Grid xs={12} md={12}>
        {formState.transportType === "PICKUP" ? (
          <Select
            value={formState.office}
            onChange={handleSelectChange}
            className={classes.selectField}
            name="office"
            id="office"
            variant="outlined"
          >
            <MenuItem value="RĪGA, KROŅU IELA 11">RĪGA, KROŅU IELA 11</MenuItem>
            <MenuItem value="RĪGA, KARLA ULMANA GATVE 82">
              RĪGA, KARLA ULMANA GATVE 82
            </MenuItem>
            <MenuItem value="JELGAVA, AVIACIJAS IELA 18A">
              JELGAVA, AVIACIJAS IELA 18A
            </MenuItem>
            <MenuItem value="VALMIERA, PATVERSMES IELA 9">
              VALMIERA, PATVERSMES IELA 9
            </MenuItem>
          </Select>
        ) : (
          <CartMap
            formState={formState}
            projects={projects}
            handleMapChange={handleMapChange}
          />
        )}
      </Grid>
    </>
  );
};

export default CartTransport;
