import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import LocalHelmet from "./Helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    largeTitle: {
      paddingBottom: 15,
      fontWeight: "bold",
      fontSize: "25px",
    },
    text: {
      fontSize: "16px",
      lineHeight: "25px",
      textAlign: "left",
    },
    banner: {
      width: "100%",
    },
    about: {
      textAlign: "left",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      color: "#333333",
    },
  })
);

const About: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box alignItems="center" display="block">
      <LocalHelmet title={intl.formatMessage({ id: "main" })} />
      <img
        src="/images/about/header.png"
        className={classes.banner}
        alt="Stats Rent"
      />
      <Box className={classes.about}>
        <Grid container className={classes.about} spacing={9}>
          <Grid item xs={12} sm={6}>
            <Box pb={10} className={classes.text}>
              <FormattedMessage id="about.10" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img src="/images/about/stats-rent-logo.png" alt="" width="100%" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <img src="/images/about/stats-rent.png" alt="" width="100%" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box pt={4} className={classes.text}>
              <b>
                <FormattedMessage id="about.1" />
              </b>
              <br />
              <br />
              <FormattedMessage id="about.2" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <span className={classes.text}>
              <b>
                <FormattedMessage id="about.3" />
              </b>
              <br />
              <br />
              <FormattedMessage id="about.4" />
              <br />
              <br />
              <FormattedMessage id="about.5" />
              <br />
              <br />
              <FormattedMessage id="about.6" />
            </span>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img src="/images/about/our-clients.png" alt="" width="100%" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <img src="/images/about/stats-rent-volvo.png" alt="" width="100%" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box pt={4} className={classes.text}>
              <b>
                <FormattedMessage id="about.7" />
              </b>
              <br />
              <br />
              <FormattedMessage id="about.8" />
              <br />
              <br />
              <FormattedMessage id="about.9" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {};
};

export default connect(null, mapDispatchToProps)(About);
