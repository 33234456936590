import React, { ChangeEvent, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Grid, MenuItem, Select } from "@material-ui/core";
import InputTextField from "./TextField";
import { registerLocale } from "react-datepicker";
import "./styles/datepicker.css";
import lv from "date-fns/locale/lv";
import { FormattedMessage, useIntl } from "react-intl";
import { Employee } from "../models/types";
import { useDropzone } from "react-dropzone";

registerLocale("lv", {
  ...lv,
  options: {
    weekStartsOn: 1,
    lastDayOfWeek: 0,
    firstWeekContainsDate: 1,
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxLine: {
      border: "1px solid #ebdcc6",
      borderRadius: "4px",
      boxShadow: "none",
      padding: 10,
      backgroundColor: "#ffffff",
    },
    boxTitle: {
      paddingBottom: 10,
      paddingTop: 10,
      fontWeight: "bold",
      fontSize: "18px",
    },
    buttonBox: {
      paddingTop: "20px",
      textAlign: "right",
    },
    button: {
      width: "250px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    dateInput: {
      padding: theme.spacing(1.2, 1.5, 1.5, 0),
      transition: theme.transitions.create("width"),
      paddingLeft: `calc(0.1em + ${theme.spacing(1)}px)`,
      width: "94%",
      borders: "0px",
      background: "#ffffff",
      borderTop: "0px",
      borderRight: "0px",
      borderLeft: "0px",
      fontSize: "16px",
      color: "#e3e3e3",
      borderBottomColor: "#e3e3e3",
    },
    selectField: {
      width: "100%",
      height: "38px",
      fontSize: "16px",
      paddingLeft: "8px",
      backgroundColor: "#ffffff",
      border: "1px solid #e3e3e3",
      margin: "0px",
      "&$focused $notchedOutline": {
        border: "1px solid #e3e3e3",
      },
      "& label.Mui-focused": {
        color: "#333333",
        backgroundColor: "#ffffff",
      },
      "& label": {
        color: "#333333",
        backgroundColor: "#ffffff",
      },
    },
    focused: {
      border: "1px solid #000000",
      margin: "0px",
    },
    outlinedInput: {
      "&$focused $notchedOutline": {
        border: "1px solid #e3e3e3",
      },
    },
    notchedOutline: {},
    labelError: {
      color: "red",
      alignSelf: "center",
      fontSize: "12px",
    },
    uploadZone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: "2px",
      borderRadius: "2px",
      borderColor: "#e3e3e3",
      borderStyle: "dashed",
      background: "#f9f9f9",
      color: "#333333",
      outline: "none",
      transition: "border .24s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
        background: "#cccccc",
      },
    },
    thumbsContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 16,
    },
    thumb: {
      display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: "border-box",
    },
    thumbInner: {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
    },
    img: {
      display: "block",
      width: "auto",
      height: "100%",
    },
  })
);

interface IProps {
  formState: any;
  formErrors: any;
  employees: Employee[];
  employeesLoading: boolean;
  formChange: (e: ChangeEvent<HTMLInputElement>) => any;
  selectChange: (index: number) => any;
  handleFiles: (files: any) => any;
}

const RepairForm: React.FC<IProps> = ({
  formState,
  formErrors,
  employees,
  employeesLoading,
  formChange,
  selectChange,
  handleFiles,
}) => {
  useEffect(() => {}, []);

  const classes = useStyles();
  const intl = useIntl();

  const handleSelectChange = (e: any) => {
    const { value } = e.target;
    selectChange(value);
  };

  const [filePreview, setFilePreview] = useState([]);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      onDrop: (acceptedFiles) => {
        var nextFiles = filePreview;
        var fileList = formState?.files || [];
        acceptedFiles.map((file) => {
          fileList.push(file);
        });
        handleFiles(fileList);
        acceptedFiles.map((file) => {
          nextFiles.push({ preview: URL.createObjectURL(file) });
        });
        setFilePreview(nextFiles);
      },
    });

  const thumbs = filePreview.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <img
          src={file.preview}
          className={classes.img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
          alt={file.name}
        />
      </div>
    </div>
  ));

  return (
    <Box m={3} justifyContent="center">
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} md={8}>
          <InputTextField
            name="companyName"
            title={intl.formatMessage({ id: "repairForm.companyName" })}
            value={formState.companyName || ""}
            error={formErrors.comapnyName}
            formChange={formChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputTextField
            name="registrationNumber"
            title={intl.formatMessage({ id: "repairForm.registrationNumber" })}
            value={formState.registrationNumber || ""}
            error={formErrors.registrationNumber}
            formChange={formChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {!employeesLoading && employees.length > 0 ? (
            <Select
              value={formState.contactsId}
              onChange={handleSelectChange}
              className={classes.selectField}
              name="contactsId"
              id="contactsId"
              placeholder={intl.formatMessage({ id: "repairForm.contacts" })}
              variant="outlined"
            >
              {employees?.map((employee, index) => (
                <MenuItem value={index} id={String(index)} key={index}>
                  {employee?.name + " " + employee?.surname}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <InputTextField
              name="contacts"
              title={intl.formatMessage({ id: "repairForm.contacts" })}
              value={formState.contacts || ""}
              error={formErrors.contacts}
              formChange={formChange}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <InputTextField
            name="phone"
            title={intl.formatMessage({ id: "repairForm.phone" })}
            value={formState.phone || ""}
            error={formErrors.phone}
            formChange={formChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputTextField
            name="email"
            title={intl.formatMessage({ id: "repairForm.email" })}
            value={formState.email || ""}
            error={formErrors.email}
            formChange={formChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <b>
            <FormattedMessage id="repairForm.details" />
          </b>
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            name="brand"
            title={intl.formatMessage({ id: "repairForm.brand" })}
            value={formState.brand || ""}
            error={formErrors.brand}
            formChange={formChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            name="model"
            title={intl.formatMessage({ id: "repairForm.model" })}
            value={formState.model || ""}
            error={formErrors.model}
            formChange={formChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            name="year"
            title={intl.formatMessage({ id: "repairForm.year" })}
            value={formState.year || ""}
            error={formErrors.year}
            formChange={formChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            name="vin"
            title={intl.formatMessage({ id: "repairForm.vin" })}
            value={formState.vin || ""}
            error={formErrors.vin}
            formChange={formChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <InputTextField
            name="description"
            title={intl.formatMessage({ id: "repairForm.description" })}
            value={formState.description || ""}
            error={formErrors.description}
            formChange={formChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box p={4}>
            <section>
              <div {...getRootProps({ className: classes.uploadZone })}>
                <input {...getInputProps()} />
                <p>
                  <FormattedMessage id="profile.uploadImages" />
                </p>
              </div>
              <aside className={classes.thumbsContainer}>{thumbs}</aside>
            </section>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RepairForm;
