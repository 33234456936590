/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Employee,
  MenuTextData,
  Service,
  UserData,
  UserProfile,
} from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import RepairForm from "./RepairForm";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  getEmployees,
  getOrderStatusList,
  getUserServices,
  saveService,
} from "../store/users/action";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import ProfileNoRows from "./ProfileNoRows";
import ProfileSpinner from "./ProfileSpinner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    accordionDetails: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      paddingBottom: "20px",
    },
    accordionDetailsOpen: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      paddingBottom: "20px",
      borderBottom: "1px solid #E3E3E3",
    },
    heading: {
      flexBasis: "100%",
      flexShrink: 0,
      fontSize: "14px",
      fontWeight: "bold",
      color: "#333333",
    },
    titleBig: {
      marginBottom: 2,
      height: "auto",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      paddingTop: "10px",
      paddingBottom: "10px",
      color: "#333333",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    tableHeader: {
      borderBottom: "1px solid #E3E3E3",
      padding: "0px",
    },
    tableHeaderOpen: {
      padding: "0px",
    },
    tableTitle: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
    },
    tableText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      padding: "6px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "14px",
        padding: "2px",
      },
    },
    statusGreen: {
      background: "#D7EC84",
      fontSize: "12px",
      padding: "6px",
    },
    statusRed: {
      background: "#EC574E",
      fontSize: "12px",
      padding: "6px",
      color: "#ffffff",
    },
    statusGrey: {
      backgroundColor: "#E3E3E3 !important",
      fontSize: "12px",
      padding: "6px",
    },
    orderLink: {
      fontSize: "18px",
      lineHeight: "20px",
      textDecorationLine: "underline",
      color: "#333333",
      padding: "6px",
      textAlign: "right",
    },
    openLink: {
      textAlign: "right",
    },
    buttonEnd: {
      textAlign: "end",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    button: {
      width: "200px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        width: "150px",
        fontSize: "14px",
        padding: "5px",
      },
    },
    checkbox: {
      color: "#333333",
    },
    buttonStart: {
      textAlign: "start",
      alignSelf: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    back: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    backIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    title: {
      marginBottom: 2,
      minHeight: "29px",
      fontSize: "14px",
      letterSpacing: "0.25px",
      color: "#333333",
      textAlign: "left",
    },
    hr: {
      border: "none",
      height: "1px",
      color: "#e3e3e3",
      backgroundColor: "#e3e3e3",
    },
  })
);

interface propsFromDispatch {
  getServices: () => any;
  getEmployees: () => any;
  getOrderStatusList: () => any;
  saveService: (payload: any, formData: FormData) => any;
}

interface PropsFromState {
  service: Service[];
  serviceLoading: boolean;
  employees: Employee[];
  employeesLoading: boolean;
  orderStatusList: MenuTextData;
  language: string;
}

interface IProps {
  profile: UserProfile;
  user: UserData;
}

type AllProps = IProps & PropsFromState & propsFromDispatch;

const ProfileService: React.FC<AllProps> = ({
  profile,
  user,
  service,
  serviceLoading,
  employees,
  employeesLoading,
  orderStatusList,
  language,
  getServices,
  getEmployees,
  getOrderStatusList,
  saveService,
}) => {
  const [values, setValues] = useState({});
  const [errors] = useState({});
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [newService, setNewService] = React.useState<boolean | false>(false);

  useEffect(() => {
    getServices();
    getEmployees();
    getOrderStatusList();
  }, []);

  useEffect(() => {
    setValues({
      registrationNumber: profile.registrationNumber || "",
      companyName: profile.contractCode || "",
    });
  }, [profile]);

  useEffect(() => {
    if (employees) {
      var index = employees.findIndex((employee) => {
        return employee.email === user.profile.email;
      });
      setValues({
        ...values,
        contacts: employees[index]?.name + " " + employees[index]?.surname,
        phone: employees[index]?.phone || "",
        email: employees[index]?.email || "",
        contactsId: index,
      });
    }
  }, [user, employees]);

  const handlePanelChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const submitServiceRequest = () => {
    let formData: any = values;
    let payload = {
      companyName: formData.companyName,
      registrationNumber: formData.registrationNumber,
      contacts: formData.contacts,
      phone: formData.phone,
      email: formData.email,
      brand: formData.brand,
      model: formData.model,
      year: formData.year,
      vin: formData.vin,
      description: formData.description,
    };
    let formFileData = new FormData();
    if (formData?.files) {
      for (let i = 0; i < formData.files.length; i++) {
        formFileData.append(`files`, formData.files[i]);
      }
    }
    saveService(payload, formFileData);
    setNewService(false);
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setValues({ ...values, [name]: value });
  };

  const handleFormFileChange = (files: any) => {
    setValues({
      ...values,
      files: files,
    });
  };

  const handleSelectChange = (index: number) => {
    setValues({
      ...values,
      contacts: employees[index].name + " " + employees[index].surname,
      phone: employees[index].phone || "",
      email: employees[index].email || "",
      contactsId: index,
    });
  };

  const classes = useStyles();
  const intl = useIntl();

  return !newService ? (
    <Grid container>
      <Grid item xs={6} md={6} className={classes.titleBig}>
        <FormattedMessage id="profile.myService" />
      </Grid>
      <Grid item xs={6} md={6} className={classes.buttonEnd}>
        <Button
          variant="contained"
          size="large"
          className={classes.button}
          onClick={() => setNewService(true)}
        >
          <FormattedMessage id="profile.newService" />
        </Button>
      </Grid>
      {service?.length > 0 ? (
        <Hidden smDown>
          <Grid container className={classes.tableHeader}>
            <Grid item md={2}>
              <Typography className={classes.tableTitle}>
                <FormattedMessage id="service.number" />
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.tableTitle}>
                <FormattedMessage id="service.receiveDate" />
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className={classes.tableTitle}>
                <FormattedMessage id="service.status" />
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.tableTitle}>
                <FormattedMessage id="service.responsiblePerson" />
              </Typography>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </Hidden>
      ) : null}
      <Grid md={12} className={classes.titleBig}>
        {!serviceLoading ? (
          service?.length > 0 ? (
            service?.map((service, index) => (
              <Accordion
                className={classes.accordion}
                expanded={expanded === service.service_id}
                onChange={handlePanelChange(service.service_id)}
                key={index}
              >
                <AccordionSummary
                  id={service.service_id}
                  className={
                    expanded === service.service_id
                      ? classes.tableHeaderOpen
                      : classes.tableHeader
                  }
                >
                  <Grid container>
                    <Grid item md={2} xs={4}>
                      <Typography className={classes.tableText}>
                        {service.orderNumber}
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={3}>
                      <Typography className={classes.tableText}>
                        {service.creationDate}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={1}
                      xs={3}
                      className={
                        service.serviceStatus === "000000001"
                          ? classes.statusGrey
                          : service.serviceStatus === "000000004"
                          ? classes.statusRed
                          : classes.statusGreen
                      }
                    >
                      {orderStatusList[service.serviceStatus]?.value[language]}
                    </Grid>

                    <Hidden smDown>
                      <Grid item md={1}></Grid>
                      <Grid item md={2}>
                        <Typography className={classes.tableText}>
                          {service.responsiblePerson}
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Grid item md={3} xs={2} className={classes.openLink}>
                      {expanded === service.service_id ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === service.service_id
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Grid container>
                    <Hidden smDown>
                      <Grid item md={12}>
                        <Grid container>
                          <Grid item md={2}>
                            <Typography className={classes.tableTitle}>
                              <FormattedMessage id="service.type" />
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography className={classes.tableTitle}>
                              <FormattedMessage id="service.datesApprox" />
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography className={classes.tableTitle}>
                              <FormattedMessage id="service.datesReal" />
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography className={classes.tableTitle}>
                              <FormattedMessage id="service.problem" />
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography className={classes.tableTitle}>
                              <FormattedMessage id="service.defectReport" />
                            </Typography>
                          </Grid>
                          <Grid md={12}>
                            <hr className={classes.hr} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Hidden>
                    <Grid item md={12}>
                      <Grid container spacing={1}>
                        <Grid item md={2} xs={12}>
                          <Typography className={classes.title}>
                            {service.brand}
                          </Typography>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          {service.plannedDateTo ? (
                            <Typography className={classes.title}>
                              {service.plannedDateFrom +
                                " - " +
                                service.plannedDateTo}
                            </Typography>
                          ) : (
                            <Typography className={classes.title}>
                              <FormattedMessage id="service.receiveDateNotKnown" />
                            </Typography>
                          )}
                        </Grid>
                        <Grid item md={2} xs={12}>
                          {service.dateFrom ? (
                            <Typography className={classes.title}>
                              {service.dateFrom + " - " + service.dateTo}
                            </Typography>
                          ) : (
                            <Typography className={classes.title}>-</Typography>
                          )}
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <Typography className={classes.title}>
                            {service.description}
                          </Typography>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          {service.defectDocument ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={
                                process.env.REACT_APP_IMAGE_HOST +
                                "/files/contract_files/" +
                                service.defectDocument
                              }
                              className={classes.orderLink}
                            >
                              <FormattedMessage id="service.defectAct" />
                            </a>
                          ) : (
                            <Typography className={classes.title}>
                              <FormattedMessage id="service.defectActNA" />
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <ProfileNoRows
              text={intl.formatMessage({ id: "profile.noOrders" })}
            />
          )
        ) : (
          <ProfileSpinner />
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid container>
      <Grid md={12} className={classes.titleBig}>
        <FormattedMessage id="profile.newService" />
      </Grid>
      <Grid item md={12}>
        <RepairForm
          employees={employees}
          employeesLoading={employeesLoading}
          formState={values}
          formErrors={errors}
          formChange={handleFormChange}
          selectChange={handleSelectChange}
          handleFiles={handleFormFileChange}
        />
        <Grid container>
          <Grid item md={6} className={classes.buttonStart}>
            <span
              onClick={() => setNewService(false)}
              className={classes.backIcon}
            >
              {" "}
              &lt;{" "}
            </span>
            <span onClick={() => setNewService(false)} className={classes.back}>
              <FormattedMessage id="back" />
            </span>
          </Grid>
          <Grid item md={6} className={classes.buttonEnd}>
            <Button
              variant="contained"
              onClick={() => submitServiceRequest()}
              size="large"
              className={classes.button}
            >
              <FormattedMessage id="send" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ user, language }: ApplicationState) => ({
  service: user.profile.service,
  serviceLoading: user.serviceLoading,
  employees: user.profile.employees,
  employeesLoading: user.employeeLoading,
  language: language.language,
  orderStatusList: user.orderStatusList,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getServices: () => {
      dispatch(getUserServices());
    },
    saveService: (payload: any, formData: FormData) => {
      dispatch(saveService(payload, formData));
    },
    getOrderStatusList: () => {
      dispatch(getOrderStatusList());
    },
    getEmployees: () => {
      dispatch(getEmployees());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileService);
