import React, { ChangeEvent, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: "99%",
      fontSize: "16px",
      backgroundColor: "#ffffff",
      border: "1px solid #e3e3e3",
      margin: "0px",
      paddingTop: "8px",
      paddingLeft: "8px",
      paddingBottom: "8px",
      color: "#333333",
      "&$focused $notchedOutline": {
        border: "1px solid #e3e3e3",
      },
      "& label.Mui-focused": {
        color: "#333333",
        backgroundColor: "#ffffff",
      },
      "& label": {
        color: "#333333",
        backgroundColor: "#ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    labelError: {
      color: "red",
      alignSelf: "center",
      fontSize: "12px",
    },
  })
);

interface IProps {
  name: string;
  title?: string;
  value: string;
  isPassword?: boolean;
  fieldError?: boolean;
  required?: boolean;
  error?: string;
  enabled?: boolean;
  formChange: (e: ChangeEvent<HTMLInputElement>) => any;
}

const InputTextField: React.FC<IProps> = ({
  name,
  title,
  value,
  isPassword = false,
  fieldError = false,
  required = false,
  error,
  enabled = true,
  formChange,
}) => {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <>
      <TextField
        id={name}
        name={name}
        className={classes.textField}
        onChange={formChange}
        value={value || ""}
        disabled={enabled ? false : true}
        required={required}
        type={isPassword ? "password" : "text"}
        margin="normal"
        size="small"
        variant="outlined"
        label={title || ""}
        error={fieldError}
      />
      {error ? (
        <span className={classes.labelError}>
          <FormattedMessage id={error} />
        </span>
      ) : null}
    </>
  );
};

export default InputTextField;
