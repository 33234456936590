import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Typography,
  Hidden,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { ProductsData } from "../models/types";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 2,
      height: "24px",
      fontSize: "16px",
      weight: 400,
      color: "#333333",
      textAlign: "center",
    },
    titleBig: {
      marginBottom: 2,
      height: "31px",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      color: "#333333",
      textAlign: "left",
    },
    hr: {
      border: "none",
      height: "1px",
      color: "#e3e3e3",
      backgroundColor: "#e3e3e3",
    },
    button: {
      width: "20px",
      height: "20px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    plusButton: {
      width: "30px",
      height: "30px",
      backgroundColor: "#da2232",
      borderRadius: "0px",
      color: "#ffffff",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    minusButton: {
      width: "30px",
      height: "30px",
      backgroundColor: "#da2232",
      borderRadius: "0px",
      color: "#ffffff",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    counterButton: {
      width: "50px",
      height: "30px",
      border: "1px solid transparent",
      outline: "none",
    },
    gridAlign: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    red: {
      color: "#EC574E",
      fontSize: "30px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
  })
);

interface IProps {
  productList?: ProductsData[];
  language: string;
  deleteItem: (id?: number) => any;
  setCartItemQuantityUp: (cartId?: number) => any;
  setCartItemQuantityDown: (cartId?: number) => any;
}

const CartProductList: React.FC<IProps> = ({
  productList,
  language,
  deleteItem,
  setCartItemQuantityUp,
  setCartItemQuantityDown,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid md={12} className={classes.titleBig}>
        <FormattedMessage id="cart" />
      </Grid>
      <Hidden smDown>
        <Grid md={12}>
          <Grid container>
            <Grid md={2}></Grid>
            <Grid item md={2}></Grid>
            <Grid item md={4}>
              <Box textAlign="center">
                <FormattedMessage id="cart.period" />
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box textAlign="center">
                <FormattedMessage id="cart.quantity" />
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box textAlign="center">
                <FormattedMessage id="cart.withVAT" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      {productList?.map((product) => (
        <>
          <Grid md={12}>
            <hr className={classes.hr} />
          </Grid>
          <Grid md={12}>
            <Grid container>
              <Grid xs={12} md={2} className={classes.gridAlign}>
                <img
                  src={
                    process.env.REACT_APP_IMAGE_HOST +
                      "/files/product_images/" +
                      product?.image[0].img || ""
                  }
                  alt={product?.title[language]}
                  width="70%"
                />
              </Grid>
              <Grid xs={12} md={2} className={classes.gridAlign}>
                {product?.title[language] ? (
                  <Typography className={classes.title}>
                    <b>{product?.title[language] || ""}</b>
                  </Typography>
                ) : null}
              </Grid>
              <Grid xs={12} md={4} className={classes.gridAlign}>
                {product?.startDate ? (
                  <Typography className={classes.title}>
                    {product?.startDate} - {product?.endDate} ({product?.period}{" "}
                    dienas)
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={6} md={2}>
                <Box textAlign="center">
                  <ButtonGroup>
                    <Button
                      className={classes.minusButton}
                      onClick={() => setCartItemQuantityDown(product?.cartId)}
                    >
                      -
                    </Button>
                    <Button className={classes.counterButton}>
                      {product.orderQuantity}
                    </Button>
                    <Button
                      className={classes.plusButton}
                      onClick={() => setCartItemQuantityUp(product?.cartId)}
                    >
                      +
                    </Button>
                  </ButtonGroup>
                </Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <Grid container>
                  <Grid item xs={12}>
                    {product?.totalProduct ? (
                      <Typography className={classes.title}>
                        <b>€ {product?.totalProductVAT}</b>
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      textAlign="center"
                      onClick={() => deleteItem(product?.cartId)}
                    >
                      <DeleteForeverIcon className={classes.red} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ))}
      <Grid md={12}>
        <hr className={classes.hr} />
      </Grid>
    </>
  );
};

export default CartProductList;
