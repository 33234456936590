import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import LocalHelmet from "./Helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    largeTitle: {
      paddingBottom: 15,
      fontWeight: "bold",
      fontSize: "25px",
    },
    text: {
      fontSize: "16px",
      lineHeight: "25px",
      textAlign: "left",
    },
    banner: {
      width: "100%",
    },
    about: {
      textAlign: "left",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      paddingTop: "15px",
      paddingBottom: "30px",
      color: "#333333",
      fontSize: "16px",
      lineHeight: "25px",
    },
  })
);

const Repair: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box alignItems="center" display="block">
      <LocalHelmet title={intl.formatMessage({ id: "repair.title" })} />
      <img
        src="/images/repair/header.png"
        className={classes.banner}
        alt={intl.formatMessage({ id: "repair.title" })}
      />
      <Box p={4}>
        <Box className={classes.about}>
          <b>
            <FormattedMessage id="repair.1" />
          </b>
          <br />
          <FormattedMessage id="repair.2" />
          <br />
          <FormattedMessage id="repair.3" />
          <br />
          <FormattedMessage id="repair.4" />
          <br />
          <br />
          <b>
            <FormattedMessage id="repair.13" />
          </b>
          <br />
          <FormattedMessage id="repair.14" />
          <br />
          <FormattedMessage id="repair.15" />
          <br />
          <FormattedMessage id="repair.16" />
          <br />
          <br />
          <b>
            <FormattedMessage id="repair.5" />
          </b>
          <br />
          <FormattedMessage id="repair.6" />
          <br />
          <br />
          <b>
            <FormattedMessage id="repair.7" />
          </b>
          <br />
          <FormattedMessage id="repair.8" />
          <br />
          <br />
          <b>
            <FormattedMessage id="repair.9" />
          </b>
          <br />
          <FormattedMessage id="repair.10" />
          <br />
          <br />
          <b>
            <FormattedMessage id="repair.11" />
          </b>
          <br />
          <FormattedMessage id="repair.12" />
          <br />
          <br />
        </Box>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {};
};

export default connect(null, mapDispatchToProps)(Repair);
