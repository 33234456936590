import React from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs';
import { TransitionProps } from '@material-ui/core/transitions';
import { Box } from "@material-ui/core";
import { MenuData, MenuTextData } from "../models/types";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Link } from "react-router-dom";
import { saveTreeState } from "./../store/menu/action";
import { useHistory } from "react-router-dom";

const StyledTreeItem = withStyles((theme: Theme) =>
    createStyles({
        iconContainer: {
            '& .close': {
                opacity: 0.3,
            },
        },
        group: {
            marginLeft: 7,
            paddingLeft: 18,
        },
    }),
)((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles(
    createStyles({
        root: {
            flexGrow: 1,
            maxWidth: '300px',
        },
        link: {
            color: "#000000",
            textDecoration: "none"
        },
        linkSelected: {
            color: "#DA2232",
            textDecoration: "none",
            fontWeight: "bolder"
        }
    }),
);

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

interface IProps {
    categoryId?: string
  }
  
interface PropsFromState {
    language: string;
    loading: boolean;
    errors?: string;
    menu: MenuData[];
    text: MenuTextData;
    treeState: string[];
    breadCrumb: string[];
}

interface propsFromDispatch {
    saveTreeState: (treeState: string[]) => any;
}

type AllProps = PropsFromState & propsFromDispatch & IProps;

const ProductTree: React.FC<AllProps> = ({
    language,
    loading,
    menu,
    text,
    treeState,
    breadCrumb,
    saveTreeState
}) => {

    let history = useHistory();

    const onNodeToggle = (node: any, isExpanded: string[]) => {
        saveTreeState(isExpanded)
    };

    const openLink = (code: string) => {
        history.push("/product/category/"+code);
    }

    const classes = useStyles();

    const renderTree = (menu: MenuData, text: MenuTextData, language: string) => (
        (Array.isArray(menu.children) && menu.children?.length) ? (
            <StyledTreeItem key={menu.code} nodeId={menu.code} className={breadCrumb.indexOf(menu.code) > -1 ? classes.linkSelected : classes.link} label={text[menu.code]?.value[language]} onClick={() => openLink(menu.code)}>
                {Array.isArray(menu.children) ? menu.children.map((menu) => renderTree(menu, text, language)) : null}
            </StyledTreeItem>
        ) : (
                <Link key={'L'+menu.code} to={"/product/category/" + menu.code} >
                    <StyledTreeItem key={menu.code} className={breadCrumb.indexOf(menu.code) > -1 ? classes.linkSelected : classes.link} nodeId={menu.code} label={text[menu.code]?.value[language]} />
                </Link>
            )
    );

    return (
        <>
            { !loading ? (
                <Box pl={2} pb={2}>
                    <TreeView
                        className={classes.root}
                        expanded={treeState}
                        onNodeToggle={onNodeToggle}
                    >
                        {menu.map(codes => (renderTree(codes, text, language)))}
                    </TreeView>
                </Box>
            ) : (
                    null
                )}
        </>
    );
};

const mapStateToProps = ({ language, menu }: ApplicationState) => ({
    language: language.language,
    loading: menu.loading,
    errors: menu.errors,
    breadCrumb: menu.breadcrumb,
    menu: menu.menu,
    text: menu.text,
    treeState: menu.treeState
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        saveTreeState: (treeState: string[]) => {
          dispatch(saveTreeState(treeState));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTree);
