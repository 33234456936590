/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import Login from "./Login";
import { Cart, Projects, UserProfile } from "../models/types";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  deleteCartItem,
  setCartItemQuantityDown,
  setCartItemQuantityUp,
  submitCart,
} from "../store/users/action";
import CartTransport from "./CartTransport";
import CartProductList from "./CartProductList";
import CartBilling from "./CartBilling";
import CartPayments from "./CartPayments";
import Cart404 from "./Cart404";
import CartSuccess from "./CartSuccess";
import LocalHelmet from "./Helmet";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    container: {
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    },
    heading: {
      flexBasis: "100%",
      flexShrink: 0,
      fontSize: "14px",
      fontWeight: "bold",
      color: "black",
    },
    cancelBox: {
      paddingTop: "20px",
      textAlign: "left",
    },
    title: {
      marginBottom: 2,
      height: "24px",
      fontSize: "16px",
      weight: 400,
      color: "#333333",
      textAlign: "center",
      textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    titleBig: {
      marginBottom: 2,
      height: "31px",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      color: "#333333",
      textAlign: "left",
    },
    titleSmall: {
      marginBottom: 2,
      height: "31px",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "31px",
      color: "#333333",
      textAlign: "left",
      paddingLeft: "10px",
    },
    summaryLeft: {
      marginBottom: 2,
      height: "36px",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "31px",
      color: "#333333",
      textAlign: "left",
      paddingLeft: "10px",
    },
    summaryRight: {
      marginBottom: 2,
      height: "36px",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "31px",
      color: "#333333",
      textAlign: "right",
      paddingLeft: "10px",
    },
    totalLeft: {
      marginBottom: 2,
      height: "36px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "31px",
      color: "#333333",
      textAlign: "left",
      paddingLeft: "10px",
    },
    totalRight: {
      marginBottom: 2,
      height: "36px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "31px",
      color: "#333333",
      textAlign: "right",
      paddingLeft: "10px",
    },
    infoLeft: {
      marginBottom: 2,
      height: "36px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "31px",
      color: "#333333",
      textAlign: "center",
      paddingLeft: "10px",
    },
    infoRight: {
      marginBottom: 2,
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "18px",
      color: "#333333",
      textAlign: "center",
      paddingBottom: "20px",
    },
    total: {
      border: "1px solid #e3e3e3",
      backgroundColor: "#f9f9f9",
      height: "100%",
      fontSize: "18px",
      color: "#333333",
    },
    link: {
      color: "#da2232",
      textDecoration: "underline",
    },
    buttonBox: {
      paddingTop: "50px",
    },
    button: {
      width: "100%",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    hr: {
      border: "none",
      height: "1px",
      color: "#e3e3e3",
      backgroundColor: "#e3e3e3",
    },
    price: {
      paddingTop: "20px",
      marginBottom: 5,
      fontSize: "18px",
      fontWeight: "bold",
      color: "#597eb2",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    buttonText: {
      fontSize: "14px",
      fontWeight: "bold",
      paddingLeft: "10px",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(${theme.spacing(2)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    back: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    backIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    selectField: {
      width: "100%",
      height: "42px",
      fontSize: "16px",
      backgroundColor: "#ffffff",
      border: "1px solid #e3e3e3",
      margin: "0px",
    },
  })
);

interface PropsFromState {
  cart: Cart;
  profile: UserProfile;
  guest: boolean;
  language: string;
  projects: Projects[];
  projectsLoading: boolean;
}

interface propsFromDispatch {
  deleteCartItem: (id?: number) => any;
  setCartItemQuantityUp: (cartId?: number) => any;
  setCartItemQuantityDown: (cartId?: number) => any;
  submitCart: (data: any) => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const UserCart: React.FC<AllProps> = ({
  cart,
  profile,
  guest,
  language,
  projects,
  projectsLoading,
  deleteCartItem,
  setCartItemQuantityUp,
  setCartItemQuantityDown,
  submitCart,
}) => {
  const classes = useStyles();
  const [isLoginOpen, handleLogin] = useState(false);
  const [formState, setFormState] = useState<any>({
    transportType: "PICKUP",
    clientType: "business",
    step: "step1",
    office: "RĪGA, KROŅU IELA 11",
    delivery: 0,
  });
  const [formErrors] = useState<any>();
  let history = useHistory();

  useEffect(() => {
    if (formState.transportType === "delivery")
      setFormState({ ...formState, delivery: 20 });
    else setFormState({ ...formState, delivery: 0 });
  }, [formState.transportType]);

  useEffect(() => {
    if (formState.step === "step4") {
      let request = {
        address: formState.address,
        lat: formState.lat,
        lng: formState.lng,
        transportType: formState.transportType,
        office: formState.office,
        delivery: formState.delivery,
        notes: formState.notes,
      };
      submitCart(request);
      setFormState({ ...formState, step: "step5" });
    }
  }, [formState.step]);

  const deleteItem = (id?: number) => {
    deleteCartItem(id);
  };

  const onNext = (step: string) => {
    setFormState({ ...formState, step: step });
    window.scrollTo(0, 0);
  };
  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const popupHandler = () => {
    handleLogin((current) => !current);
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value, type } = e.currentTarget;
    const setValue = type === "checkbox" ? checked : value;
    setFormState({ ...formState, [name]: setValue });
  };

  const goBack = () => {
    history.goBack();
    window.scrollTo(0, 0);
  };
  const handleMapChange = (address: any) => {
    setFormState({
      ...formState,
      address: address.address,
      lng: address.lng,
      lat: address.lat,
    });
  };

  const getTotalAmount = (totalVAT: number, delivery: number) => {
    return (Number(totalVAT) + Number(delivery)).toFixed(2);
  };

  return (
    <Box m={3} justifyContent="center">
      <LocalHelmet title="STATS Rent - Grozs" />
      <Box className={classes.container}>
        {formState.step === "step5" ? (
          <CartSuccess />
        ) : cart && cart.count > 0 ? (
          <>
            <Grid container spacing={2} direction="row" justify="center">
              <Grid item md={9}>
                <Grid container direction="row"  justify="center">
                  {formState.step === "step1" ? (
                    <CartProductList
                      productList={cart.products}
                      language={language}
                      deleteItem={deleteItem}
                      setCartItemQuantityUp={setCartItemQuantityUp}
                      setCartItemQuantityDown={setCartItemQuantityDown}
                    />
                  ) : null}

                  {formState.step === "step2" ? (
                    <CartTransport
                      formState={formState}
                      projects={projects}
                      handleFormChange={handleFormChange}
                      handleMapChange={handleMapChange}
                      handleSelectChange={handleSelectChange}
                    />
                  ) : null}

                  {formState.step === "step3" ? (
                    <CartBilling
                      formState={formState}
                      formErrors={formErrors}
                      handleFormChange={handleFormChange}
                      onNext={onNext}
                    />
                  ) : null}

                  {formState.step === "step4" ? (
                    <CartPayments
                      formState={formState}
                      handleFormChange={handleFormChange}
                      onNext={onNext}
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} className={classes.total}>
                <Grid container style={{ height: "100%" }}>
                  <Grid item xs={12} md={12}>
                    <span className={classes.titleBig}>
                      <FormattedMessage id="cart.1" />
                    </span>
                    <span className={classes.titleSmall}>
                      {cart?.count} <FormattedMessage id="cart.2" />
                    </span>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <hr className={classes.hr} />
                  </Grid>
                  <Grid className={classes.summaryLeft} item xs={6} md={6}>
                    <FormattedMessage id="cart.3" />
                  </Grid>
                  <Grid className={classes.summaryRight} item xs={6} md={6}>
                    € {cart.total}
                  </Grid>
                  {cart?.delivery ? (
                    <>
                      <Grid className={classes.summaryLeft} item xs={6} md={6}>
                        Transports
                      </Grid>
                      <Grid className={classes.summaryRight} item xs={6} md={6}>
                        € {cart?.delivery?.price}
                      </Grid>
                    </>
                  ) : null}
                  <Grid className={classes.summaryLeft} item xs={6} md={6}>
                    <FormattedMessage id="cart.4" />
                  </Grid>
                  <Grid className={classes.summaryRight} item xs={6} md={6}>
                    € {((cart?.totalVAT || 0) - (cart?.total || 0)).toFixed(2)}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <hr className={classes.hr} />
                  </Grid>
                  <Grid className={classes.totalLeft} item xs={6} md={6}>
                    <FormattedMessage id="cart.1" />
                  </Grid>
                  <Grid className={classes.totalRight} item xs={6} md={6}>
                    €{" "}
                    {cart?.delivery?.price > 0
                      ? getTotalAmount(
                          cart.totalVAT || 0,
                          cart.delivery.price || 0
                        )
                      : cart.totalVAT}
                  </Grid>
                  {!guest ? (
                    <>
                      <Grid className={classes.infoLeft} item xs={2} md={2}>
                        <img src="/images/info.svg" alt="information" />
                      </Grid>
                      <Grid className={classes.infoRight} item xs={10} md={10}>
                        <Login
                          isLoginOpen={isLoginOpen}
                          closeHandler={popupHandler}
                        />
                        <FormattedMessage id="cart.6" />
                        <span className={classes.link} onClick={popupHandler}>
                          <FormattedMessage id="cart.7" />
                        </span>
                        .
                      </Grid>
                    </>
                  ) : null}
                  <Grid className={classes.buttonBox} item xs={12} md={12}>
                    {formState.step === "step1" ? (
                      <Button
                        variant="contained"
                        onClick={() => onNext("step2")}
                        size="small"
                        className={classes.button}
                      >
                        <FormattedMessage id="cart.8" />
                      </Button>
                    ) : null}

                    {formState.step === "step2" && guest ? (
                      <Button
                        variant="contained"
                        onClick={() => onNext("step4")}
                        size="small"
                        className={classes.button}
                      >
                        <FormattedMessage id="cart.9" />
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={12}>
                <Box className={classes.cancelBox}>
                  <span onClick={() => goBack()} className={classes.backIcon}>
                    {" "}
                    &lt;{" "}
                  </span>
                  <span onClick={() => goBack()} className={classes.back}>
                    <FormattedMessage id="cart.10" />
                  </span>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Cart404 />
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ user, language }: ApplicationState) => ({
  cart: user.cart,
  guest: user.login,
  profile: user.profile,
  language: language.language,
  projects: user.profile.projects,
  projectsLoading: user.projectsLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    deleteCartItem: (id?: number) => {
      dispatch(deleteCartItem(id));
    },
    setCartItemQuantityUp: (cartId?: number) => {
      dispatch(setCartItemQuantityUp(cartId));
    },
    setCartItemQuantityDown: (cartId?: number) => {
      dispatch(setCartItemQuantityDown(cartId));
    },
    submitCart: (data: any) => {
      dispatch(submitCart(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCart);
