import React, { ChangeEvent, useState } from "react";
import { Employee, SelectData } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Button, Checkbox, Grid, Typography } from "@material-ui/core";
import InputTextField from "./TextField";
import { deleteEmployee, updateEmployee } from "../store/users/action";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { FormattedMessage, useIntl } from "react-intl";
import InputPasswordField from "./PasswordField";
import InputSelectField from "./SelectField";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import lv from "date-fns/locale/lv";
import { getDay } from "date-fns";

registerLocale("lv", {
  ...lv,
  options: {
    weekStartsOn: 1,
    lastDayOfWeek: 0,
    firstWeekContainsDate: 1,
  },
});

const isWeekday = (date: Date) => {
  const day: number = getDay(date);
  return day !== 0;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectField: {
      width: "100%",
      height: "39px",
      fontSize: "16px",
      backgroundColor: "#ffffff",
      border: "1px solid #e3e3e3",
      paddingLeft: "10px",
      margin: "0px",
    },
    checkbox: {
      color: "#333333",
    },
    buttonEnd: {
      textAlign: "end",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    buttonStart: {
      textAlign: "start",
      alignSelf: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    button: {
      width: "120px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    buttonDelete: {
      width: "120px",
      backgroundColor: "#333333",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#000000",
        color: "#ffffff",
      },
    },
    back: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    backIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    inputInput: {
      padding: theme.spacing(2, 1.5, 1.5, 0),
      transition: theme.transitions.create("width"),
      paddingLeft: `calc(0.1em + ${theme.spacing(1)}px)`,
      width: "94%",
      borders: "0px",
      background: "#ffffff",
      borderTop: "0px",
      borderRight: "0px",
      borderLeft: "0px",
      fontSize: "16px",
      color: "#333333",
      borderBottomColor: "1px solid #e3e3e3",
    },
    text: {
      color: "#333333",
    },
    updated: {
      marginBottom: 5,
      fontSize: "18px",
      fontWeight: "bold",
      color: "#da2232",
      textAlign: "center",
    },
  })
);

interface IProps {
  employee?: any;
  type?: string;
  roles: SelectData[];
  newEmployee: (status: any) => any;
}

interface propsFromDispatch {
  updateEmployee: (payload: any) => any;
  deleteEmployee: (id: number) => any;
}

type AllProps = IProps & propsFromDispatch;

const ProfileEmployeesEdit: React.FC<AllProps> = ({
  employee,
  type,
  roles,
  newEmployee,
  updateEmployee,
  deleteEmployee,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [formState, setFormState] = useState<Employee>(employee || {});
  const [formError, setFormErrors] = useState<any>({});
  const [startDate, setStartDateForm] = useState<Date>();
  const [endDate, setEndDateForm] = useState<Date>();
  const [changesSaved, setChangesSaved] = useState(undefined);

  const saveEmployee = () => {
    if (validateForm()) {
      let employee = {
        id: formState.id,
        name: formState.name,
        surname: formState.surname,
        password: formState.password,
        ssn: formState.ssn,
        email: formState.email,
        phone: formState.phone,
        role: formState.role,
        active: formState.active,
        accountant: formState.accountant,
        max_limit: formState.maxLimit,
        activeFrom: formState.activeFrom,
        activeTo: formState.activeTo,
      };
      updateEmployee(employee);

      if (type === "new") newEmployee(false);
      setChangesSaved(intl.formatMessage({ id: "profile.updated" }));
      setTimeout(() => setChangesSaved(undefined), 5000);
    }
  };

  const setStartDate = (startDate: Date) => {
    setStartDateForm(startDate);
    setFormState({
      ...formState,
      activeFrom: moment(startDate).format("YYYY-MM-DD"),
    });
  };

  const setEndDate = (endDate: Date) => {
    setEndDateForm(endDate);
    setFormState({
      ...formState,
      activeTo: moment(endDate).format("YYYY-MM-DD"),
    });
  };

  const validateForm = () => {
    let formData: any = formState;
    let formIsValid = true;
    let error = {};

    //SSN
    if (!formData.ssn) {
      formIsValid = false;
      error = { ...error, ssn: "field.empty" };
    }

    //Role
    if (!formData.role) {
      formIsValid = false;
      error = { ...error, role: "field.empty" };
    }

    //Email
    if (!formData.email) {
      formIsValid = false;
      error = { ...error, email: "field.empty" };
    }

    if (typeof formData.email !== "undefined") {
      let lastAtPos = formData.email.lastIndexOf("@");
      let lastDotPos = formData.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          formData.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          formData.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        error = { ...error, email: "field.emailInvalid" };
      }
    }

    //Phone
    if (!formData.phone) {
      formIsValid = false;
      error = { ...error, phone: "field.empty" };
    }

    if (typeof formData.phone !== "undefined") {
      if (!formData.phone.match(/^[0-9]+$/)) {
        formIsValid = false;
        error = { ...error, phone: "field.numbers" };
      }
    }
    setFormErrors({ ...error });
    return formIsValid;
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value, type } = e.currentTarget;
    const setValue = type === "checkbox" ? checked : value;
    let employee = {
      ...formState,
      [name]: setValue,
    };
    setFormState({ ...employee });
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <Box p={2}>
      <Grid container spacing={5} direction="row" justify="center">
        <Grid item xs={12} md={12}>
          {changesSaved ? (
            <Box pt={2} textAlign="center">
              <Typography className={classes.updated}>
                {changesSaved}
              </Typography>
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            name="name"
            title={intl.formatMessage({ id: "register.name" })}
            value={formState?.name}
            error={formError.name}
            enabled={true}
            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
              handleFormChange(ev)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            name="surname"
            title={intl.formatMessage({ id: "register.surname" })}
            value={formState?.surname}
            error={formError.surname}
            enabled={true}
            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
              handleFormChange(ev)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            name="ssn"
            title={intl.formatMessage({ id: "profile.employeeSSN" })}
            value={formState?.ssn}
            error={formError.ssn}
            enabled={true}
            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
              handleFormChange(ev)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            name="email"
            title={intl.formatMessage({ id: "profile.employeeEmail" })}
            value={formState?.email}
            error={formError.email}
            enabled={true}
            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
              handleFormChange(ev)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            name="phone"
            title={intl.formatMessage({ id: "profile.employeePhone" })}
            value={formState?.phone}
            error={formError.phone}
            enabled={true}
            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
              handleFormChange(ev)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputPasswordField
            name="password"
            title={intl.formatMessage({ id: "profile.password" })}
            value={formState?.password || ""}
            error={formError.password}
            enabled={true}
            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
              handleFormChange(ev)
            }
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.text}>
          <InputSelectField
            name="role"
            title={intl.formatMessage({ id: "profile.employeeLevel" })}
            list={roles}
            value={formState?.role}
            error={formError.role}
            formChange={handleSelectChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            name="maxLimit"
            title={intl.formatMessage({ id: "profile.maxLimit" })}
            value={formState?.maxLimit || ""}
            error={formError.maxLimit}
            enabled={true}
            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
              handleFormChange(ev)
            }
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.text}>
          {intl.formatMessage({ id: "profile.dateFrom" })}
          <DatePicker
            selected={
              startDate || formState?.activeFrom
                ? moment(formState?.activeFrom, "yyyy-MM-DD").toDate()
                : undefined
            }
            onChange={(date: Date) => setStartDate(date)}
            selectsStart
            locale={lv}
            minDate={moment().toDate()}
            filterDate={isWeekday}
            dateFormat="dd.MM.yyyy"
            placeholderText={intl.formatMessage({ id: "profile.activeFrom" })}
            monthsShown={1}
            className={classes.inputInput}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.text}>
          {intl.formatMessage({ id: "profile.dateTo" })}
          <DatePicker
            selected={
              endDate || formState?.activeTo
                ? moment(formState?.activeTo, "yyyy-MM-DD").toDate()
                : undefined
            }
            onChange={(date: Date) => setEndDate(date)}
            selectsEnd
            monthsShown={2}
            locale={lv}
            startDate={
              formState?.activeFrom
                ? moment(formState?.activeFrom, "yyyy-MM-dd").toDate()
                : undefined
            }
            minDate={startDate || moment().toDate()}
            filterDate={isWeekday}
            dateFormat="dd.MM.yyyy"
            placeholderText={intl.formatMessage({ id: "profile.activeTo" })}
            className={classes.inputInput}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.text}>
          <Checkbox
            checked={formState?.accountant}
            onChange={(ev: ChangeEvent<HTMLInputElement>): void =>
              handleFormChange(ev)
            }
            name="accountant"
            id="accountant"
            className={classes.checkbox}
            inputProps={{ "aria-label": "primary checkbox" }}
          />{" "}
          <FormattedMessage id="profile.userAccountant" />
        </Grid>
        <Grid item xs={12} md={6} className={classes.text}>
          <Checkbox
            checked={formState?.active}
            onChange={(ev: ChangeEvent<HTMLInputElement>): void =>
              handleFormChange(ev)
            }
            name="active"
            id="active"
            className={classes.checkbox}
            inputProps={{ "aria-label": "primary checkbox" }}
          />{" "}
          <FormattedMessage id="profile.userActive" />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item md={6} className={classes.buttonStart}>
              <span
                onClick={() => newEmployee(false)}
                className={classes.backIcon}
              >
                {" "}
                &lt;{" "}
              </span>
              <span onClick={() => newEmployee(false)} className={classes.back}>
                <FormattedMessage id="back" />
              </span>
            </Grid>
            <Grid item md={6} className={classes.buttonEnd}>
              {type !== "new" ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => deleteEmployee(formState?.id)}
                    size="large"
                    className={classes.buttonDelete}
                  >
                    <FormattedMessage id="delete" />
                  </Button>
                  &nbsp;
                </>
              ) : null}
              <Button
                variant="contained"
                onClick={() => saveEmployee()}
                size="large"
                className={classes.button}
              >
                <FormattedMessage id="save" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    updateEmployee: (payload: any) => {
      dispatch(updateEmployee(payload));
    },
    deleteEmployee: (id: number) => {
      dispatch(deleteEmployee(id));
    },
  };
};

export default connect(null, mapDispatchToProps)(ProfileEmployeesEdit);
