import React, { ChangeEvent, useEffect, useState } from "react";
import { GVP, GVPForm } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  getGVP,
  getGVPStatus,
  setGVPEdit,
  updateGVP,
} from "../store/users/action";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ApplicationState } from "../store";
import { FormattedMessage, useIntl } from "react-intl";
import InputTextField from "./TextField";
import { Document, Page } from "react-pdf";
import ProfileDocumentViewer from "./ProfileDocumentViewer";
import InfoIcon from "@material-ui/icons/Info";
import ProfileSignDocument from "./ProfileSignDocument";
import { sendSign } from "../store/sign/action";
import { theme } from "../theme/retrostyle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      flexBasis: "100%",
      flexShrink: 0,
      fontSize: "14px",
      fontWeight: "bold",
      color: "#333333",
    },
    titleBig: {
      marginBottom: 2,
      fontWeight: 600,
      padding: "3rem",
      color: "#333333",
      textAlign: "center",
    },
    tableHeader: {
      borderBottom: "1px solid #E3E3E3",
      padding: "0px",
    },
    tableTitle: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
    },
    tableText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#333333",
    },
    statusGreen: {
      background: "#D7EC84",
      fontSize: "12px",
      padding: "6px",
    },
    statusRed: {
      background: "#EC574E",
      fontSize: "12px",
      padding: "6px",
      color: "#ffffff",
    },
    statusGrey: {
      backgroundColor: "#E3E3E3 !important",
      fontSize: "12px",
      padding: "6px",
    },
    button: {
      width: "300px",
      backgroundColor: "#da2232",
      margin: "10px",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    buttonInfo: {
      width: "37px",
      backgroundColor: "#ffffff",
      margin: "10px",
      color: "#333333",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#ffffff",
        color: "#333333",
      },
    },
    menuButtonDark: {
      width: "300px",
      backgroundColor: "#333333",
      color: "#ffffff",
      border: "0px",
      margin: "10px",
      borderRadius: "0px",
      textDecoration: "none",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#333333",
        color: "#ffffff",
      },
    },
    checkbox: {
      color: "#333333",
    },
    back: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    backIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    title: {
      marginBottom: 2,
      height: "29px",
      fontSize: "14px",
      letterSpacing: "0.25px",
      color: "#333333",
      textAlign: "left",
    },
    hr: {
      border: "none",
      height: "1px",
      color: "#e3e3e3",
      backgroundColor: "#e3e3e3",
    },
    gContainer: {
      minHeight: "70vh",
    },
  })
);

interface propsFromDispatch {
  getGVP: () => any;
  getGVPStatus: () => any;
  setGVPEdit: () => any;
  updateGVP: (payload: any) => any;
}
interface PropsFromState {
  gvp?: GVP;
  gvpLoading: boolean;
  login: boolean;
}

type AllProps = PropsFromState & propsFromDispatch;

const ProfileGVP: React.FC<AllProps> = ({
  gvp,
  gvpLoading,
  login,
  getGVP,
  setGVPEdit,
  getGVPStatus,
  updateGVP,
}) => {
  const classes = useStyles();
  const [loop, setLoop] = useState(0);
  const [formError, setFormErrors] = useState<any>({});
  const [formState, setFormState] = useState<GVP>({});
  const [isDocumentOpen, setIsDocument] = useState(false);
  const [signDocument, setSignDocument] = useState(false);
  const [hash, setHash] = useState("");

  useEffect(() => {
    getGVP();
  }, []);

  useEffect(() => {
    if (gvp) setFormState(gvp);
  }, [gvp]);

  useEffect(() => {
    if (gvp?.status === "REPORT" && login) {
      setLoop(
        window.setInterval(() => {
          getGVPStatus();
        }, 5000)
      );
    } else {
      clearInterval(loop);
    }
  }, [gvp?.status, login]);

  const handleFormChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { checked, name, value, type } = e.currentTarget;
    const setValue = type === "checkbox" ? checked : value;
    if (formState.form) {
      const newForm: GVPForm[] = formState.form.map((row, rindex) => {
        if (rindex === index) {
          return {
            ...row,
            [name]: setValue,
          };
        } else {
          return row;
        }
      });

      setFormState({
        ...formState,
        form: newForm,
      });
    }
  };

  const addRow = () => {
    let gvp: GVPForm = {
      id: 0,
      ssn: "",
      name: "",
      role: "",
      surname: "",
      phone: "",
      email: "",
      sign: false,
      receive: false,
      payment: false,
    };

    let employees = formState;
    employees.form?.push(gvp);
    setFormState({ ...formState, form: employees.form });
  };

  const saveForm = () => {
    updateGVP(formState);
  };

  const editForm = () => {
    setGVPEdit();
  };

  const signForm = () => {
    setSignDocument((current) => !current);
  };

  const signDocumentOpen = async (documentId: string) => {
    let data: any = await sendSign(documentId);
    if (data?.status === "REQUESTED") {
      setHash(data?.hash);
      setSignDocument((current) => !current);
    }
  };

  const popupHandler = () => {
    setIsDocument((current) => !current);
  };

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return !mobile ? (
    <Box className={classes.gContainer}>
      {!gvpLoading ? (
        <>
          <ProfileDocumentViewer
            fileName={formState.fileName}
            isDocumentOpen={isDocumentOpen}
            closeHandler={popupHandler}
          />
        </>
      ) : null}
      <ProfileSignDocument
        isSignOpen={signDocument}
        hash={hash}
        closeHandler={signForm}
      />
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <Typography className={classes.titleBig}>
            {gvp?.status === "NEW"
              ? "Lai turpinātu darbu, jums ir jāaizpilda sadarbības līgums."
              : null}

            {gvp?.status === "REPORT"
              ? "Sadarbības līgums tiek sagatavots."
              : null}

            {gvp?.status === "SIGN"
              ? "Sadarbības līgums ir sagatavots. Ludzu parakstiet izmantojot SmartId."
              : null}

            <IconButton
              className={classes.buttonInfo}
              aria-label="Info"
              aria-haspopup="true"
              onClick={popupHandler}
              color="inherit"
            >
              <InfoIcon />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Grid container className={classes.tableHeader}>
            <Grid item md={10}>
              <Typography className={classes.tableTitle}>
                Darbinieku dati
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className={classes.tableTitle}>Tiesibas</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            {!gvpLoading
              ? formState.form?.map((employee, index) => (
                  <>
                    <Grid item md={1}>
                      <InputTextField
                        name="name"
                        required={true}
                        value={employee?.name}
                        error={formError.name}
                        enabled={gvp?.status === "NEW" ? true : false}
                        formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                          handleFormChange(ev, index)
                        }
                      />
                    </Grid>
                    <Grid item md={1}>
                      <InputTextField
                        name="surname"
                        required={true}
                        value={employee?.surname}
                        error={formError.surname}
                        enabled={gvp?.status === "NEW" ? true : false}
                        formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                          handleFormChange(ev, index)
                        }
                      />
                    </Grid>
                    <Grid item md={2}>
                      <InputTextField
                        name="ssn"
                        required={true}
                        value={employee?.ssn}
                        error={formError.ssn}
                        enabled={gvp?.status === "NEW" ? true : false}
                        formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                          handleFormChange(ev, index)
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <InputTextField
                        name="email"
                        value={employee?.email}
                        error={formError.email}
                        enabled={gvp?.status === "NEW" ? true : false}
                        formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                          handleFormChange(ev, index)
                        }
                      />
                    </Grid>
                    <Grid item md={1}>
                      <InputTextField
                        name="phone"
                        required={true}
                        value={employee?.phone}
                        error={formError.phone}
                        enabled={gvp?.status === "NEW" ? true : false}
                        formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                          handleFormChange(ev, index)
                        }
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Grid container md={12}>
                        <Grid item md={4}>
                          <Checkbox
                            checked={employee?.sign}
                            disabled={gvp?.status === "NEW" ? false : true}
                            onChange={(
                              ev: ChangeEvent<HTMLInputElement>
                            ): void => handleFormChange(ev, index)}
                            name="sign"
                            id="sign"
                            className={classes.checkbox}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          <FormattedMessage id="register.sign" />
                        </Grid>
                        <Grid item md={4}>
                          <Checkbox
                            checked={employee?.receive}
                            disabled={gvp?.status === "NEW" ? false : true}
                            onChange={(
                              ev: ChangeEvent<HTMLInputElement>
                            ): void => handleFormChange(ev, index)}
                            name="receive"
                            id="receive"
                            className={classes.checkbox}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          <FormattedMessage id="register.receive" />
                        </Grid>
                        <Grid item md={4}>
                          <Checkbox
                            checked={employee?.payment}
                            disabled={gvp?.status === "NEW" ? false : true}
                            onChange={(
                              ev: ChangeEvent<HTMLInputElement>
                            ): void => handleFormChange(ev, index)}
                            name="payment"
                            id="payment"
                            className={classes.checkbox}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          <FormattedMessage id="register.pay" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ))
              : null}
            <Grid item md={12}>
              <Box textAlign="right" pb={3}>
                {gvp?.status === "NEW" ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => addRow()}
                      size="large"
                      className={classes.menuButtonDark}
                    >
                      <FormattedMessage id="profile.gvpAdd" />
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => saveForm()}
                      size="large"
                      className={classes.button}
                    >
                      <FormattedMessage id="save" />
                    </Button>
                  </>
                ) : null}

                {gvp?.status === "REPORT" ? (
                  <Button
                    variant="contained"
                    onClick={() => editForm()}
                    size="large"
                    className={classes.button}
                  >
                    <FormattedMessage id="profile.gvpEdit" />
                  </Button>
                ) : null}

                {gvp?.status === "SIGN" ? (
                  <Button
                    variant="contained"
                    onClick={() => signDocumentOpen(gvp?.id.toString())}
                    size="large"
                    className={classes.button}
                  >
                    <FormattedMessage id="profile.gvpSign" />
                  </Button>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box textAlign="center">
      Sadarbības līgumu var aizpildīt tikai izmantojot pārlūku datorā!
    </Box>
  );
};

const mapStateToProps = ({ user }: ApplicationState) => ({
  gvp: user.profile.gvp,
  gvpLoading: user.gvpLoading,
  login: user.login,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getGVP: () => {
      dispatch(getGVP());
    },
    getGVPStatus: () => {
      dispatch(getGVPStatus());
    },
    setGVPEdit: () => {
      dispatch(setGVPEdit());
    },
    updateGVP: (payload: any) => {
      dispatch(updateGVP(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileGVP);
