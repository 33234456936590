import React, { useEffect } from "react";
import { MenuTextData, Products } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../store";
import { AnyAction } from "redux";
import {
  deleteProduct,
  getMyProducts,
  getOrderStatusList,
} from "../store/users/action";
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import ProfileNoRows from "./ProfileNoRows";
import ProfileSpinner from "./ProfileSpinner";
import { FormattedMessage, useIntl } from "react-intl";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ViewList from "@material-ui/icons/ViewList";
import ProfileMyProductsEdit from "./ProfileMyProductsEdit";
import ProfileProductsRent from "./ProfileMyProductsRent";
import moment from "moment";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleBig: {
      marginBottom: 2,
      height: "auto",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      paddingTop: "10px",
      paddingBottom: "10px",
      color: "#333333",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "20px",
        paddingTop: "5px",
        paddingBottom: "0px",
      },
    },
    tableHeader: {
      borderBottom: "1px solid #E3E3E3",
      padding: "0px",
    },
    tableHeaderOpen: {
      padding: "0px",
    },
    tableTitle: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
    },
    tableText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      padding: "6px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "14px",
        padding: "2px",
      },
    },
    statusGreen: {
      background: "#D7EC84",
      fontSize: "12px",
      padding: "6px",
      border: "2px #ffffff solid",
    },
    statusRed: {
      background: "#EC574E",
      fontSize: "12px",
      padding: "6px",
      color: "#ffffff",
      border: "2px #ffffff solid",
    },
    statusGrey: {
      backgroundColor: "#E3E3E3 !important",
      fontSize: "12px",
      padding: "6px",
      border: "2px #ffffff solid",
    },
    red: {
      color: "#EC574E",
      fontSize: "30px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    green: {
      color: "green",
      fontSize: "30px",
    },
    edit: {
      color: "#000000",
      fontSize: "30px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    orderLink: {
      fontSize: "18px",
      lineHeight: "20px",
      textDecorationLine: "underline",
      color: "#333333",
      padding: "6px",
    },
    openLink: {
      textAlign: "center",
    },
    buttonEnd: {
      textAlign: "end",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    button: {
      width: "200px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        width: "150px",
        fontSize: "14px",
        padding: "5px",
      },
    },
    row: {
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
  })
);

interface propsFromDispatch {
  getMyProducts: () => any;
  deleteProduct: (id: string) => any;
  getOrderStatusList: () => any;
}
interface PropsFromState {
  productsLoading: boolean;
  products: Products[];
  orderStatusList: MenuTextData;
  orderStatusLoading: boolean;
  language: string;
  text: MenuTextData;
}

type AllProps = PropsFromState & propsFromDispatch;

const ProfileMyProducts: React.FC<AllProps> = ({
  getMyProducts,
  deleteProduct,
  getOrderStatusList,
  products,
  productsLoading,
  orderStatusList,
  orderStatusLoading,
  language,
  text,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [editProduct, setEditProduct] = React.useState<boolean | false>(false);
  const [rentProduct, setRentProduct] = React.useState<boolean | false>(false);
  const [editProductId, setEditProductId] = React.useState<string | undefined>(
    undefined
  );

  const openNew = () => {
    setEditProductId(undefined);
    setEditProduct(true);
  };

  const openEdit = (id: string) => {
    setEditProductId(id);
    setEditProduct(true);
  };

  const openRent = (id: string) => {
    setEditProductId(id);
    setRentProduct(true);
  };

  useEffect(() => {
    getMyProducts();
    getOrderStatusList();
  }, []);

  return (
    <Grid container>
      <Grid item md={6} xs={6} className={classes.titleBig}>
        <FormattedMessage id="profile.myLend" />
      </Grid>
      {!editProduct && !rentProduct ? (
        <Grid item md={6} xs={6} className={classes.buttonEnd}>
          <Button
            variant="contained"
            size="large"
            className={classes.button}
            onClick={() => openNew()}
          >
            <FormattedMessage id="profile.newLend" />
          </Button>
        </Grid>
      ) : null}
      {editProduct ? (
        <ProfileMyProductsEdit
          editProductId={editProductId}
          editProduct={editProduct}
          setEditProduct={setEditProduct}
        />
      ) : null}
      {rentProduct ? (
        <ProfileProductsRent
          editProductId={editProductId}
          editRent={rentProduct}
          setRentProduct={setRentProduct}
        />
      ) : null}
      {products?.length > 0 && !editProduct && !rentProduct ? (
        <Hidden smDown>
          <Grid container spacing={2} className={classes.tableHeader}>
            <Grid item md={3}>
              <Typography className={classes.tableTitle}>
                Tehnikas nosaukums
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography className={classes.tableTitle}>Statuss</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.tableTitle}>
                Produkta kategorija
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className={classes.tableTitle}>
                Pieteikuma laiks
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography className={classes.tableTitle}>Darījumi</Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className={classes.tableTitle}>
                Labot Produktu
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
      ) : null}
      {!productsLoading ? (
        products?.length > 0 && !editProduct && !rentProduct ? (
          <Grid container>
            <Grid item md={12}>
              <Box pt={3}></Box>
            </Grid>
            {products?.map((product, index) => (
              <Grid md={12} xs={12}>
                <Grid container className={classes.row}>
                  <Grid item md={3} xs={8}>
                    <Typography className={classes.tableText}>
                      {product?.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={1}
                    xs={4}
                    className={
                      product.status === "000000005" || product.status === "000000002"
                        ? classes.statusGreen
                        : classes.statusGrey
                    }
                  >
                    {orderStatusList[product.status]?.value[language]}
                  </Grid>
                  <Grid item md={3} xs={8} className={classes.tableText}>
                    {product.categoryTree ? (
                      <>
                        {product.categoryTree.category1
                          ? text[product.categoryTree.category1]?.value[
                              language
                            ]
                          : null}
                        {product.categoryTree.category2
                          ? " - " +
                            text[product.categoryTree.category2]?.value[
                              language
                            ]
                          : null}
                        {product.categoryTree.category3
                          ? " - " +
                            text[product.categoryTree.category3]?.value[
                              language
                            ]
                          : null}
                        {product.categoryTree.category4
                          ? " - " +
                            text[product.categoryTree.category4]?.value[
                              language
                            ]
                          : null}
                      </>
                    ) : null}
                  </Grid>
                  <Hidden smDown>
                    <Grid item md={2}>
                      <Typography className={classes.tableText}>
                        {moment(product.creationDate).format("DD-MM-YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item md={1} className={classes.openLink}>
                      {product?.rent ? (
                        <ViewList
                          className={classes.green}
                          onClick={() => openRent(product.id)}
                        />
                      ) : null}
                    </Grid>
                  </Hidden>
                  <Grid item md={2} xs={4} className={classes.openLink}>
                    <EditIcon
                      className={classes.edit}
                      onClick={() => openEdit(product.id)}
                    />
                    <DeleteForeverIcon
                      className={classes.red}
                      onClick={() => deleteProduct(product.id)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : !editProduct && !rentProduct ? (
          <Grid item md={12}>
            <ProfileNoRows
              text={intl.formatMessage({ id: "profile.noLend" })}
            />
          </Grid>
        ) : null
      ) : (
        <Grid item md={12}>
          <ProfileSpinner />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ user, menu, language }: ApplicationState) => ({
  products: user.profile.products,
  language: language.language,
  productsLoading: user.productsLoading,
  orderStatusList: user.orderStatusList,
  orderStatusLoading: user.orderStatusListLoading,
  menu: menu.menu,
  text: menu.text,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getMyProducts: () => {
      dispatch(getMyProducts());
    },
    deleteProduct: (id: string) => {
      dispatch(deleteProduct(id));
    },
    getOrderStatusList: () => {
      dispatch(getOrderStatusList());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMyProducts);
