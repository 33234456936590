import React, { ChangeEvent, useState } from "react";
import { UserProfile } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ProfileSpinner from "./ProfileSpinner";
import ProfileNoRows from "./ProfileNoRows";
import { connect } from "react-redux";
import { saveNewMessage, setMessageRead } from "../store/users/action";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ApplicationState } from "../store";
import ProfileInboxDetails from "./ProfileInboxDetails";
import InputTextField from "./TextField";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  accordion: {
    borderRadius: "0 !important",
    boxShadow: "none",
    padding: "0px",
    "&:hover": {
      cursor: "pointer",
      color: "#da2232",
    },
  },
  accordionDetails: {
    borderRadius: "0 !important",
    boxShadow: "none",
    padding: "0px",
    paddingBottom: "20px",
  },
  accordionDetailsOpen: {
    borderRadius: "0 !important",
    boxShadow: "none",
    padding: "0px",
    paddingBottom: "20px",
    borderBottom: "1px solid #E3E3E3",
  },
  heading: {
    flexBasis: "100%",
    flexShrink: 0,
    fontSize: "14px",
    fontWeight: "bold",
    color: "#333333",
  },
  titleBig: {
    marginBottom: 2,
    height: "auto",
    fontSize: "22px",
    fontWeight: 600,
    lineHeight: "31px",
    paddingBottom: "20px",
    color: "#333333",
    textAlign: "left",
  },
  titleBigCenter: {
    marginBottom: 2,
    height: "auto",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "31px",
    color: "#333333",
    textAlign: "center",
  },
  tableHeader: {
    borderBottom: "1px solid #E3E3E3",
    padding: "0px",
  },
  tableHeaderOpen: {
    padding: "0px",
  },
  tableTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    color: "#858585",
  },
  tableText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    padding: "6px",
  },
  tableTextBold: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    padding: "6px",
  },
  statusGreen: {
    background: "#D7EC84",
    fontSize: "12px",
    padding: "6px",
  },
  statusRed: {
    background: "#EC574E",
    fontSize: "12px",
    padding: "6px",
    color: "#ffffff",
  },
  statusGrey: {
    backgroundColor: "#E3E3E3 !important",
    fontSize: "12px",
    padding: "6px",
  },
  orderLink: {
    fontSize: "18px",
    lineHeight: "20px",
    textDecorationLine: "underline",
    color: "#333333",
    padding: "6px",
  },
  filter: {
    color: "#EC574E",
    fontSize: "22px",
    "&:hover": {
      cursor: "pointer",
      color: "#da2232",
    },
  },
  openLink: {
    textAlign: "right",
  },
  button: {
    width: "120px",
    backgroundColor: "#da2232",
    color: "#ffffff",
    borderRadius: "0px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#9b1b25",
      color: "#ffffff",
    }
  },
  alignRigh: {
    textAlign: "right"
  },
  alignCenter: {
    textAlign: "center"
  }
})
);

interface PropsFromState {
  profile: UserProfile;
  loading: boolean;
}

interface propsFromDispatch {
  setMessageRead: (messageFlowId: string) => any;
  saveNewMessage: (payload: any) => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const ProfileInbox: React.FC<AllProps> = ({ 
  loading, 
  profile, 
  setMessageRead,
  saveNewMessage
}) => {
  
  const classes = useStyles();
  const intl = useIntl();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handlePanelChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setMessageRead(panel);
      setExpanded(isExpanded ? panel : false);
    };
    
    const [message, setMessage] = useState<string>("");
  
    const validateForm = () => {
      return true;
    };
  
    const send = () => {
      if (validateForm()) {
        let request = {
          message: message
        };
        saveNewMessage(request);
        setMessage("");
      }
    };

    const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      setMessage(value);
    };
  
  return (
    <>
      <Grid item md={12} className={classes.titleBig}>
        <FormattedMessage id="profile.inboxTitle" />
          &nbsp;&nbsp;
      </Grid>
      {profile.inbox?.length > 0 ? (
        <Hidden smDown>
          <Grid container className={classes.tableHeader}>
            <Grid item md={8}>
              <Typography className={classes.tableTitle}>
        <FormattedMessage id="profile.inboxMessage" />
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.tableTitle}>                
        <FormattedMessage id="profile.inboxTime" />
              </Typography>
            </Grid>
            <Grid item md={1}>
            </Grid>
          </Grid>
        </Hidden>
      ) : null}
      {!loading ? (
        profile.inbox?.length > 0 ? (
          profile.inbox?.map((message, index) => (
            <Accordion
              className={classes.accordion}
              expanded={expanded === message.messageFlowId}
              onChange={handlePanelChange(message.messageFlowId)}
              key={index}
            >
              <AccordionSummary
                id={message.messageFlowId}
                className={
                  expanded === message.messageFlowId
                    ? classes.tableHeaderOpen
                    : classes.tableHeader
                }
              >
                <Grid container>
                  <Grid item md={8} xs={6}>
                    <Typography className={message.readed === 1 ? classes.tableText : classes.tableTextBold}>
                      {message.message}
                    </Typography>
                  </Grid>
                    <Grid item md={3}>
                      <Typography className={classes.tableText}>
                        {message.creationDate}
                      </Typography>
                    </Grid>
                  <Grid item xs={1} md={1} className={classes.openLink}>
                    {expanded === message.messageId ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                className={
                  expanded === message.messageFlowId
                    ? classes.accordionDetailsOpen
                    : classes.accordionDetails
                }
              >
                <ProfileInboxDetails messageFlowId={message.messageFlowId} visible={expanded === message.messageFlowId}/>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <>
          <ProfileNoRows
            text={intl.formatMessage({ id: "profile.noMessages" })}
          />
          </>
        )
      ) : (
        <ProfileSpinner />
      )}
      <Grid container>
      <Grid item xs={12} md={6}>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box pt={6} />
        <Grid container direction="row" spacing={2}>
          <Grid item md={12}>
            <Typography className={classes.titleBigCenter}>
              <FormattedMessage id="profile.sendMessage" />
            </Typography>
          </Grid>
          <Grid item md={12}>
            <InputTextField
              name="name"
              title={intl.formatMessage({ id: "profile.inboxMessage" })}
              value={message || ""}
              formChange={handleFormChange}
            />
          </Grid>
          <Grid item md={12} className={classes.alignRigh}>
            <Button
              variant="contained"
              onClick={() => send()}
              size="large"
              className={classes.button}
            >
              <FormattedMessage id="send" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ user }: ApplicationState) => ({
  profile: user.profile,
  loading: user.inboxLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    setMessageRead: (messageFlowId: string) => {
      dispatch(setMessageRead(messageFlowId));
    },
    saveNewMessage: (payload: any) => {
      dispatch(saveNewMessage(payload))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInbox);
