import React, { ChangeEvent } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Grid, MenuItem, Select } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import {
  GoogleMap,
  StandaloneSearchBox,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { calculateDelivery } from "../store/users/action";
import { connect } from "react-redux";
import { Projects } from "../models/types";
require("dotenv").config();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectField: {
      width: "100%",
      height: "42px",
      fontSize: "16px",
      backgroundColor: "#ffffff",
      border: "1px solid #e3e3e3",
      margin: "0px",
      paddingLeft: "20px",
      fontWeight: "bold",
    },
  })
);

interface IProps {
  formState: any;
  projects: Projects[];
  handleMapChange: (address: any) => any;
}

interface propsFromDispatch {
  calculateDelivery: (data: any) => any;
}

type AllProps = IProps & propsFromDispatch;

const CartMap: React.FC<AllProps> = ({
  formState,
  projects,
  handleMapChange,
  calculateDelivery,
}) => {
  const classes = useStyles();
  const [currentLocation, setCurrentLocation] = React.useState({
    lat: 56.9496,
    lng: 24.1052,
  });

  const [markers, setMarkers] = React.useState({
    lat: 0,
    lng: 0,
  });
  const [project, setProject] = React.useState<number>(undefined);

  const onMapLoad = (map: any) => {
    const geocoder = new google.maps.Geocoder();

    if (formState.lat && formState.lgn) {
      setMarkers({
        lat: Number(formState.lat),
        lng: Number(formState.lgn),
      });
      setCurrentLocation({
        lat: Number(formState.lat),
        lng: Number(formState.lgn),
      });
    } else {
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          setCurrentLocation({
            lat: lat,
            lng: lng,
          });
        }
      );
    }

    google.maps.event.addListener(map, "click", (event: any) => {
      geocoder.geocode(
        { location: { lat: event.latLng.lat(), lng: event.latLng.lng() } },
        (response: any) => {
          if (response[0]) {
            handleMapChange({
              address: response[0].formatted_address,
              lat: event.latLng.lat().toString(),
              lng: event.latLng.lng().toString(),
            });
            calculateDelivery({
              lat: event.latLng.lat().toString(),
              lng: event.latLng.lng().toString(),
            });
          }
        }
      );
      setMarkers({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
      setCurrentLocation({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    });
  };

  const onSBLoad = (address: any) => {
    google.maps.event.addListener(address, "change", () => {
      address.setComponentRestrictions({
        country: ["lv", "lt", "ee"],
        zoom: "14",
      });
    });
    google.maps.event.addListener(address, "places_changed", () => {
      let place = address.getPlaces();
      let place1 = place[0];
      handleMapChange({ address: place1.formatted_address });
      calculateDelivery({
        lat: place1.geometry?.location.lat(),
        lng: place1.geometry?.location.lng(),
      });
      setCurrentLocation({
        lat: place1.geometry?.location.lat(),
        lng: place1.geometry?.location.lng(),
      });
      setMarkers({
        lat: place1.geometry?.location.lat(),
        lng: place1.geometry?.location.lng(),
      });
    });
  };

  const setLocalAddress = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    handleMapChange({ address: value });
    calculateDelivery(currentLocation);
  };

  const handleSelectChange = (e: any) => {
    const { value } = e.target;
    setProject(value);
    handleMapChange({
      address: projects[value].address,
      lat: projects[value].lat,
      lng: projects[value].lgn,
    });
    setCurrentLocation({
      lat: Number(projects[value].lat),
      lng: Number(projects[value].lgn),
    });
    setMarkers({
      lat: Number(projects[value].lat),
      lng: Number(projects[value].lgn),
    });
    calculateDelivery(currentLocation);
  };

  return (
    <Box m={3} justifyContent="center">
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY || ""}
        libraries={["places"]}
      >
        <Grid container spacing={2} direction="row" justify="center">
          {projects?.length > 0 ? (
            <>
              <Grid item xs={12} md={12}>
                <b>
                  <FormattedMessage id="profile.myObjects" />
                </b>
              </Grid>
              <Grid item md={12}>
                <Select
                  value={project}
                  onChange={handleSelectChange}
                  className={classes.selectField}
                  name="project"
                  id="project"
                  variant="outlined"
                >
                  {projects?.map((project, index) => (
                    <MenuItem value={index}>
                      {project?.name} ({project?.address})
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} md={12}>
            <b>
              <FormattedMessage id="mapAddress" />
            </b>
          </Grid>
          <Grid item xs={12} md={12}>
            <StandaloneSearchBox onLoad={onSBLoad}>
              <input
                type="text"
                placeholder="Piegades adrese"
                value={formState.address || ""}
                onChange={setLocalAddress}
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid #E3E3E3`,
                  width: `100%`,
                  height: `36px`,
                  padding: `10px 12px`,
                  borderRadius: `0px`,
                  maxWidth: `800px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                }}
              />
            </StandaloneSearchBox>
          </Grid>
          <Grid item md={12}>
            <GoogleMap
              id="search-box-example"
              mapContainerStyle={{
                minHeight: "400px",
                maxHeight: "600px",
                minWidth: "300px",
                maxWidth: "800px",
              }}
              zoom={12}
              center={currentLocation}
              onLoad={(map) => onMapLoad(map)}
            >
              <Marker
                key="place"
                icon={{ url: "/images/marker.svg" }}
                position={markers}
              />
            </GoogleMap>
          </Grid>
        </Grid>
      </LoadScript>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    calculateDelivery: (data: any) => {
      dispatch(calculateDelivery(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(CartMap);
