/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { Categories, MenuTextData, ProductsData, UserData } from "../models/types";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { searchCategories, searchProducts } from "../store/products/action";
import ProductCard from "./ProductCard";
import { Box, Grid, Hidden } from "@material-ui/core";
import ProductBreadcrumb from "./ProductBreadcrumb";
import ProductTree from "./ProductTree";
import { appendTreeSelection } from "../store/menu/action";
import ProductCategoryCard from "./ProductCategoryCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    popoverPaper: {
      width: '100%',
      maxHeight: 'unset',
    },
    listContainer: {
      maxWidth: '1200px',
    }
  }),
);

interface PropsFromState {
  text: MenuTextData;
  textLoading: boolean;
  language: string;
  loading: boolean;
  listLoading: boolean;
  errors?: string;
  products: ProductsData[];
  listCategories: Categories[];
  token: UserData["token"];
}

interface propsFromDispatch {
  appendTreeSelection: (type: string, id: string) => any;
  searchCategories: (id: string) => any;
}

interface IProps {
  type: string,
  id?: string
}

type AllProps = IProps & PropsFromState & propsFromDispatch;

const ProductList: React.FC<AllProps> = ({
  text,
  textLoading,
  type,
  id,
  language,
  loading,
  listLoading,
  errors,
  products,
  listCategories,
  token,
  appendTreeSelection,
  searchCategories
}) => {
  useEffect(() => {
    appendTreeSelection(type, id || '');
    searchCategories(id || '');
    searchProducts()
  }, [token, type, id]);

  const classes = useStyles();

  return (
    <Box pl={1} pr={1} pt={3} pb={2} className={classes.listContainer}>
      <ProductBreadcrumb />
      <Grid
        container
        spacing={0} 
        direction="row"
        justify="center"
      >

      <Hidden smUp>
        <Grid item xs={12} sm={12} >
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            {!loading&&!textLoading&&products ? products.map((item, index) => (
              <Grid key={'GPC'+index} item xs={6} sm={6} md={3} >
                <ProductCard key={'PC'+index} product={item} language={language} />
              </Grid>
            )) : null}
            {!listLoading&&!textLoading&&listCategories ? listCategories.map((item, index) => (
              <Grid key={'GPCC'+index} item xs={6} sm={6} md={3} >
                <ProductCategoryCard key={'PCC'+index} category={item.code} name={text[item.code]?.value[language]} image={text[item.code]?.image || ''} />
              </Grid>
            )) : null}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid item xs={4} sm={4} >
          <ProductTree />
        </Grid>
        <Grid item xs={8} sm={8} >
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="flex-start"
          >
          {!loading&&!textLoading&&products.length > 0 ? products.map((item, index) => (
            <Grid key={'GPC'+index} item xs={6} sm={6} md={3} >
              <ProductCard key={'PC'+index} product={item} language={language} />
            </Grid>
          )) : null}
          {!listLoading&&!textLoading&&listCategories ? listCategories.map((item, index) => (
            <Grid key={'GPCC'+index} item xs={6} sm={6} md={3} >
              <ProductCategoryCard key={'PCC'+index} category={item.code} name={text[item.code]?.value[language]} image={text[item.code]?.image || ''} />
            </Grid>
          )) : null}
          </Grid>
        </Grid>
      </Hidden>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ language, products, user, menu }: ApplicationState) => ({
  text: menu.text,
  textLoading: menu.loading, 
  language: language.language,
  loading: products.loading,
  errors: products.errors,
  products: products.products,
  listCategories: products.categoryList,
  listLoading: products.categoryListLoading,
  token: user.user["token"]
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    appendTreeSelection: (type: string, id: string) => {
      dispatch(appendTreeSelection(type, id));
    },
    searchCategories: (id?: string) => {
      dispatch(searchCategories(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
