import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getStatus } from "./../store/payment/action";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        partners: {
            color: '#333333',
            fontSize: 14,
            textAlign: 'center'
        },
        link: {
            color: '#da2232'
        },
    }),
);

const Callback: React.FC = () => {

    const classes = useStyles();
    
    useEffect(() => {
        const paymentResult = new URLSearchParams(window.location.search); 
        getStatus(paymentResult.get('payment_reference') || '')
    }, []);
  
    return (
        <Box pt={10} pb={10} pl={2} pr={2} className={classes.partners}>
            Maksajums atrodas apstrade! Jus varat aizvert so logu.
            <br/>
            <br/>
            Aicinām aplūkot <Link to="/" className={classes.link}>produktu katalogu</Link>.
            <br/>
        </Box>
    );
};

export default (Callback);
