import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";
import LocalHelmet from "./Helmet";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        largeTitle: {
            paddingBottom: 15,
            fontWeight: 'bold',
            fontSize: '25px'
        },
        text: {
            fontSize: '16px',
            lineHeight: '25px',
            textAlign: "left",
        },
        banner: {
            width: '100%',
            maxWidth: "750px",
        },
        bannerBox: {
            textAlign: "center",
            alignSelf: "center",
            width: "100%",
            maxWidth: "1200px",
            margin: "0 auto"
        },
        about: {
            textAlign: "left",
            alignSelf: "center",
            width: "100%",
            maxWidth: "1200px",
            color: '#333333',
            margin: "0 auto",
        },
    }),
);

const Feedback: React.FC = () => {

    const classes = useStyles();

    return (
        <Box alignItems="center" display="block">
        <LocalHelmet title="STATS Rent - Atsauksmes" />
        </Box>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {};
};

export default connect(null, mapDispatchToProps)(Feedback);
