import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

interface IProps {
  data: any,
  show: string
}

const GalleryPhoto: React.FC<IProps> = ({
  data,
  show
}) => {
  useEffect(() => { }, []);

  return (
    <Box p={3} display={show}>
        <ImageGallery items={data} />
    </Box>
  );
};

export default (GalleryPhoto);
