/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { UserData, UserProfile } from "../models/types";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Grid, Hidden, Tab, Tabs, useMediaQuery } from "@material-ui/core";
import { getUserProfile, updateCompany } from "../store/users/action";
import ProfileData from "./ProfileData";
import ProfileEmployees from "./ProfileEmployees";
import ProfileOrders from "./ProfileOrders";
import ProfileProjects from "./ProfileProjects";
import ProfileInvoices from "./ProfileInvoices";
import ProfileService from "./ProfileService";
import ProfileMyProducts from "./ProfileMyProducts";
import Error404 from "./404";
import { FormattedMessage, useIntl } from "react-intl";
import ProfileContract from "./ProfileContracts";
import ProfileGVP from "./ProfileGVP";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getStatus } from "./../store/payment/action";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FolderOpen from "@material-ui/icons/FolderOpen";
import GavelIcon from "@material-ui/icons/Gavel";
import BusinessIcon from "@material-ui/icons/Business";
import ListAltIcon from "@material-ui/icons/ListAlt";
import BuildIcon from "@material-ui/icons/Build";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ChecklistIcon from '@material-ui/icons/ListTwoTone';
import StoreIcon from "@material-ui/icons/Store";
import { theme } from "../theme/retrostyle";
import LocalHelmet from "./Helmet";
import ProfileActive from "./ProfileActive";
import MessageIcon from '@material-ui/icons/Message';
import ProfileInbox from "./ProfileInbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    buttonText: {
      fontSize: "14px",
      fontWeight: "bold",
      paddingLeft: "10px",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(${theme.spacing(2)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    heading: {
      flexBasis: "100%",
      flexShrink: 0,
      fontSize: "14px",
      fontWeight: "bold",
      color: "black",
    },
    accordion: {
      border: "1px solid #ebdcc6",
      boxShadow: "none",
    },
    plusButton: {
      border: "1px solid #ebdcc6",
      borderRadius: "3px",
    },
    minusButton: {
      border: "1px solid #ebdcc6",
      borderRadius: "3px",
    },
    boxLine: {
      border: "1px solid #ebdcc6",
      borderRadius: "4px",
      boxShadow: "none",
      padding: 10,
      backgroundColor: "#ffffff",
    },
    boxTitle: {
      paddingBottom: 10,
      paddingTop: 10,
      fontWeight: "bold",
      fontSize: "18px",
    },
    boxText: {
      fontSize: "14px",
      lineHeight: "25px",
    },
    importantText: {
      fontWeight: "bold",
      fontSize: "16px",
    },
    menu: {
      backgroundColor: "#f9f9f9",
      flexGrow: 1,
      height: "100%",
      minHeight: "70vh",
      alignItems: "baseline",
      opacity: "1",
      [theme.breakpoints.down("xs")]: {
        width: "50px",
      },
    },
    menuSelected: {
      backgroundColor: "#333333",
      color: "#ffffff",
      textDecoration: "underline",
      textAlign: "left",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    menuText: {
      alignItems: "flex-start",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    borderRight: {
      borderRight: "1px solid #e3e3e3",
      backgroundColor: "#f9f9f9",
      fontSize: "14px",
      height: "100%",
    },
    title: {
      color: "#333333",
      fontWeight: "bold",
      fontSize: "18px",
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

interface IProps {
  tab?: string;
  operation?: string;
}

interface PropsFromState {
  loading: boolean;
  login: boolean;
  profile: UserProfile;
  user: UserData;
}

interface propsFromDispatch {
  getUserProfile: () => any;
  updateCompany: (payload: any) => void;
}

type AllProps = IProps & PropsFromState & propsFromDispatch;

const Profile: React.FC<AllProps> = ({
  loading,
  login,
  profile,
  user,
  tab,
  getUserProfile,
  updateCompany,
}) => {
  useEffect(() => {
    getUserProfile();
  }, [user]);

  const classes = useStyles();
  const intl = useIntl();
  let history = useHistory();
  const [value, setValue] = useState(0);
  const [currentTab] = useState("profile");
  const [formState, setFormState] = useState(profile);
  const [formError] = useState<any>();
  const [sentOrder, setSentOrder] = useState("");
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    let employee = {
      ...formState,
      [name]: value,
    };
    setFormState({ ...employee });
  };

  const saveAddress = () => {
    let address = {
      registrationAddress: formState.registrationAddress,
      officeAddress: formState.officeAddress,
      deliveryAddress: formState.deliveryAddress,
    };
    updateCompany(address);
  };

  useEffect(() => {
    if (
      searchParams.get("order_reference") &&
      searchParams.get("payment_reference") &&
      sentOrder !== searchParams.get("order_reference")
    ) {
      setSentOrder(searchParams.get("order_reference"));
      getStatus(
        searchParams.get("order_reference") || "",
        searchParams.get("payment_reference") || ""
      );
    }
  }, [searchParams]);

  useEffect(() => {
    switch (tab) {
      case "":
        setValue(0);
        break;
      case "iznoma":
        setValue(9);
        break;
      case "remonts":
        setValue(7);
        break;
      case "darbinieki":
        setValue(1);
        break;
      case "sazina":
          setValue(2);
          break;
      case "ligumi":
        setValue(3);
        break;
      case "objekti":
        setValue(4);
        break;
      case "pasutijumi":
        setValue(5);
        break;
      case "atlikumi-noma":
        setValue(6);
        break;
      case "rekini":
        setValue(8);
        break;
      default:
        setValue(0);
        break;
    }
  }, [tab]);

  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  return login ? (
    user.profile?.signDocuments ? (
      <Grid container spacing={0} direction="row" justify="center">
        <Grid item>
          <ProfileGVP />
        </Grid>
        <LocalHelmet title={intl.formatMessage({ id: "profile.title" })} />
      </Grid>
    ) : (
      <Grid container spacing={0} direction="row" justify="center">
        <LocalHelmet title={intl.formatMessage({ id: "profile.title" })} />
        <Grid item xs={1} sm={2} md={2} className={classes.borderRight}>
          <Hidden smDown>
            <Box pt={3} pl={2} pb={3} className={classes.title}>
              <FormattedMessage id="profile.title" />
            </Box>
          </Hidden>
          <Tabs
            orientation="vertical"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            classes={{ root: classes.menu }}
          >
            <Tab
              icon={mobile ? <AccountCircleIcon /> : <div />}
              label={
                mobile ? null : intl.formatMessage({ id: "profile.myCompany" })
              }
              classes={{
                selected: classes.menuSelected,
                wrapper: classes.menuText,
              }}
              selected={currentTab === "profile"}
              onClick={() => history.push("/profile")}
            />
            <Tab
              icon={mobile ? <FolderOpen /> : <div />}
              label={
                mobile ? null : intl.formatMessage({ id: "profile.myData" })
              }
              classes={{
                selected: classes.menuSelected,
                wrapper: classes.menuText,
              }}
              selected={currentTab === "profile"}
              onClick={() => history.push("/profile/darbinieki")}
            />
            <Tab
              icon={mobile ? <MessageIcon /> : <div />}
              label={
                mobile ? null : intl.formatMessage({ id: "profile.inbox" })
              }
              classes={{
                selected: classes.menuSelected,
                wrapper: classes.menuText,
              }}
              selected={currentTab === "profile"}
              onClick={() => history.push("/profile/sazina")}
            />
            <Tab
              icon={mobile ? <GavelIcon /> : <div />}
              label={
                mobile
                  ? null
                  : intl.formatMessage({ id: "profile.myContracts" })
              }
              classes={{
                selected: classes.menuSelected,
                wrapper: classes.menuText,
              }}
              onClick={() => history.push("/profile/ligumi")}
            />
            <Tab
              icon={mobile ? <BusinessIcon /> : <div />}
              label={
                mobile ? null : intl.formatMessage({ id: "profile.myObjects" })
              }
              classes={{
                selected: classes.menuSelected,
                wrapper: classes.menuText,
              }}
              onClick={() => history.push("/profile/objekti")}
            />
            <Tab
              icon={mobile ? <ListAltIcon /> : <div />}
              label={
                mobile ? null : intl.formatMessage({ id: "profile.myOrders" })
              }
              classes={{
                selected: classes.menuSelected,
                wrapper: classes.menuText,
              }}
              onClick={() => history.push("/profile/pasutijumi")}
            />
            <Tab
              icon={mobile ? <ChecklistIcon /> : <div />}
              label={
                mobile ? null : intl.formatMessage({ id: "profile.myActive" })
              }
              classes={{
                selected: classes.menuSelected,
                wrapper: classes.menuText,
              }}
              onClick={() => history.push("/profile/atlikumi-noma")}
            />
            <Tab
              icon={mobile ? <BuildIcon /> : <div />}
              label={
                mobile ? null : intl.formatMessage({ id: "profile.myService" })
              }
              classes={{
                selected: classes.menuSelected,
                wrapper: classes.menuText,
              }}
              onClick={() => history.push("/profile/remonts")}
            />
            <Tab
              icon={mobile ? <AccountBalanceWalletIcon /> : <div />}
              label={
                mobile ? null : intl.formatMessage({ id: "profile.myBills" })
              }
              classes={{
                selected: classes.menuSelected,
                wrapper: classes.menuText,
              }}
              onClick={() => history.push("/profile/rekini")}
            />
            <Tab
              icon={mobile ? <StoreIcon /> : <div />}
              label={
                mobile ? null : intl.formatMessage({ id: "profile.myLend" })
              }
              classes={{
                selected: classes.menuSelected,
                wrapper: classes.menuText,
              }}
              onClick={() => history.push("/profile/iznoma")}
            />
          </Tabs>
        </Grid>
        <Grid item xs={11} sm={10} md={10}>
          <TabPanel value={value} index={0}>
            <ProfileData
              loading={loading}
              profile={profile}
              formState={formState}
              formErrors={formError}
              formChange={handleFormChange}
              formSubmit={saveAddress}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LocalHelmet
              title={
                intl.formatMessage({ id: "profile.title" }) +
                " - " +
                intl.formatMessage({ id: "profile.myData" })
              }
            />
            <ProfileEmployees />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <LocalHelmet
              title={
                intl.formatMessage({ id: "profile.title" }) +
                " - " +
                intl.formatMessage({ id: "profile.inboxTitle" })
              }
            />
            <ProfileInbox />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <LocalHelmet
              title={
                intl.formatMessage({ id: "profile.title" }) +
                " - " +
                intl.formatMessage({ id: "profile.myContracts" })
              }
            />
            <ProfileContract />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <LocalHelmet
              title={
                intl.formatMessage({ id: "profile.title" }) +
                " - " +
                intl.formatMessage({ id: "profile.myObjects" })
              }
            />
            <ProfileProjects />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <LocalHelmet
              title={
                intl.formatMessage({ id: "profile.title" }) +
                " - " +
                intl.formatMessage({ id: "profile.myOrders" })
              }
            />
            <ProfileOrders />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <LocalHelmet
              title={
                intl.formatMessage({ id: "profile.title" }) +
                " - " +
                intl.formatMessage({ id: "profile.myActive" })
              }
            />
            <ProfileActive />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <LocalHelmet
              title={
                intl.formatMessage({ id: "profile.title" }) +
                " - " +
                intl.formatMessage({ id: "profile.myService" })
              }
            />
            <ProfileService profile={profile} user={user} />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <LocalHelmet
              title={
                intl.formatMessage({ id: "profile.title" }) +
                " - " +
                intl.formatMessage({ id: "profile.myBills" })
              }
            />
            <ProfileInvoices loading={loading} profile={profile} />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <LocalHelmet
              title={
                intl.formatMessage({ id: "profile.title" }) +
                " - " +
                intl.formatMessage({ id: "profile.myLend" })
              }
            />
            <ProfileMyProducts />
          </TabPanel>
        </Grid>
      </Grid>
    )
  ) : (
    <Error404 />
  );
};

const mapStateToProps = ({ user, language }: ApplicationState) => ({
  loading: user.profileLoading,
  profile: user.profile,
  user: user.user,
  login: user.login,
  language: language.language,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserProfile: () => {
      dispatch(getUserProfile());
    },
    updateCompany: (payload: any) => {
      dispatch(updateCompany(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
