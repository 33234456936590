/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from "@material-ui/core";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  getUserData,
  loginEmployee,
  loginSmartId,
  loginSmartIdClean,
  loginSmartIdStatus,
} from "../store/users/action";
import { connect } from "react-redux";
import { SmartId } from "../models/types";
import SmartIdInput from "./SmartIdInput";
import { FormattedMessage, useIntl } from "react-intl";
import { useCookies } from "react-cookie";
import { getStatus } from "../store/sign/action";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 0.1,
    },
    selectText: {
      fontSize: "14px",
      fontWeight: "bold",
      paddingTop: "3px",
      paddingLeft: "5px",
    },
    code: {
      width: "100%",
      fontSize: "35px",
      backgroundColor: "#ffffff",
      textDecoration: "bold",
      textAlign: "center",
      color: "#333333",
      margin: "0px",
    },
    error: {
      width: "100%",
      fontSize: "20px",
      backgroundColor: "#ffffff",
      textDecoration: "bold",
      textAlign: "center",
      color: "#da2232",
      margin: "0px",
    },
    focused: {},
    outlinedInput: {
      "&$focused $notchedOutline": {
        border: "1px solid #e3e3e3",
      },
    },
    notchedOutline: {},
    button: {
      width: "100%",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    title: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#000000",
      paddingBottom: "16px",
    },
    information: {
      fontSize: "13px",
      fontWeight: "normal",
      color: "#000000",
      paddingBottom: "16px",
      paddingTop: "18px",
      textAlign: "center",
    },
    link: {
      color: "#da2232",
    },
    registration: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#000000",
      paddingBottom: "16px",
      textDecoration: "underline",
    },
    formWidth: {
      maxWidth: "300px",
    },
  })
);

interface PropsFromState {}

interface propsFromDispatch {
  getUserData: () => any;
}

interface IProps {
  isSignOpen: boolean;
  hash: string;
  closeHandler: () => any;
}

type AllProps = IProps & PropsFromState & propsFromDispatch;

const ProfileSignDocument: React.FC<AllProps> = ({
  isSignOpen,
  hash,
  getUserData,
  closeHandler,
}) => {
  const [loop, setLoop] = useState(0);
  const classes = useStyles();
  const [values, setValues] = useState({
    documentId: undefined,
    status: undefined,
    code: undefined,
  });

  useEffect(() => {
    if (isSignOpen) {
      setLoop(
        window.setInterval(async () => {
          let data: any = await getStatus(hash);
          setValues({
            ...values,
            status: data.status,
            code: data?.code,
          });
          if (data.status === "ERROR" && values.status === "REQUESTED") {
            setValues({
              ...values,
              status: data.status,
              code: undefined,
            });
          }
        }, 3000)
      );
      setTimeout(() => clearInterval(loop), 90000);
    }
  }, [isSignOpen, hash]);

  useEffect(() => {
    if (values.status === "SUCCESS") {
      getUserData();
      clearInterval(loop);
      closeHandler();
    }
    if (values.status === "ERROR") {
      clearInterval(loop);
    }
  }, [values]);

  return (
    <Dialog
      open={isSignOpen}
      onBackdropClick={closeHandler}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <DialogContentText align="center">
          <Box className={classes.title}>
            <FormattedMessage id="profile.signTitle" />
          </Box>
        </DialogContentText>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} md={6}>
            <Box textAlign="center" pb={2}>
              <img src="/images/smart-id.png" alt="Smart ID" />
            </Box>
            <Box
              display={values?.status === "REQUESTED" ? "block" : "none"}
              className={classes.code}
            >
              {values?.code}
            </Box>
            <Box
              display={
                values?.status !== "REQUESTED" && values?.status !== "ERROR"
                  ? "block"
                  : "none"
              }
              pt={2}
              textAlign="center"
            >
              <FormattedMessage id="profile.signWait" />
            </Box>
            <Box
              display={values?.status === "ERROR" ? "block" : "none"}
              pt={5}
              textAlign="center"
            >
              <FormattedMessage id="profile.signError" />
            </Box>
            <Box className={classes.information}>
              <FormattedMessage id="register.smartIdMore" />{" "}
              <a
                href="https://www.smart-id.com/lv/"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                {" "}
                <FormattedMessage id="register.smartIdHere" />{" "}
              </a>
              .
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({ user }: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserData: () => {
      dispatch(getUserData());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSignDocument);
