import React, { ChangeEvent } from "react";
import { UserProfile } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import InputTextField from "./TextField";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    importantText: {
      fontWeight: "bold",
      fontSize: "16px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    button: {
      width: "250px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        width: "150px",
      },
    },
    spinner: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
    },
  })
);

interface IProps {
  loading: boolean;
  profile: UserProfile;
  formState: any;
  formErrors: any;
  formChange: (e: ChangeEvent<HTMLInputElement>) => any;
  formSubmit: () => any;
}

const ProfileData: React.FC<IProps> = ({
  loading,
  profile,
  formState,
  formErrors,
  formChange,
  formSubmit,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      {!loading ? (
        <Grid container spacing={3} direction="row">
          <Grid item xs={6} sm={6} md={6}>
            <span className={classes.importantText}>
              {profile.contractCode}
            </span>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <span className={classes.importantText}>
              {profile?.type === "BUSINESS" ? (
                <FormattedMessage id="register.companyRegistrationNumber" />
              ) : (
                <FormattedMessage id="register.ssn" />
              )}{" "}
              {profile.registrationNumber}
            </span>
          </Grid>
          {profile?.type === "BUSINESS" ? (
            <>
              <Grid item xs={6} sm={6} md={6}></Grid>
              <Grid item xs={6} sm={6} md={6}>
                <span className={classes.importantText}>
                  <FormattedMessage id="register.companyVAT" />{" "}
                  {profile.vatNumber}
                </span>
              </Grid>
            </>
          ) : null}
          <Grid item xs={6} sm={6} md={6}>
            <span className={classes.importantText}>
              <FormattedMessage id="profile.creditLimit" />{" "}
              {console.log(profile)}
              {profile.creditLimit || 0} EUR (<FormattedMessage id="profile.creditLimitAvailable" />{" "}{profile.creditLimitAvailable || 0} EUR)
            </span>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <span className={classes.importantText}>
              <FormattedMessage id="profile.paymentTerms" />{" "}
              {profile.payDue || 0} <FormattedMessage id="profile.days" />
            </span>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <InputTextField
              name="registrationAddress"
              title={intl.formatMessage({ id: "profile.registrationAddress" })}
              value={
                formState.registrationAddress || profile.registrationAddress
              }
              error={formErrors}
              enabled={true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <InputTextField
              name="officeAddress"
              title={intl.formatMessage({ id: "profile.officeAddress" })}
              value={formState.officeAddress || profile.officeAddress}
              error={formErrors}
              enabled={true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <InputTextField
              name="deliveryAddress"
              title={intl.formatMessage({ id: "profile.deliveryAddress" })}
              value={formState.deliveryAddress || profile.deliveryAddress}
              error={formErrors}
              enabled={true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              variant="contained"
              onClick={formSubmit}
              size="large"
              className={classes.button}
            >
              <FormattedMessage id="profile.save" />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.spinner}>
          <Loader
            type="Bars"
            color="#da2232"
            height={100}
            width={100}
            timeout={5000}
          />
        </div>
      )}
    </>
  );
};

export default ProfileData;
