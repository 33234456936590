import React, { ChangeEvent, useEffect, useState } from "react";
import { UserProfile } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Button, Checkbox, Grid, Hidden, Popover, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import ProfileDocumentViewer from "./ProfileDocumentViewer";
import FileDownloadIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AssignmentIcon from '@material-ui/icons/Assignment';
import FilterListIcon from '@material-ui/icons/FilterList';
import ProfileNoRows from "./ProfileNoRows";
import ProfileSignDocument from "./ProfileSignDocument";
import { sendSign } from "../store/sign/action";
import ProfileFilters from "./ProfileFilters";
import InputTextField from "./TextField";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import { getDay } from "date-fns";
import { lv } from "date-fns/locale";
import { getUserContracts } from "../store/users/action";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 2,
      height: "40px",
      fontSize: "16px",
      paddingTop: "8px",
      weight: 400,
      color: "#333333",
      textAlign: "left",
      textDecoration: "underline",
    },
    titleBig: {
      marginBottom: 2,
      height: "auto",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      paddingTop: "10px",
      paddingBottom: "10px",
      color: "#333333",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    middle: {
      textAlign: "center",
    },
    button: {
      width: "100px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    tableTitle: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
    },
    tableText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      padding: "6px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "14px",
        padding: "2px",
      },
    },
    tableHeader: {
      borderBottom: "1px solid #E3E3E3",
      padding: "0px",
    },
    edit: {
      color: "#000000",
      fontSize: "35px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    red: {
      color: "#EC574E",
      fontSize: "35px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    filter: {
      color: "#EC574E",
      fontSize: "22px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    row: {
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    search: {
      maxWidth: "600px",
      minHeight: "500px",
      padding: "10px",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    divider: {
      padding: "10px",
    },
    checkbox: {
      color: "#333333",
    },
    inputInput: {
      padding: theme.spacing(2, 1.5, 1.5, 0),
      transition: theme.transitions.create("width"),
      paddingLeft: `calc(0.1em + ${theme.spacing(1)}px)`,
      width: "94%",
      borders: "0px",
      background: "#ffffff",
      borderTop: "0px",
      borderRight: "0px",
      borderLeft: "0px",
      fontSize: "16px",
      color: "#333333",
      borderBottomColor: "1px solid #e3e3e3",
    },
  })
);

registerLocale("lv", {
  ...lv,
  options: {
    weekStartsOn: 1,
    lastDayOfWeek: 0,
    firstWeekContainsDate: 1,
  },
});

const isWeekday = (date: Date) => {
  const day: number = getDay(date);
  return day !== 0;
};


interface PropsFromState {
  profile: UserProfile;
  language: string;
  contractsLoading: boolean;
}

interface propsFromDispatch {
  getUserContracts: () => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const ProfileContract: React.FC<AllProps> = ({ 
  profile,
  language,
  contractsLoading,
  getUserContracts
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [isDocumentOpen, handleDocument] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [signDocument, setSignDocument] = useState(false);
  const [hash, setHash] = useState("");

  useEffect(() => {
    getUserContracts();
  }, []);

  const popupHandler = () => {
    handleDocument((current) => !current);
  };

  const openDocument = (documentName: string) => {
    setDocumentName(documentName);
    popupHandler();
  };

  const signForm = () => {
    setSignDocument((current) => !current);
  };

  const signDocumentOpen = async (documentId: string) => {
    let data: any = await sendSign(documentId);
    if (data?.status === "REQUESTED") {
      setHash(data?.hash);
      setSignDocument((current) => !current);
    }
  };

  const [filterState, setFilterState] = useState<any>({});
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value, type } = e.currentTarget;
    const setValue = type === "checkbox" ? checked : value;
    let filter = {
      ...filterState,
      [name]: setValue,
    };
    setFilterState({ ...filter });
  };

  
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  
  const handleFilterOpen = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    let filter = {
      startDate: startDate,
      endDate: endDate,
      contract: filterState.contract,
      payed: filterState.payed
    }
    handleFilterClose();
    console.log(filter)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
    <ProfileSignDocument
      isSignOpen={signDocument}
      hash={hash}
      closeHandler={signForm}
    />
    <ProfileFilters loading={false} />
      <Grid container>
        <Grid item xs={6} md={6} className={classes.titleBig}>
          <FormattedMessage id="profile.myContracts" />
          &nbsp;&nbsp;
          <FilterListIcon
                      className={classes.filter}
                      onClick={handleFilterOpen}
                    />
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleFilterClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      style={{
                          overflow: "hidden !important",
                          height: "400px",
                      }}
                    >
                      <Grid container className={classes.search}>
                        <Grid item md={12}>
                          <Typography className={classes.titleBig}>
                            <FormattedMessage id="profile.search" />
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.divider}>
                          <InputTextField
                            name="contract"
                            title={intl.formatMessage({ id: "search.contract" })}
                            value={filterState?.contract || ""}
                            enabled={true}
                            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                              handleFormChange(ev)
                            }
                          />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.divider}>
                          <DatePicker
                            selected={
                              startDate
                                ? moment(startDate, "yyyy-MM-DD").toDate()
                                : undefined
                            }
                            onChange={(date: Date) => setStartDate(date)}
                            selectsStart
                            locale={lv}
                            filterDate={isWeekday}
                            dateFormat="dd.MM.yyyy"
                            placeholderText={intl.formatMessage({ id: "search.dateFrom" })}
                            monthsShown={1}
                            className={classes.inputInput}
                          />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.divider}>
                          <DatePicker
                            selected={
                              endDate
                                ? moment(endDate, "yyyy-MM-DD").toDate()
                                : undefined
                            }
                            onChange={(date: Date) => setEndDate(date)}
                            selectsStart
                            locale={lv}
                            minDate={startDate || moment().toDate()}
                            filterDate={isWeekday}
                            dateFormat="dd.MM.yyyy"
                            placeholderText={intl.formatMessage({ id: "search.dateTo" })}
                            monthsShown={1}
                            className={classes.inputInput}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Checkbox
                            checked={filterState?.payed}
                            onChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                              handleFormChange(ev)
                            }
                            name="payed"
                            id="payed"
                            className={classes.checkbox}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />{" "}
                          <FormattedMessage id="search.payed" />
                        </Grid>
                        <Grid md={12} xs={12} style={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            size="small"
                            className={classes.button}
                            onClick={() => handleSearch()}
                          >
                            <FormattedMessage id="search" />
                          </Button>
                        </Grid>
                      </Grid>
                    </Popover>
          {profile?.contracts ? (
            <ProfileDocumentViewer
              fileName={documentName}
              isDocumentOpen={isDocumentOpen}
              closeHandler={popupHandler}
            />
          ) : null}
        </Grid>
        <Grid item xs={6} md={6}></Grid>
      </Grid>
      <Grid container direction="row" justify="center">
        {profile.contracts.length > 0 ? (
          <Hidden smDown>
            <Grid md={12}>
              <Grid container spacing={2} className={classes.tableHeader}>
                <Grid item md={2}>
                  <Typography className={classes.tableTitle}>
                    Dokuments
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography className={classes.tableTitle}>Tips</Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography className={classes.tableTitle}>
                    Nummurs
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography className={classes.tableTitle}>
                    Derīguma datums
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography className={classes.tableTitle}>
                    Darbības
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        ) : (
          <ProfileNoRows
            text={intl.formatMessage({ id: "profile.noContracts" })}
          />
        )}
        <Grid md={12}>
          <Box pt={3}></Box>
        </Grid>
        {profile.contracts?.map((contract) => (
          <Grid xs={12} md={12}>
            <Grid container className={classes.row}>
              <Grid item xs={2} md={2}>
                {contract?.file_name ? (
                  <img
                    src={"/images/product/pdf.png"}
                    alt={""}
                    height="30px"
                    onClick={() => openDocument(contract?.file_name)}
                  />
                ) : null}
              </Grid>
              <Grid item className={classes.tableText} xs={5} md={3}>
                {intl.formatMessage({
                  id: "profile.contract." + contract?.document_type,
                })}
              </Grid>
              <Grid item className={classes.tableText} xs={3} md={2}>
                {contract?.document_name || ""}
              </Grid>
              <Hidden smDown>
                <Grid item className={classes.tableText} md={2}>
                  {contract?.expiry_date}
                </Grid>
              </Hidden>
              <Grid item className={classes.middle} xs={2} md={3}>
                {contract?.signed ? (
                  <>
                    <Hidden smDown>
                      <VisibilityIcon
                        className={classes.edit}
                        onClick={() => openDocument(contract?.file_name)}
                      />
                    </Hidden>
                    <a
                      href={
                        process.env.REACT_APP_IMAGE_HOST +
                          "/files/documents/" +
                          contract?.file_name || ""
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FileDownloadIcon className={classes.red} />
                    </a>
                  </>
                ) : (
                  <>
                  <Hidden smDown>
                    <VisibilityIcon
                      className={classes.edit}
                      onClick={() => openDocument(contract?.file_name)}
                    />
                  </Hidden>
                  
                      <AssignmentIcon
                        className={classes.edit}
                        onClick={() => signDocumentOpen(contract?.id.toString())}
                      />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const mapStateToProps = ({ user, language }: ApplicationState) => ({
  profile: user.profile,
  language: language.language,
  contractsLoading: user.contractsLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserContracts: () => {
      dispatch(getUserContracts());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContract);
