import React, { ChangeEvent } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import CartBillingAddress from "./CartBillingAddress";
import CardBillingPayment from "./CartBillingPayment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "400px",
      display: "inline-grid",
      alignSelf: "center",
    },
    label: {
      fontSize: "20px",
      color: "#D3D3D3",
    },
    labelSelected: {
      fontSize: "16px",
      color: "#D3D3D3",
      textDecoration: "underline",
      textDecorationColor: "#DA2232",
      textDecorationThickness: "2px",
      textUnderlineOffset: "8px",
    },
    link: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#000000",
      paddingBottom: "16px",
      textDecoration: "underline",
    },
    checkbox: {
      color: "#000000",
    },
    button: {
      width: "250px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    title: {
      marginBottom: 2,
      height: "24px",
      fontSize: "16px",
      weight: 800,
      fontWeight: "bold",
      color: "#333333",
    },
    titleBig: {
      marginBottom: 2,
      height: "31px",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      color: "#333333",
      textAlign: "left",
    },
  })
);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
  },
  active: {
    "& $line": {
      borderColor: "#E3E3E3",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#E3E3E3",
    },
  },
  line: {
    borderColor: "#E3E3E3",
    borderTopWidth: 1,
    borderRadius: 1,
  },
  stepper: {
    textAlign: "left",
    alignSelf: "center",
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    color: "#333333",
  },
})(StepConnector);

function QontoStepIcon(props: StepIconProps) {
  const { active } = props;
  return (
    <div>
      {active ? (
        <img height="20px" src="/images/stepper-filled.svg" alt="" />
      ) : (
        <img height="20px" src="/images/stepper-empty.svg" alt="" />
      )}
    </div>
  );
}
interface IProps {
  formState: any;
  formErrors: any;
  onNext: (step: string) => any;
  handleFormChange: (e: ChangeEvent<HTMLInputElement>) => any;
}

const CartBilling: React.FC<IProps> = ({
  formState,
  formErrors,
  onNext,
  handleFormChange,
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Box p={4}>
      <Grid container spacing={3} direction="row" justify="center">
        <Grid item md={12}>
          <Box textAlign="center">
            <div className={classes.root}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                <Step key={1}>
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    className={
                      activeStep === 0 ? classes.labelSelected : classes.label
                    }
                    onClick={() => setActiveStep(0)}
                  >
                    <FormattedMessage id="billing.1" />
                  </StepLabel>
                </Step>
                <Step key={2}>
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    className={
                      activeStep === 1 ? classes.labelSelected : classes.label
                    }
                    onClick={() => setActiveStep(1)}
                  >
                    <FormattedMessage id="billing.2" />
                  </StepLabel>
                </Step>
              </Stepper>
            </div>
          </Box>
        </Grid>
        {activeStep === 0 ? (
          <CartBillingAddress
            formState={formState}
            formErrors={formErrors}
            handleFormChange={handleFormChange}
            onNext={setActiveStep}
          />
        ) : (
          <CardBillingPayment formState={formState} formBack={setActiveStep} />
        )}
      </Grid>
    </Box>
  );
};

export default CartBilling;
