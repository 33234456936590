/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { Categories, MenuTextData } from "../models/types";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { Box, Grid, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import { searchTopCategories } from "../store/products/action";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuItem: {
      border: "solid",
      borderRadius: "0px",
      borderColor: "#dadada",
      borderWidth: "thin",
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#FFF",
        borderColor: "#da2232",
      },
    },
    menuItemLink: {
      fontSize: "12px",
      color: "#333333",
      textDecoration: "none",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    categoryBox: {
      color: "#333333",
      fontSize: 16,
      fontWeight: "bold",
    },
    menuImage: {
      width: "30px",
      maxHeight: "30px",
    },
  })
);

interface IProps {
  title?: string;
}

interface PropsFromState {
  language: string;
  text: MenuTextData;
  loading: boolean;
  categories: Categories[];
}

interface propsFromDispatch {
  searchCategories: (id?: string) => any;
}

type AllProps = IProps & PropsFromState & propsFromDispatch;

const ProductCategories: React.FC<AllProps> = ({
  title,
  language,
  text,
  loading,
  categories,
  searchCategories,
}) => {
  useEffect(() => {
    searchCategories();
  }, []);

  const classes = useStyles();

  return (
    <Box pt={2} pb={1} pl={2} pr={2} className={classes.categoryBox}>
      {title ? <Box pb={2}>{title}</Box> : null}
      <Grid container spacing={3} direction="row" justify="center">
        {!loading
          ? categories.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.code}>
                <Link
                  to={"/product/category/" + item.code}
                  className={classes.menuItemLink}
                >
                  <MenuItem className={classes.menuItem}>
                    <ListItemIcon>
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_HOST +
                            "/files/menu_pictures/" +
                            text[item.code]?.image || ""
                        }
                        alt={text[item.code]?.value[language]}
                        className={classes.menuImage}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={text[item.code]?.value[language]}
                    ></ListItemText>
                  </MenuItem>
                </Link>
              </Grid>
            ))
          : null}
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ menu, products, language }: ApplicationState) => ({
  text: menu.text,
  language: language.language,
  loading: products.categoryMenuLoading,
  categories: products.categoryMenu,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    searchCategories: () => {
      dispatch(searchTopCategories());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategories);
