import React, { ChangeEvent, useEffect } from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TextField } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: '100%',
            fontSize: '10px',
            backgroundColor: '#ffffff',
            border: '1px solid #e3e3e3',
            margin: '0px',
            paddingTop: '8px',
            paddingLeft: '8px',
            paddingBottom: '8px',
            color: '#333333',
            '&$focused $notchedOutline': {
                border: '1px solid #e3e3e3'
            },
            '& label.Mui-focused': {
                color: '#333333',
                backgroundColor: '#ffffff',
            },
            '& label': {
                color: '#333333',
                backgroundColor: '#ffffff',
            },
        },
        labelError: {
            color: 'red',
            alignSelf: 'center',
            fontSize: '12px'
        },
    }),
);

interface IProps {
    name: string;
    title: string,
    value: string,
    error?: string,
    enabled?: boolean,
    formChange: (e: ChangeEvent<HTMLInputElement>) => any,
}

const InputPasswordField: React.FC<IProps> = ({
    name,
    title,
    value,
    error,
    enabled = true,
    formChange
}) => {
    useEffect(() => { }, []);

    const classes = useStyles();

    return (
        <>
            <TextField
                id={name}
                name={name}
                className={classes.textField}
                onChange={formChange}
                value={value || ''}
                disabled={enabled ? false : true}
                type="password"
                margin="normal"
                size="small"
                variant="outlined"
                label={title || ''}
            />
            {error ? (<span className={classes.labelError}><FormattedMessage id={error} /></span>) : (null)}
        </>
    );  
};

export default (InputPasswordField);
