/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { ChangeEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { Cart, MenuTextData, ProductsData, UserData, UserProfile } from "../models/types";
import { fetchMenu } from "../store/menu/action";
import { changeLanguage } from "../store/language/action";
import { getStatus, logoutUser, switchUser } from "../store/users/action";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MessageIcon from "@material-ui/icons/ChatBubble";
import {
  Box,
  Grid,
  Hidden,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Login from "./Login";
import MenuLine from "./MenuLine";
import { searchText } from "../store/products/action";
import { Autocomplete } from "@material-ui/lab";
import { useCookies } from "react-cookie";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 0.1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    search: {
      position: "relative",
      flexGrow: 1,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        cursor: "pointer",
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: "2px",
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    buttonText: {
      fontSize: "14px",
      fontWeight: "bold",
      paddingLeft: "10px",
    },
    selectText: {
      fontSize: "14px",
      fontWeight: "bold",
      paddingTop: "3px",
      paddingLeft: "5px",
    },
    inputInput: {
      height: "35px",
      color: "inherit",
      padding: "0px",
      border: "1px solid #DA2232",
      "& .MuiInputLabel-outlined": {
        transform: "translate(14px, 12px) scale(1)",
        color: "#333333",
        fontStyle: 'italic',
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    linkButton: {
      textDecoration: "none",
      color: "#000000",
    },
    topText: {
      fontSize: "14px",
      color: "#333333",
      verticalAlign: "middle",
      display: "inline-flex",
    },
    hl: {
      height: "70px",
    },
    clientService: {
      fontSize: "12px",
    },
    clientServicePhone: {
      fontSize: "12px",
      fontWeight: "bold",
    },
    clientBox: {
      borderRight: "1px solid #f2f2f2",
      textAlign: "right",
      paddingRight: "5px",
    },
    cart: {
      backgroundColor: "#DA2232",
      color: "#ffffff",
    },
    button: {
      width: "37px",
      height: "37px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    inputRoot: {
      padding: "0px",
      margin: "2px",
    },
    searchContent: {
      color: "#333333",
      border: "0px",
      height: "34px",
      width: "280px",
      borderRadius: "0px",
      textDecoration: "none",
      textAlign: "left",
    },
    searchImage: {
      width: "80px",
      textAlign: "center",
    },
    searchImageDetails: {
      maxWidth: "50px",
      maxHeight: "34px",
    },
    languageBox: {
      boxShadow:
        "rgba(0, 0, 0, 0.23) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      cursor: "pointer",
    },
    user: {
      color: "#000000",
      fontSize: "35px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    shoppingCart: {
      color: "#000000",
      fontSize: "35px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    red: {
      color: "#EC574E",
      fontSize: "30px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
  })
);

interface PropsFromState {
  language: string;
  login: boolean;
  loading: boolean;
  user: UserData;
  profile: UserProfile;
  cart?: Cart;
  searchResults: ProductsData[];
  text: MenuTextData;
}

interface propsFromDispatch {
  changeLanguage: (language: string) => any;
  searchText: (text: string) => any;
  fetchMenu: () => any;
  getStatus: (cookie: any, setCookie: any) => any;
  logoutUser: (setCookie: any) => any;
  switchUser: (setCookie: any, companyId: string) => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const HeaderLine: React.FC<AllProps> = ({
  language,
  login,
  loading,
  user,
  profile,
  cart,
  searchResults,
  text,
  changeLanguage,
  searchText,
  fetchMenu,
  getStatus,
  logoutUser,
  switchUser,
}) => {
  useEffect(() => {
    getStatus(cookies, setCookie);
    fetchMenu();
  }, []);

  useEffect(() => {
    setOptions(searchResults);
  }, [searchResults]);

  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorSwitcher, setSwitcherEl] = React.useState<null | HTMLElement>(
    null
  );
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [isLoginOpen, handleLogin] = useState(false);
  const [search, setSearch] = useState("");
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isSwitcherOpen = Boolean(anchorSwitcher);
  const [options, setOptions] = React.useState<ProductsData[]>([]);
  const [cookies, setCookie] = useCookies();
  const intl = useIntl();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSwitcherOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSwitcherEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSwitcherClose = () => {
    setSwitcherEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const popupHandler = () => {
    handleLogin((current) => !current);
  };

  const handleLanguage = (language: string) => {
    changeLanguage(language);
    handleMenuClose();
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearch(value);
    if (value.length > 1) {
      searchText(value);
    }
  };

  const handleSwitchUser = (companyId: string) => {
    switchUser(setCookie, companyId);
    setSwitcherEl(null);
  };

  const handleLogoutUser = () => {
    logoutUser(setCookie);
    handleLogin(false);
    history.push("/");
  };

  const menuId = "language-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        className={classes.selectText}
        value={"lv"}
        onClick={(e) => handleLanguage("lv")}
      >
        <FormattedMessage id="language.lv" />
      </MenuItem>
    </Menu>
  );

  const switcherId = "switcher-menu";
  const renderSwitcher =
    user.profile?.organization && user.profile?.organization.length > 1 ? (
      <Menu
        anchorEl={anchorSwitcher}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        id={switcherId}
        keepMounted
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={isSwitcherOpen}
        onClose={handleSwitcherClose}
      >
        {user.profile.organization.map((organizationDetails) => (
          <MenuItem
            className={classes.selectText}
            value={organizationDetails.number}
            onClick={(e) => handleSwitchUser(organizationDetails.number)}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item md={3}>
                {organizationDetails.type === "BUSINESS" ? (
                  <WorkOutlineIcon />
                ) : (
                  <PersonIcon />
                )}
              </Grid>
              <Grid item md={9}>
                {organizationDetails.name}
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Menu>
    ) : null;

  const mobileMenuId = "mobile-menu";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        {login && !loading ? (
          <Link to="/profile" className={classes.linkButton}>
            <IconButton
              edge="end"
              aria-label="User Account"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
              <div className={classes.buttonText}>{user?.profile?.name}</div>
            </IconButton>
          </Link>
        ) : null}
        {!login ? (
          <IconButton
            edge="end"
            aria-label="User Account"
            aria-haspopup="true"
            onClick={popupHandler}
            color="inherit"
          >
            <AccountCircle />
            <div className={classes.buttonText}>
              <FormattedMessage id="login" />
            </div>
          </IconButton>
        ) : null}
      </MenuItem>
      <MenuItem>
        <Link to="cart" className={classes.linkButton}>
          <IconButton aria-label="label" color="inherit">
            <Badge color="secondary">
              <NotificationsIcon />
            </Badge>
            <div className={classes.buttonText}>
              <FormattedMessage id="cart" />
            </div>
          </IconButton>
        </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar color="primary" position="sticky">
        <Login isLoginOpen={isLoginOpen} closeHandler={popupHandler} />
        <Hidden xsDown>
          <Grid container spacing={0} direction="row" justify="center">
            <Grid item xs={false} md={1}></Grid>
            <Grid item xs={false} md={3}>
              <Box textAlign="center">
                <Typography className={classes.topText}>
                  <img
                    src="/images/diennakts-klientu-serviss.svg"
                    alt=""
                    height="22px"
                  />
                  <FormattedMessage id="header.service" />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={false} md={3}>
              <Box textAlign="center">
                <Typography className={classes.topText}>
                  <img
                    src="/images/pieredze-buvnieciba.svg"
                    alt=""
                    height="22px"
                  />
                  <FormattedMessage id="header.experience" />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={false} md={3}>
              <Box textAlign="center">
                <Typography className={classes.topText}>
                  <img
                    src="/images/vienmer-izdevigas-cenas.svg"
                    alt=""
                    height="22px"
                  />
                  <FormattedMessage id="header.prices" />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={false} md={1}></Grid>
          </Grid>
        </Hidden>
        <Toolbar className={classes.hl}>
          <Hidden mdDown>
            <Link to="/">
              <Box>
                <img
                  src="/images/stats-rent-logo.svg"
                  height="40px"
                  alt="Stats Rent"
                />
              </Box>
            </Link>
            <div className={classes.search}>
              <Autocomplete
                id="options"
                className={classes.inputRoot}
                options={options}
                autoComplete
                includeInputInList
                noOptionsText={intl.formatMessage({ id: "NOT_FOUND" })}
                filterSelectedOptions
                onChange={(event: any, newValue: ProductsData | null) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                }}
                getOptionLabel={(option) => option?.title["lv"]}
                renderOption={(option) => {
                  return (
                    option.searchType === 'P' ? (
                    <Link to={"/product/" + option?.code}>
                      <Grid container alignItems="center">
                        <Grid item xs={3} className={classes.searchImage}>
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_HOST +
                                "/files/product_images/" +
                                option?.image[0].img || ""
                            }
                            alt={option?.image[0].description}
                            className={classes.searchImageDetails}
                          />
                        </Grid>
                        <Grid item xs={9} className={classes.searchContent}>
                          {option?.title["lv"]}
                        </Grid>
                      </Grid>
                    </Link> ) : (
                    <Link to={"/product/category/" + option?.code}>
                      <Grid container alignItems="center">
                        <Grid item xs={3} className={classes.searchImage}>
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_HOST +
                                "/files/menu_pictures/" +
                                option?.image[0].img || ""}
                            alt={option?.title["lv"]}
                            className={classes.searchImageDetails}
                          />
                        </Grid>
                        <Grid item xs={9} className={classes.searchContent}>
                          {option?.title["lv"]}
                        </Grid>
                      </Grid>
                    </Link>
                    )
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    onChange={handleSearch}
                    className={classes.inputInput}
                    fullWidth
                    label={search=== "" ? intl.formatMessage({ id: "searchProducts" }): ""}
                    InputLabelProps={{
                      shrink: false
                    }} 
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <InputAdornment position="end" />,
                    }}
                  />
                )}
              />
            </div>
          </Hidden>

          <Hidden mdUp>
            <Grid container spacing={0} direction="row">
              <Grid item xs={2}>
                {!login ? (
                  <IconButton
                    edge="end"
                    aria-label="User Account"
                    aria-haspopup="true"
                    onClick={popupHandler}
                    className={classes.user}
                  >
                    <PersonIcon style={{ fontSize: "35px" }} />
                  </IconButton>
                ) : null}
                {login && !loading ? (
                  <IconButton
                    edge="end"
                    aria-label="User Account"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleLogoutUser}
                  >
                    <LogoutIcon className={classes.red} />
                  </IconButton>
                ) : null}
              </Grid>
              <Grid item xs={8} style={{ textAlign: "center" }}>
                <Link to="/">
                  <img
                    src="/images/stats-rent-logo.svg"
                    alt="Stats Rent"
                    height="40px"
                  />
                </Link>
              </Grid>
              <Grid item xs={2}>
                <Link to="/cart" className={classes.linkButton}>
                  <IconButton aria-label="Shoping Cart" color="inherit">
                    <Badge
                      badgeContent={cart?.count}
                      classes={{ badge: classes.cart }}
                    >
                      <ShoppingCartIcon className={classes.shoppingCart} />
                    </Badge>
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </Hidden>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {!login ? (
              <IconButton
                edge="end"
                aria-label="User Account"
                aria-haspopup="true"
                onClick={popupHandler}
                className={classes.user}
              >
                <PersonIcon style={{ fontSize: "35px" }} />
              </IconButton>
            ) : null}
            {login && !loading ? (
              <>
                <Link to="/profile" className={classes.linkButton}>
                  <IconButton
                    edge="end"
                    aria-label="User Account"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    className={classes.user}
                  >
                    <PersonIcon style={{ fontSize: "35px" }} />
                    <div className={classes.buttonText}>
                      {user.profile.name}
                    </div>
                  </IconButton>
                </Link>
                {user.profile?.organization &&
                user.profile?.organization.length > 1 ? (
                  <IconButton
                    edge="end"
                    aria-label="User Organization"
                    aria-controls={switcherId}
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleSwitcherOpen}
                    className={classes.user}
                  >
                    <WorkOutlineIcon />
                    <div className={classes.buttonText}>
                      {user.profile.title}
                    </div>
                  </IconButton>
                ) : null}
              </>
            ) : null}
            {login && !loading ? (
              <IconButton
                edge="end"
                aria-label="User Account"
                aria-haspopup="true"
                color="inherit"
                onClick={handleLogoutUser}
              >
                <LogoutIcon className={classes.red} />
              </IconButton>
            ) : null}
            {profile?.inboxNew ? (
              <Link to="/profile/sazina" className={classes.linkButton}>
                <IconButton aria-label="Message" color="inherit">
                  <Badge
                    badgeContent={profile?.inboxNewCount}
                    classes={{ badge: classes.cart }}
                  >
                    <MessageIcon className={classes.shoppingCart} />
                  </Badge>
                </IconButton>
              </Link>
            ) : null}
            <Link to="/cart" className={classes.linkButton}>
              <IconButton aria-label="Shoping Cart" color="inherit">
                <Badge
                  badgeContent={cart?.count}
                  classes={{ badge: classes.cart }}
                >
                  <ShoppingCartIcon className={classes.shoppingCart} />
                </Badge>
              </IconButton>
            </Link>
            <Box pt={1} className={classes.clientBox}>
              <Typography className={classes.clientService}>
                <FormattedMessage id="header.clientService" />
              </Typography>
              <Typography className={classes.clientServicePhone}>
                <FormattedMessage id="header.clientServicePhone" />
              </Typography>
            </Box>
            <Box pl={2} pt={2} pr={2} onClick={handleMenuOpen}>
              <img
                src={"/images/languages/" + language + ".svg"}
                alt=""
                height="20px"
                className={classes.languageBox}
              />
            </Box>
          </div>
        </Toolbar>

        <Hidden xsUp>
          <AppBar color="secondary" position="sticky">
            <Toolbar>
              <Grid container spacing={0} direction="row">
                <Grid item xs={12}>
                  <Autocomplete
                    id="options"
                    className={classes.inputRoot}
                    options={options}
                    autoComplete
                    includeInputInList
                    noOptionsText={"Nekas netika atrasts"}
                    filterSelectedOptions
                    onChange={(event: any, newValue: ProductsData | null) => {
                      setOptions(newValue ? [newValue, ...options] : options);
                    }}
                    getOptionLabel={(option) => option?.title["lv"]}
                    renderOption={(option) => {
                      return (
                        <Link to={"/product/" + option?.code}>
                          <Grid container alignItems="center">
                            <Grid item className={classes.searchImage}>
                              <img
                                src={
                                  process.env.REACT_APP_IMAGE_HOST +
                                    "/files/product_images/" +
                                    option?.image[0].img || ""
                                }
                                alt={option?.image[0].description}
                                height="45px"
                              />
                            </Grid>
                            <Grid item xs>
                              <Typography className={classes.searchContent}>
                                {option?.title["lv"]}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Link>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        onChange={handleSearch}
                        className={classes.inputInput}
                        label={search=== "" ? intl.formatMessage({ id: "searchProducts" }): ""}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end"></InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Hidden>
        <MenuLine />
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderSwitcher}
    </>
  );
};

const mapStateToProps = ({ language, user, products, menu }: ApplicationState) => ({
  language: language.language,
  login: user.login,
  loading: user.loading,
  user: user.user,
  profile: user.profile,
  cart: user.cart,
  searchResults: products.searchResults,
  text: menu.text,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchMenu: () => {
      dispatch(fetchMenu());
    },
    changeLanguage: (language: string) => {
      dispatch(changeLanguage(language));
    },
    getStatus: (cookie: any, setCookie: any) => {
      dispatch(getStatus(cookie, setCookie));
    },
    logoutUser: (setCookie: any) => {
      dispatch(logoutUser(setCookie));
    },
    switchUser: (setCookie: any, companyId: string) => {
      dispatch(switchUser(setCookie, companyId));
    },
    searchText: (text: string) => {
      dispatch(searchText(text));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLine);
