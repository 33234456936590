import React from "react";
import { Box } from "@material-ui/core";

interface IProps {
  text: string;
}

const ProfileNoRows: React.FC<IProps> = ({
  text
}) => {

  return (
    <Box pt={5} pb={5} textAlign="center">
      <img src="/images/inbox.svg" width="100px" alt="" />
      <br/>
      <br/>
      {text}
    </Box>
  );
};

export default ProfileNoRows;