import { createIntl, createIntlCache } from "react-intl";

export const messages = {
  en: require(`./translationsEn.json`),
  lv: require(`./translationsLv.json`),
  ee: require(`./translationsEe.json`),
  lt: require(`./translationsLt.json`),
  ru: require(`./translationsRu.json`),
  de: require(`./translationsDe.json`)
};

const cache = createIntlCache();

let int = createIntl(
  {
    locale: "lv",
    messages: messages["lv"]
  },
  cache
);

const translate = (id: string, values?: {}) => {
  return int.formatMessage({ id }, values);
};

export default translate;

export const getMessages = (language: string) => {
  switch (language) {
    case "lv":
      return messages["lv"];
    case "lt":
      return messages["lt"];
    case "ee":
      return messages["ee"];
    case "en":
      return messages["en"];
    case "de":
      return messages["de"];
    case "ru":
      return messages["ru"];
  }
};