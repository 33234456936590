import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AppsIcon from "@material-ui/icons/Apps";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ProductCategories from "./ProductCategories";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    headerMenu: {
      boxShadow: "none",
      backgroundColor: "#4e342e",
    },
    headerProducts: {
      boxShadow: "none",
      backgroundColor: "#ebdcc4",
    },
    menuItem: {
      border: "solid",
      borderRadius: "4px",
      borderColor: "#ebdcc4",
      borderWidth: "thin",
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#FFF",
      },
    },
    menuItemLink: {
      fontSize: "12px",
      color: "#000000",
      textDecoration: "none",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    menuButtonDark: {
      color: "#333333",
      backgroundColor: "#ffffff",
      border: "0px",
      height: "34px",
      paddingLeft: "30px",
      paddingRight: "30px",
      borderRadius: "0px",
      textDecoration: "none",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        minWidth: "200px",
      },
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    menuButtonDarkSmall: {
      color: "#333333",
      backgroundColor: "#ffffff",
      border: "0px",
      height: "34px",
      paddingLeft: "15px",
      paddingRight: "15px",
      borderRadius: "0px",
      textDecoration: "none",
      textAlign: "center",
    },
    menuButtonLightCatalog: {
      color: "#ffffff",
      backgroundColor: "#333333",
      border: "0px",
      height: "34px",
      minWidth: "200px",
      borderRadius: "0px",
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#333333",
        color: "#ffffff",
      },
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    inputRoot: {
      color: "inherit",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "block",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    popoverPaper: {
      width: "90%",
      maxHeight: "unset",
    },
    popoverPaperMore: {
      width: "50%",
      maxHeight: "unset",
    },
  })
);

interface PropsFromState {
  login: boolean;
}

const MenuLine: React.FC<PropsFromState> = ({ login }) => {
  useEffect(() => {}, []);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <AppBar color="secondary" position="sticky">
        <Toolbar>
          <Button
            className={classes.menuButtonLightCatalog}
            aria-label="Show more"
            onClick={handleClick}
          >
            <AppsIcon />
            <FormattedMessage id="header.catalog" />
          </Button>
          <div className={classes.sectionDesktop}>
            <Link to="/ka-iznomat">
              <Button className={classes.menuButtonDark}>
                <FormattedMessage id="header.howTo" />
              </Button>
            </Link>

            {login ? (
              <Link to="/profile/iznoma">
                <Button className={classes.menuButtonDark}>
                  <FormattedMessage id="header.rentOwn" />
                </Button>
              </Link>
            ) : (
              <Link to="/iznoma-savu-tehniku">
                <Button className={classes.menuButtonDark}>
                  <FormattedMessage id="header.rentOwn" />
                </Button>
              </Link>
            )}
            {login ? (
              <Link to="/profile/remonts">
                <Button className={classes.menuButtonDark}>
                  <FormattedMessage id="header.repairs" />
                </Button>
              </Link>
            ) : (
              <Link to="/remonts">
                <Button className={classes.menuButtonDark}>
                  <FormattedMessage id="header.repairs" />
                </Button>
              </Link>
            )}
            <Link to="/transports">
              <Button className={classes.menuButtonDark}>
                <FormattedMessage id="header.transport" />
              </Button>
            </Link>
            <Link to="/kontakti">
              <Button className={classes.menuButtonDark}>
                <FormattedMessage id="header.contacts" />
              </Button>
            </Link>
            <Link to="/par-mums">
              <Button className={classes.menuButtonDark}>
                <FormattedMessage id="header.about" />
              </Button>
            </Link>

            {login ? (
              <Link to="/profile">
                <Button className={classes.menuButtonDark}>
                  <FormattedMessage id="profile.myCompany" />
                </Button>
              </Link>
            ) : null}
          </div>
          <div className={classes.sectionMobile}>
            <Button
              aria-label="Show more"
              className={classes.menuButtonDark}
              onClick={handleMobileMenuOpen}
            >
              <FormattedMessage id="header.more" />
              <MoreIcon />
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <Menu
        PopoverClasses={{ paper: classes.popoverPaper }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id="category-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <ProductCategories />
      </Menu>
      <Menu
        PopoverClasses={{ paper: classes.popoverPaperMore }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id="small-menu"
        anchorEl={mobileMoreAnchorEl}
        open={Boolean(isMobileMenuOpen)}
        onClose={handleMobileMenuClose}
        onClick={handleMobileMenuClose}
      >
        <MenuItem style={{ placeContent: "center" }}>
          <Link to="/ka-iznomat">
            <Button
              className={
                login ? classes.menuButtonDarkSmall : classes.menuButtonDark
              }
            >
              <FormattedMessage id="header.howTo" />
            </Button>
          </Link>
        </MenuItem>
        <MenuItem style={{ placeContent: "center" }}>
          <Link to="/iznoma-savu-tehniku">
            <Button
              className={
                login ? classes.menuButtonDarkSmall : classes.menuButtonDark
              }
            >
              <FormattedMessage id="header.rentOwn" />
            </Button>
          </Link>
        </MenuItem>
        <MenuItem style={{ placeContent: "center" }}>
          <Link to="/remonts">
            <Button
              className={
                login ? classes.menuButtonDarkSmall : classes.menuButtonDark
              }
            >
              <FormattedMessage id="header.repairs" />
            </Button>
          </Link>
        </MenuItem>
          <MenuItem style={{ placeContent: "center" }}>
            <Link to="/transports">
              <Button
                className={
                  login ? classes.menuButtonDarkSmall : classes.menuButtonDark
                }
              >
                <FormattedMessage id="header.transport" />
              </Button>
            </Link>
          </MenuItem>
          <MenuItem style={{ placeContent: "center" }}>
            <Link to="/kontakti">
              <Button
                className={
                  login ? classes.menuButtonDarkSmall : classes.menuButtonDark
                }
              >
                <FormattedMessage id="header.contacts" />
              </Button>
            </Link>
          </MenuItem>
        <MenuItem style={{ placeContent: "center" }}>
          <Link to="/par-mums">
            <Button
              className={
                login ? classes.menuButtonDarkSmall : classes.menuButtonDark
              }
            >
              <FormattedMessage id="header.about" />
            </Button>
          </Link>
        </MenuItem>
        {login ? (
          <MenuItem style={{ placeContent: "center" }}>
            <Link to="/profile">
              <Button
                className={
                  login ? classes.menuButtonDarkSmall : classes.menuButtonDark
                }
              >
                <FormattedMessage id="profile.myCompany" />
              </Button>
            </Link>
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

const mapStateToProps = ({ language, user, products }: ApplicationState) => ({
  login: user.login,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuLine);
