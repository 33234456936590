import { Reducer } from "redux";
import { ProductActionTypes, ProductState } from "./types";

export const initialState: ProductState = {
  products: [],
  product: [],
  similarProducts: [],
  newProducts: [],
  viewedProducts: [],
  categories: [],
  categoryMenu: [],
  categoryList: [],
  searchResults: [],
  errors: undefined,
  loading: false,
  loadingId: false,
  newLoading: false,
  viewedLoading: false,
  similarLoading: false,
  categoriesLoading: false,
  categoryListLoading: false,
  categoryMenuLoading: false,
};

const reducer: Reducer<ProductState> = (state = initialState, action) => {
  switch (action.type) {
    case ProductActionTypes.FETCH_SIMILAR_REQUEST: {
      return { ...state, similarLoading: true };
    }
    case ProductActionTypes.FETCH_NEW_REQUEST: {
      return { ...state, newLoading: true };
    }
    case ProductActionTypes.FETCH_VIEWED_REQUEST: {
      return { ...state, viewedLoading: true };
    }
    case ProductActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case ProductActionTypes.FETCH_LIST_REQUEST: {
      return { ...state, categoryListLoading: true };
    }
    case ProductActionTypes.FETCH_CATEGORY_REQUEST: {
      return { ...state, categoriesLoading: true };
    }
    case ProductActionTypes.FETCH_MENU_REQUEST: {
      return { ...state, categoryMenuLoading: true };
    }
    case ProductActionTypes.FETCH_SIMILAR_SUCCESS: {
      return {
        ...state,
        similarLoading: false,
        similarProducts: action.payload,
      };
    }
    case ProductActionTypes.FETCH_CATEGORY_SUCCESS: {
      return { ...state, categoriesLoading: false, categories: action.payload };
    }
    case ProductActionTypes.FETCH_LIST_SUCCESS: {
      return {
        ...state,
        categoryListLoading: false,
        categoryList: action.payload,
      };
    }
    case ProductActionTypes.FETCH_MENU_SUCCESS: {
      return {
        ...state,
        categoryMenuLoading: false,
        categoryMenu: action.payload,
      };
    }
    case ProductActionTypes.FETCH_NEW_SUCCESS: {
      return { ...state, newLoading: false, newProducts: action.payload };
    }
    case ProductActionTypes.FETCH_VIEWED_SUCCESS: {
      return { ...state, viewedLoading: false, viewedProducts: action.payload };
    }
    case ProductActionTypes.SEARCH_SUCCESS: {
      return { ...state, newLoading: false, searchResults: action.payload };
    }
    case ProductActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, products: action.payload };
    }
    case ProductActionTypes.FETCH_ID_REQUEST: {
      return { ...state, loadingId: true };
    }
    case ProductActionTypes.FETCH_ID_SUCCESS: {
      return { ...state, loadingId: false, product: action.payload };
    }
    case ProductActionTypes.FETCH_ID_ERROR: {
      return { ...state, loadingId: false, errors: action.payload };
    }
    case ProductActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as ProductsReducer };
