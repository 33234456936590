import React, { ChangeEvent } from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxTitle: {
      paddingBottom: 10,
      paddingTop: 10,
      fontWeight: 'bold',
      fontSize: '18px'
    },
    
  })
);

interface IProps {
  formState: any,
  onNext: (step: string) => any,
  handleFormChange: (e: ChangeEvent<HTMLInputElement>) => any,
}

const CartPayments: React.FC<IProps> = ({
  formState,
  handleFormChange,
  onNext
}) => {

  return (
    <Box m={3} justifyContent="center" >
    </Box>
  )
};

export default (CartPayments);
