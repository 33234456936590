import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Box, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import CookieConsent from "react-cookie-consent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    footerData: {
      backgroundColor: "#333333",
    },
    title: {
      color: "#ffffff",
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "24px",
      letterSpacing: "0.15px",
    },
    body: {
      color: "#ffffff",
      fontSize: "14px",
      fontStyle: "normal",
      lineHeight: "19px",
      letterSpacing: "0.25px",
    },
    copyright: {
      color: "#ffffff",
      fontSize: "11px",
      fontStyle: "normal",
      lineHeight: "14px",
      letterSpacing: "0.25px",
    },
    textRigh: {
      textAlign: "right",
      paddingRight: "1rem",
      lineHeight: "24px",
    },
    textCenter: {
      textAlign: "center",
    },
    footer: {
      textAlign: "left",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    },
    cookieLink: {
      color: "#da2232",
    },
    partnerLogo: {
      maxHeight: "60px",
      maxWidth: "120px",
      margin: "0.7em",
      boxSizing: "border-box",
    },
  })
);

interface PropsFromState {}

interface propsFromDispatch {}

type AllProps = PropsFromState & propsFromDispatch;

const Footer: React.FC<AllProps> = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [showAll, setShowAll] = useState(true);

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={intl.formatMessage({ id: "cookie.agree" })}
        cookieName="StatsCookies"
        style={{ background: "#333333", opacity: "0.8" }}
        buttonStyle={{
          color: "#ffffff",
          backgroundColor: "#da2232",
          fontSize: "15px",
        }}
        expires={0}
      >
        <FormattedMessage id="cookie.text" />
        &nbsp;
        <Link to="/sikdatnes" className={classes.cookieLink}>
          <FormattedMessage id="consent.title" />
        </Link>
        &nbsp; & &nbsp;
        <Link to="/privatuma-politika" className={classes.cookieLink}>
          <FormattedMessage id="personData.title" />
        </Link>
      </CookieConsent>

      <AppBar className={classes.footerData} position="static">
        <Box pt={2} pb={2} className={classes.footer}>
          <Grid container spacing={1} direction="row" justify="center">
            <Grid item xs={12} sm={12} md={12}>
              <Box textAlign={{ xs: "center", md: "left" }} pb={2}>
                <img
                  src="/images/footer/stats-rent-footer.png"
                  alt=""
                  height="30px"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Box textAlign={{ xs: "center", md: "left" }}>
                <Typography className={classes.title}>RĪGA</Typography>
                <Typography className={classes.body}>
                  <a
                    href="https://www.google.com/maps/place/Kroņu+iela+11,+Vidzemes+priekšpilsēta,+Rīga,+LV-1084,+Latvia/@56.957055,24.160341,15z/data=!4m5!3m4!1s0x46eece466e860e73:0xee59d4f2561a0434!8m2!3d56.9618366!4d24.1595953?hl=en-GB"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.body}
                  >
                    Kroņu iela 11
                  </a>
                  <br />
                  +371 28 681 000
                  <br />
                  kronu@stats.lv
                  <br />
                  <br />
                </Typography>
                <Grid container className={classes.body}>
                  <Grid item xs={2} className={classes.textRigh}>
                    I.
                  </Grid>
                  <Grid item xs={8}>
                    7.00 – 18.00
                  </Grid>
                  {!showAll ? (
                    <Grid
                      item
                      xs={2}
                      className={classes.textCenter}
                      onClick={() => setShowAll(true)}
                    >
                      <ExpandMoreIcon fontSize="small" />
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={2}
                        className={classes.textCenter}
                        onClick={() => setShowAll(false)}
                      >
                        <ExpandLessIcon fontSize="small" />
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        II.
                      </Grid>
                      <Grid item xs={10}>
                        7.00 – 18.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        III.
                      </Grid>
                      <Grid item xs={10}>
                        7.00 – 18.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        IV.
                      </Grid>
                      <Grid item xs={10}>
                        7.00 – 18.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        V.
                      </Grid>
                      <Grid item xs={10}>
                        7.00 – 18.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        VI.
                      </Grid>
                      <Grid item xs={10}>
                        -
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        VII.
                      </Grid>
                      <Grid item xs={10}>
                        -
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Box textAlign={{ xs: "center", md: "left" }}>
                <Typography className={classes.title}>RĪGA</Typography>
                <Typography className={classes.body}>
                  <a
                    href="https://www.google.com/maps/place/Kārļa+Ulmaņa+gatve+82,+Zemgales+priekšpilsēta,+Rīga,+LV-1002,+Latvia/@56.928145,24.0509,15z/data=!4m5!3m4!1s0x46eed06b813baf87:0x8c0fb841b9abf828!8m2!3d56.9281449!4d24.0509002?hl=en-GB"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.body}
                  >
                    Kārļa Ulmaņa gatve 82
                  </a>
                  <br />
                  +371 26 460 242
                  <br />
                  remtes@stats.lv
                  <br />
                  <br />
                </Typography>
                <Grid container className={classes.body}>
                  <Grid item xs={2} className={classes.textRigh}>
                    I.
                  </Grid>
                  <Grid item xs={8}>
                    8.00 – 17.00
                  </Grid>
                  {!showAll ? (
                    <Grid
                      item
                      xs={2}
                      className={classes.textCenter}
                      onClick={() => setShowAll(true)}
                    >
                      <ExpandMoreIcon fontSize="small" />
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={2}
                        className={classes.textCenter}
                        onClick={() => setShowAll(false)}
                      >
                        <ExpandLessIcon fontSize="small" />
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        II.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 17.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        III.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 17.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        IV.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 17.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        V.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 17.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        VI.
                      </Grid>
                      <Grid item xs={10}>
                        -
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        VII.
                      </Grid>
                      <Grid item xs={10}>
                        -
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Box textAlign={{ xs: "center", md: "left" }}>
                <Typography className={classes.title}>JELGAVA</Typography>
                <Typography className={classes.body}>
                  <a
                    href="https://www.google.com/maps?ll=56.662032,23.768077&z=15&t=m&hl=en-GB&gl=US&mapclient=embed&q=Aviācijas+iela+18+Jelgava,+LV-3004"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.body}
                  >
                    Aviācijas iela 18a
                  </a>
                  <br />
                  +371 28 625 373
                  <br />
                  jelgava@stats.lv
                  <br />
                  <br />
                </Typography>
                <Grid container className={classes.body}>
                  <Grid item xs={2} className={classes.textRigh}>
                    I.
                  </Grid>
                  <Grid item xs={8}>
                    8.00 – 17.00
                  </Grid>
                  {!showAll ? (
                    <Grid
                      item
                      xs={2}
                      className={classes.textCenter}
                      onClick={() => setShowAll(true)}
                    >
                      <ExpandMoreIcon fontSize="small" />
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={2}
                        className={classes.textCenter}
                        onClick={() => setShowAll(false)}
                      >
                        <ExpandLessIcon fontSize="small" />
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        II.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 17.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        III.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 17.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        IV.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 17.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        V.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 18.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        VI.
                      </Grid>
                      <Grid item xs={10}>
                        -
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        VII.
                      </Grid>
                      <Grid item xs={10}>
                        -
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Box textAlign={{ xs: "center", md: "left" }}>
                <Typography className={classes.title}>VALMIERA</Typography>
                <Typography className={classes.body}>
                  <a
                    href="https://www.google.com/maps/place/Patversmes+iela+9,+Valmiera,+LV-4201,+Latvia/@57.547688,25.389956,15z/data=!4m5!3m4!1s0x46ebedd97780268b:0x40887023690f262c!8m2!3d57.5476881!4d25.3899556?hl=en-GB"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.body}
                  >
                    Patversmes iela 9
                  </a>
                  <br />
                  +371 26 468 877
                  <br />
                  valmiera@stats.lv
                  <br />
                  <br />
                </Typography>
                <Grid container className={classes.body}>
                  <Grid item xs={2} className={classes.textRigh}>
                    I.
                  </Grid>
                  <Grid item xs={8}>
                    8.00 – 17.00
                  </Grid>
                  {!showAll ? (
                    <Grid
                      item
                      xs={2}
                      className={classes.textCenter}
                      onClick={() => setShowAll(true)}
                    >
                      <ExpandMoreIcon fontSize="small" />
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={2}
                        className={classes.textCenter}
                        onClick={() => setShowAll(false)}
                      >
                        <ExpandLessIcon fontSize="small" />
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        II.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 17.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        III.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 17.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        IV.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 17.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        V.
                      </Grid>
                      <Grid item xs={10}>
                        8.00 – 18.00
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        VI.
                      </Grid>
                      <Grid item xs={10}>
                        -
                      </Grid>
                      <Grid item xs={2} className={classes.textRigh}>
                        VII.
                      </Grid>
                      <Grid item xs={10}>
                        -
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Box
                style={{ display: "none" }}
                textAlign={{ xs: "center", md: "left" }}
              >
                <Typography className={classes.title}>
                  <FormattedMessage id="transport" />
                </Typography>
                <Typography className={classes.body}>
                  <Link to="/transports" className={classes.body}>
                    <FormattedMessage id="order" />
                  </Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Box
                style={{ display: "none" }}
                textAlign={{ xs: "center", md: "left" }}
              >
                <Typography className={classes.title}>
                  <FormattedMessage id="about" />
                </Typography>
                <Typography className={classes.body}>
                  <Link to="/" className={classes.body}>
                    Stats Rent
                  </Link>
                  <br />
                  <Link to="/atsauksmes" className={classes.body}>
                    <FormattedMessage id="feedback" />
                  </Link>
                  <br />
                  <Link to="/kontakti" className={classes.body}>
                    <FormattedMessage id="contacts" />
                  </Link>
                </Typography>
              </Box>
              <Box textAlign={{ xs: "center", md: "right" }} pt={2}>
                <a
                  href="https://www.youtube.com/channel/UCHKXK3tQpQhT1t5Ok0JW9oA/featured"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/YouTube.svg"
                    height="24px"
                    alt="STATS Rent Youtube"
                  />
                </a>
              </Box>
              <Box pt={2} textAlign={{ xs: "center", md: "right" }}>
                <a
                  href="https://www.facebook.com/StatsRent"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/footer/facebook-white.png"
                    alt=""
                    height="24px"
                  />
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                direction="row"
                justify="center"
                alignContent="center"
              >
                <Grid item xs={false} md={8}></Grid>
                <Grid item xs={false} md={8}></Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    style={{ display: "none" }}
                    textAlign={{ xs: "center", md: "right" }}
                  >
                    <Typography className={classes.title}>
                      <FormattedMessage id="header.clientService" />:{" "}
                      <FormattedMessage id="header.clientServicePhone" />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={12} md={12} justifyContent="center">
                <Typography className={classes.title}>Mūsu partneri</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box textAlign="center">
                <img src="/images/partners/newcomlogo.png" alt="" className={classes.partnerLogo} />
                <img src="/images/partners/bukoteks-logo.png" alt="" className={classes.partnerLogo}/>
                <img src="/images/partners/Shenberg.png" alt="" className={classes.partnerLogo}/>
                <img src="/images/partners/tilts_logo.png" alt="" className={classes.partnerLogo}/>
                <img src="/images/partners/acb-logo-clean.png" alt="" className={classes.partnerLogo}/>
                <img src="/images/partners/rigas_tilti_logo.png" alt="" className={classes.partnerLogo}/>
                <img src="/images/partners/piche_logo.png" alt="" className={classes.partnerLogo}/>
                <img src="/images/partners/Torensberg_logo_RGB.png" alt="" className={classes.partnerLogo}/>
                <img src="/images/partners/IGATE_logo.png" alt="" className={classes.partnerLogo}/>
                <img src="/images/partners/BaltijasBuve.png" alt="" className={classes.partnerLogo}/>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md>
                  <Box textAlign="center">
                    <a
                      href="https://www.stats.lv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/footer/stats.png"
                        alt=""
                        height="18px"
                      />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} md>
                  <Box textAlign="center">
                    <a
                      href="https://stats.lv/company/stats-real-estate/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/footer/stats-real-estate.png"
                        alt=""
                        height="18px"
                      />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} md>
                  <Box textAlign="center">
                    <a
                      href="https://www.statsrent.lv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/footer/stats-rent.png"
                        alt=""
                        height="18px"
                      />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} md>
                  <Box textAlign="center">
                    <a
                      href="https://stats.lv/company/stats-build/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/footer/stats-build.png"
                        alt=""
                        height="18px"
                      />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} md>
                  <Box textAlign="center">
                    <a
                      href="https://stats.lv/company/harmony-home/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/footer/stats-harmony-home.png"
                        alt=""
                        height="18px"
                      />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} md>
                  <Box textAlign="center">
                    <a
                      href="http://rigapro.lv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/footer/stats-riga-pro.png"
                        alt=""
                        height="18px"
                      />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} md>
                  <Box textAlign="center">
                    <a
                      href="https://vefkvartals.lv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/footer/stats-vef-kvartals.png"
                        alt=""
                        height="18px"
                      />
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid container direction="row">
                <Grid item xs={12} md={6}>
                  <Box pt={3} textAlign={{ xs: "center", md: "left" }}>
                    <Typography className={classes.copyright}>
                      <FormattedMessage id="copyright" />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box pt={3} textAlign={{ xs: "center", md: "right" }}></Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </AppBar>
    </>
  );
};

const mapStateToProps = ({ menu }: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
