import React, { ChangeEvent, useEffect, useState } from "react";
import {
  MenuData,
  MenuTextData,
  ProductAvailability,
  ProductDetails,
  SelectData,
} from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Hidden } from "@material-ui/core";
import InputTextField from "./TextField";
import {
  deleteProduct,
  deleteProductAvailability,
  deleteProductImage,
  getProductDetails,
  saveProduct,
} from "../store/users/action";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { FormattedMessage, useIntl } from "react-intl";
import InputSelectField from "./SelectField";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import lv from "date-fns/locale/lv";
import { getDay } from "date-fns";
import { ApplicationState } from "../store";
import { useDropzone } from "react-dropzone";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

registerLocale("lv", {
  ...lv,
  options: {
    weekStartsOn: 1,
    lastDayOfWeek: 0,
    firstWeekContainsDate: 1,
  },
});

const isWeekday = (date: Date) => {
  const day: number = getDay(date);
  return day !== 0;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectField: {
      width: "100%",
      height: "39px",
      fontSize: "16px",
      backgroundColor: "#ffffff",
      border: "1px solid #e3e3e3",
      paddingLeft: "10px",
      margin: "0px",
    },
    checkbox: {
      color: "#333333",
    },
    buttonEnd: {
      textAlign: "end",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    buttonStart: {
      textAlign: "start",
      alignSelf: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    button: {
      width: "220px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        width: "130px",
        fontSize: "14px",
        padding: "5px",
      },
    },
    buttonAdd: {
      width: "220px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        padding: "5px",
      },
    },
    buttonDelete: {
      width: "120px",
      backgroundColor: "#333333",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#000000",
        color: "#ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        width: "130px",
        fontSize: "14px",
        padding: "5px",
      },
    },
    back: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    backIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    inputInput: {
      padding: theme.spacing(2, 1.5, 1.5, 0),
      transition: theme.transitions.create("width"),
      paddingLeft: `calc(0.1em + ${theme.spacing(1)}px)`,
      width: "94%",
      borders: "0px",
      background: "#ffffff",
      borderTop: "0px",
      borderRight: "0px",
      borderLeft: "0px",
      fontSize: "16px",
      color: "#333333",
      borderBottomColor: "1px solid #e3e3e3",
    },
    uploadZone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: "2px",
      borderRadius: "2px",
      borderColor: "#e3e3e3",
      borderStyle: "dashed",
      background: "#f9f9f9",
      color: "#333333",
      outline: "none",
      transition: "border .24s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
        background: "#cccccc",
      },
    },
    thumbsContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 16,
    },
    thumb: {
      display: "grid",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 130,
      padding: 4,
      boxSizing: "border-box",
      color: "#aaaaaa",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
        border: "1px solid #da2232",
      },
    },
    thumbInner: {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
    },
    thumbInnerCenter: {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
      justifyContent: "center",
    },
    img: {
      display: "block",
      width: "auto",
      height: "100%",
    },
    red: {
      color: "#EC574E",
      fontSize: "40px",
      paddingTop: "25px",
    },
    right: {
      textAlign: "center",
    },
    availability: {
      border: "1px #f9f9f9 solid",
    },
  })
);

interface IProps {
  editProductId?: string;
  editProduct: boolean;
  setEditProduct: (editProduct: boolean) => any;
}

interface PropsFromState {
  menu: MenuData[];
  text: MenuTextData;
  productDetails: ProductDetails;
  productDetailsLoading: boolean;
}

interface propsFromDispatch {
  getProductDetails: (productId: string) => any;
  saveProduct: (payload: any, formData: FormData) => any;
  deleteProduct: (id: string) => any;
  deleteProductAvailability: (productId: string, id: string) => any;
  deleteProductImage: (productId: string, id: string) => any;
}

type AllProps = IProps & propsFromDispatch & PropsFromState;

const ProfileMyProductsEdit: React.FC<AllProps> = ({
  editProductId,
  editProduct,
  productDetails,
  productDetailsLoading,
  menu,
  text,
  getProductDetails,
  saveProduct,
  deleteProduct,
  deleteProductAvailability,
  deleteProductImage,
  setEditProduct,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [formState, setFormState] = useState<ProductDetails>({
    availability: [],
    images: [],
  });
  const [formError, setFormErrors] = useState<any>({});
  const [formCategories, setFormCategories] = useState<any>({});
  const [files, setFiles] = useState<File[]>([]);
  const [filePreview, setFilePreview] = useState([]);

  useEffect(() => {
    if (editProductId && editProduct) getProductDetails(editProductId);
    else {
      setFormState({
        availability: [],
        images: [],
      });
      setFilePreview([]);
      setFiles([]);
      setFormCategories({});
    }
  }, [editProductId, editProduct]);

  useEffect(() => {
    if (
      productDetails &&
      !productDetailsLoading &&
      productDetails?.id === editProductId
    ) {
      setFilePreview([]);
      setFiles([]);
      setFormCategories({});
      setFormState(productDetails);
      setFormCategories(productDetails.categoryTree);
      var nextFiles = [];
      if (productDetails?.images) {
        productDetails?.images.map((image) => {
          nextFiles.push({
            id: image.id,
            preview:
              process.env.REACT_APP_IMAGE_HOST + "/files/uploads/" + image.name,
          });
        });
      }
      setFilePreview(nextFiles);
    }
  }, [productDetails, productDetailsLoading]);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      onDrop: (acceptedFiles) => {
        var nextFiles = filePreview;
        var fileList = files;
        acceptedFiles.map((file) => {
          fileList.push(file);
        });
        setFiles(fileList);
        acceptedFiles.map((file) => {
          nextFiles.push({ preview: URL.createObjectURL(file) });
        });
        setFilePreview(nextFiles);
      },
    });

  const thumbs = filePreview.map((file, index) => (
    <>
      <div className={classes.thumb} key={file.name}>
        <div className={classes.thumbInner} key={index}>
          <img
            src={file.preview}
            className={classes.img}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
            alt={file.name}
          />
        </div>
        <div
          className={classes.thumbInnerCenter}
          key={index}
          onClick={() => deleteProductPicture(formState?.id, file?.id, index)}
        >
          <FormattedMessage id="delete" />
        </div>
      </div>
    </>
  ));

  const saveProductForm = () => {
    if (validateForm()) {
      let product = {
        id: formState.id,
        categoryTree: formCategories,
        name: formState.name,
        description: formState.description,
        productAvailability: formState.availability,
      };
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i]);
      }
      saveProduct(product, formData);
      setEditProduct(false);
    }
  };

  const setStartDate = (startDate: Date, index: number) => {
    const availability: ProductAvailability[] = formState.availability.map(
      (row, rindex) => {
        if (rindex === index) {
          return {
            ...row,
            startDate: moment(startDate).format("YYYY-MM-DD"),
          };
        } else {
          return row;
        }
      }
    );

    setFormState({
      ...formState,
      availability: availability,
    });
  };

  const setEndDate = (endDate: Date, index: number) => {
    const availability: ProductAvailability[] = formState.availability.map(
      (row, rindex) => {
        if (rindex === index) {
          return {
            ...row,
            endDate: moment(endDate).format("YYYY-MM-DD"),
          };
        } else {
          return row;
        }
      }
    );

    setFormState({
      ...formState,
      availability: availability,
    });
  };

  const validateForm = () => {
    let formData: any = formState;
    let formIsValid = true;
    let error = {};

    //SSN
    if (!formData.ssn) {
      error = { ...error, ssn: "field.empty" };
    }

    setFormErrors({ ...error });
    return formIsValid;
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value, type } = e.currentTarget;
    const setValue = type === "checkbox" ? checked : value;
    let employee = {
      ...formState,
      [name]: setValue,
    };
    setFormState({ ...employee });
  };

  const addAvailability = () => {
    let availability = formState.availability;
    availability.push({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: undefined,
    });
    setFormState({ ...formState, availability: availability });
  };

  const deleteProductAvailabilityForm = (
    id: string,
    availablityId: string,
    index: number
  ) => {
    if (availablityId !== undefined) {
      deleteProductAvailability(id, availablityId);
      const availability: ProductAvailability[] = formState.availability.filter(
        (row) => row.id !== Number(availablityId)
      );
      setFormState({
        ...formState,
        availability: availability,
      });
    } else {
      if (index) {
        const availability: ProductAvailability[] =
          formState.availability.filter((row, i) => i !== index);
        setFormState({
          ...formState,
          availability: availability,
        });
      }
    }
  };

  const deleteProductPicture = (id: string, imageId: string, index: number) => {
    if (imageId !== undefined) {
      deleteProductImage(id, imageId);
      const fileList = filePreview.filter((row) => row.id !== Number(imageId));
      setFilePreview(fileList);
    } else {
      if (index) {
        const fileList = filePreview.filter((row, i) => i !== index);
        setFilePreview(fileList);
      }
    }
  };

  const deleteProductForm = () => {
    if (formState.id) {
      deleteProduct(formState.id);
      setEditProduct(false);
    }
  };

  const handleFormChangeList = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { checked, name, value, type } = e.currentTarget;
    const setValue = type === "checkbox" ? checked : value;
    const availability: ProductAvailability[] = formState.availability.map(
      (row, rindex) => {
        if (rindex === index) {
          return {
            ...row,
            [name]: setValue,
          };
        } else {
          return row;
        }
      }
    );

    setFormState({
      ...formState,
      availability: availability,
    });
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;
    setFormCategories({
      ...formCategories,
      [name]: value,
    });
  };

  const convertMenuHeader = () => {
    var menuToSelect: SelectData[] = [];
    if (menu && text)
      menu.map((header) => {
        menuToSelect.push({
          code: header.code,
          value: text[header.code].value,
        });
      });
    return menuToSelect;
  };

  const convertMenuHeaderChildred = (menu: MenuData[]) => {
    var menuToSelect: SelectData[] = [];
    if (menu && text)
      menu.map((header) => {
        menuToSelect.push({
          code: header.code,
          value: text[header.code].value,
        });
      });
    return menuToSelect;
  };

  const convertMenuHeaderChild = (menu: MenuData[], parent: String) => {
    var menuToSelect: SelectData[] = [];
    if (parent && menu && text)
      for (var i = 0; i < menu.length; i++) {
        if (menu[i].code === parent) {
          menuToSelect = convertMenuHeaderChildred(menu[i].children);
          break;
        }
        if (Array.isArray(menu[i].children)) {
          menuToSelect = convertMenuHeaderChild(menu[i].children, parent);
          if (Array.isArray(menuToSelect) && menuToSelect.length > 0) break;
        }
      }
    return menuToSelect;
  };

  return (
    <Grid container spacing={2} direction="row" justify="center">
      <Grid item xs={12} md={12}>
        <b>Kategorija</b>
      </Grid>
      <Grid item xs={12} md={12}>
        <InputSelectField
          name="category1"
          title={intl.formatMessage({ id: "profile.lendCategory" })}
          list={convertMenuHeader()}
          value={formCategories?.category1}
          error={formError.category1}
          formChange={handleSelectChange}
        />
      </Grid>
      {formCategories?.category1 ? (
        <Grid item xs={12} md={12}>
          <InputSelectField
            name="category2"
            title={intl.formatMessage({ id: "profile.lendCategory" })}
            list={convertMenuHeaderChild(menu, formCategories?.category1)}
            value={formCategories?.category2}
            error={formError.category2}
            formChange={handleSelectChange}
          />
        </Grid>
      ) : null}
      {formCategories?.category2 ? (
        <Grid item xs={12} md={12}>
          <InputSelectField
            name="category3"
            title={intl.formatMessage({ id: "profile.lendCategory" })}
            list={convertMenuHeaderChild(menu, formCategories?.category2)}
            value={formCategories?.category3}
            error={formError.category3}
            formChange={handleSelectChange}
          />
        </Grid>
      ) : null}
      {formCategories?.category3 ? (
        <Grid item xs={12} md={12}>
          <InputSelectField
            name="category4"
            title={intl.formatMessage({ id: "profile.lendCategory" })}
            list={convertMenuHeaderChild(menu, formCategories?.category3)}
            value={formCategories?.category4}
            error={formError.category4}
            formChange={handleSelectChange}
          />
        </Grid>
      ) : null}
      <Grid item xs={12} md={12}>
        <b>Tehnikas apraksts</b>
      </Grid>
      <Grid item xs={12} md={12}>
        <InputTextField
          name="name"
          title={intl.formatMessage({ id: "profile.lendName" })}
          value={formState?.name || ""}
          error={formError.name}
          enabled={true}
          formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
            handleFormChange(ev)
          }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <InputTextField
          name="description"
          title={intl.formatMessage({ id: "profile.lendDescription" })}
          value={formState?.description || ""}
          error={formError.description}
          enabled={true}
          formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
            handleFormChange(ev)
          }
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <b>Pieejamie datumi</b>
      </Grid>

      {formState?.availability
        ? formState?.availability.map((availability: any, index) => (
            <>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <InputTextField
                      name="location"
                      title={intl.formatMessage({
                        id: "profile.lendLocation",
                      })}
                      value={availability?.location || ""}
                      error={formError.location}
                      enabled={true}
                      formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                        handleFormChangeList(ev, index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputTextField
                      name="priceDay"
                      title={intl.formatMessage({
                        id: "profile.lendPriceDay",
                      })}
                      value={availability?.priceDay || ""}
                      error={formError.priceDay}
                      enabled={true}
                      formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                        handleFormChangeList(ev, index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputTextField
                      name="priceMonth"
                      title={intl.formatMessage({
                        id: "profile.lendPriceMonth",
                      })}
                      value={availability?.priceMonth || ""}
                      error={formError.priceMonth}
                      enabled={true}
                      formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                        handleFormChangeList(ev, index)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    No:
                    <DatePicker
                      selected={
                        availability?.startDate
                          ? moment(
                              availability?.startDate,
                              "yyyy-MM-DD"
                            ).toDate()
                          : undefined
                      }
                      onChange={(date: Date) => setStartDate(date, index)}
                      selectsStart
                      locale={lv}
                      minDate={moment().toDate()}
                      filterDate={isWeekday}
                      dateFormat="dd.MM.yyyy"
                      placeholderText={intl.formatMessage({
                        id: "profile.activeFrom",
                      })}
                      monthsShown={1}
                      className={classes.inputInput}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    Līdz:
                    <DatePicker
                      selected={
                        availability?.endDate
                          ? moment(availability?.endDate, "yyyy-MM-DD").toDate()
                          : undefined
                      }
                      onChange={(date: Date) => setEndDate(date, index)}
                      selectsEnd
                      monthsShown={2}
                      locale={lv}
                      startDate={moment(
                        availability?.startDate,
                        "YYYY-MM-DD"
                      ).toDate()}
                      minDate={moment(
                        availability?.startDate,
                        "YYYY-MM-DD"
                      ).toDate()}
                      filterDate={isWeekday}
                      dateFormat="dd.MM.yyyy"
                      placeholderText={intl.formatMessage({
                        id: "profile.activeTo",
                      })}
                      className={classes.inputInput}
                    />
                  </Grid>
                  <Grid xs={12} md={2} className={classes.right}>
                    <DeleteForeverIcon
                      className={classes.red}
                      onClick={() =>
                        deleteProductAvailabilityForm(
                          formState?.id,
                          availability?.id,
                          index
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))
        : null}
      <Grid item xs={12} md={12}>
        <Button
          variant="contained"
          onClick={() => addAvailability()}
          size="large"
          className={classes.buttonAdd}
        >
          <FormattedMessage id="profile.lendAddAvailability" />
        </Button>
      </Grid>

      <Grid item xs={12} md={12}>
        <Box p={4}>
          <section>
            <div {...getRootProps({ className: classes.uploadZone })}>
              <input {...getInputProps()} />
              <p>
                <FormattedMessage id="profile.uploadImages" />
              </p>
            </div>
            <aside className={classes.thumbsContainer}>{thumbs}</aside>
          </section>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item md={6} xs={6} className={classes.buttonStart}>
            <>
              <span
                onClick={() => setEditProduct(false)}
                className={classes.backIcon}
              >
                {" "}
                &lt;{" "}
              </span>
              <span
                onClick={() => setEditProduct(false)}
                className={classes.back}
              >
                <FormattedMessage id="back" />
              </span>
            </>
          </Grid>
          <Grid item md={6} xs={6} className={classes.buttonEnd}>
            <Hidden smDown>
              {editProductId ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => deleteProductForm()}
                    size="large"
                    className={classes.buttonDelete}
                  >
                    <FormattedMessage id="delete" />
                  </Button>
                  &nbsp;
                </>
              ) : null}
            </Hidden>
            <Button
              variant="contained"
              onClick={() => saveProductForm()}
              size="large"
              className={classes.button}
            >
              <FormattedMessage id="save" />
            </Button>
          </Grid>
          <Hidden smUp>
            <Grid item md={12} xs={12} className={classes.buttonEnd}>
              {editProductId ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => deleteProductForm()}
                    size="large"
                    className={classes.buttonDelete}
                  >
                    <FormattedMessage id="delete" />
                  </Button>
                  &nbsp;
                </>
              ) : null}
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ user, menu }: ApplicationState) => ({
  menu: menu.menu,
  text: menu.text,
  productDetails: user.productDetails,
  productDetailsLoading: user.productDetailsLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getProductDetails: (productId: string) => {
      dispatch(getProductDetails(productId));
    },
    saveProduct: (payload: any, formData: FormData) => {
      dispatch(saveProduct(payload, formData));
    },
    deleteProduct: (id: string) => {
      dispatch(deleteProduct(id));
    },
    deleteProductAvailability: (productId: string, id: string) => {
      dispatch(deleteProductAvailability(productId, id));
    },
    deleteProductImage: (productId: string, id: string) => {
      dispatch(deleteProductImage(productId, id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileMyProductsEdit);
