import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, Grid } from "@material-ui/core";
import PrimaryButton from "./PrimaryButton";
import GalleryVideo from "./GalleryVideo";
import GalleryPhoto from "./GalleryPhoto";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        boxLine: {
            border: '1px solid #ebdcc6',
            borderRadius: '4px',
            boxShadow: 'none',
            padding: 10,
            backgroundColor: '#ffffff'
        },
        boxTitle: {
            paddingBottom: 10,
            paddingTop: 10,
            fontWeight: 'bold',
            fontSize: '18px'
        },
        largeTitle: {
            paddingBottom: 15,
            fontWeight: 'bold',
            fontSize: '25px'
        },
        text: {
            fontSize: '14px',
            lineHeight: '25px'
        }
    }),
);


const photo = {
    "data": [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },]
}

const video = {
    "data": [
        { "id": "B1iJC2OC_3s" },
        { "id": "4NE9xIa08KI" },
    ]
}

const Gallery: React.FC = ({
}) => {
    useEffect(() => {
    }, []);

    const [selected, setSelected] = useState("photo");

    const onClick = (selected: string) => (
        setSelected(selected)
    )

    return (
        <Box pt={2} pb={1} pl={6} pr={6}>
            <Grid container justify="center" alignContent="center">
                <Grid item xs={6} sm={2}>
                    <Box textAlign="center">
                        <PrimaryButton labelText="Foto" code="photo" clickHandler={onClick} buttonEnabled={selected === "photo" ? true : false} />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={2} >
                    <Box textAlign="center">
                        <PrimaryButton labelText="Video" code="video" clickHandler={onClick} buttonEnabled={selected === "video" ? true : false} />
                    </Box>
                </Grid>
            </Grid>
            <GalleryVideo data={video.data} show={selected === "video" ? 'block' : 'none'} />
            <GalleryPhoto data={photo.data} show={selected === "photo" ? 'block' : 'none'} />

        </Box>
    );
};

export default (Gallery);
