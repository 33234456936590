import React from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '400px',
            display: 'inline-grid',
            alignSelf: 'center'
        },
        label: {
            fontSize: '20px',
            color: '#D3D3D3',
        },
        labelSelected: {
            fontSize: '16px',
            color: '#D3D3D3',
            textDecoration: 'underline',
            textDecorationColor: '#DA2232',
            textDecorationThickness: '2px',
            textUnderlineOffset: '8px',
        },
        link: {
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#000000',
            paddingBottom: '16px',
            textDecoration: 'underline'
        },
        checkbox: {
            color: '#000000'
        },
        button: {
            width: '260px',
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: '0px',
            border: '1px solid #E3E3E3',
            fontWeight: 'bold',
            textAlign: 'left',
            justifyContent: 'flex-start',
            paddingLeft: '15 px',
            "& .MuiButton-label": {
                width: '250px',
            },
            "& .MuiButton-endIcon": {
                width: '10px',
                justifyContext: 'flex-end',

            },
            '&:hover': {
                backgroundColor: '#ffffff',
                color: '#333333',
            },
        },
        title: {
            marginBottom: 2,
            height: '24px',
            fontSize: '16px',
            weight: 800,
            fontWeight: 'bold',
            color: '#333333',
        },
        titleBig: {
            marginBottom: 2,
            height: '31px',
            fontSize: '22px',
            fontWeight: 600,
            lineHeight: '31px',
            color: '#333333',
            textAlign: 'left'
        },
        all: {
            width: '100%'
        }
    })
);

interface IProps {
    formState: any,
    formBack: (value: any) => any
}

const CardBillingPayment: React.FC<IProps> = ({
    formState,
}) => {

    const classes = useStyles();

    return (
        <>
            <Grid item md={12} >
                <Typography className={classes.titleBig}>
                    <FormattedMessage id="payment.information" />
                </Typography>
            </Grid>
            <Grid item md={12} >
                <Typography className={classes.title}>
                    <FormattedMessage id="payment.method" />
                </Typography>
            </Grid>
            <Grid item md={12} >
                <Button className={classes.button} endIcon={<img src="/images/bank-send.svg" height="14px" alt="" />}><div className={classes.all}>SWEDBANK</div></Button>
            </Grid>
            <Grid item md={12} >
                <Button className={classes.button} endIcon={<img src="/images/bank-send.svg" height="14px" alt="" />}><div className={classes.all}>SEB</div></Button>
            </Grid>
            <Grid item md={12} >
                <Button className={classes.button} endIcon={<img src="/images/bank-send.svg" height="14px" alt="" />}><div className={classes.all}>Luminor</div></Button>
            </Grid>
            <Grid item md={12} >
                <Button className={classes.button} endIcon={<img src="/images/bank-send.svg" height="14px" alt="" />}><div className={classes.all}>Citadele</div></Button>
            </Grid>
            <Grid item md={12} >
                <Button className={classes.button} endIcon={<img src="/images/bank-send.svg" height="14px" alt="" />}><div className={classes.all}>
                    <FormattedMessage id="payment.card" />
                </div></Button>
            </Grid>
        </>

    )
};

export default (CardBillingPayment);
