import { MenuActionTypes } from "./types";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../index";

export type AppThunk = ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
>;

export const saveTreeState: AppThunk = (treeState: string[]) => {
  return (dispatch: Dispatch): Action => {
    try {
      return dispatch({
        type: MenuActionTypes.APPEND_STATE,
        payload: treeState
      });
    } catch (e) {
      return dispatch({
        type: MenuActionTypes.FETCH_ERROR
      });
    }
  };
};

export const getCountries = ()  => (dispatch: any) => {
  try { 
    const token: string = localStorage.getItem('token') || '';
    fetch(process.env.REACT_APP_API_HOST+'/users/countries', {
      headers: {
        'Authorization': token
      }
    })
      .then(result => result.json())
      .then(result => {
        if (result.error) {
          throw (result.error);
        }
        return dispatch({
          type: MenuActionTypes.COUNTRIES_SUCCESS,
          payload: result
        });
      })
      .catch(error => {
        return dispatch({
          type: MenuActionTypes.COUNTRIES_ERROR,
          errors: error
        });
      })
      return dispatch({
        type: MenuActionTypes.COUNTRIES_ERROR
      })
  } catch (e) {
    return dispatch({
      type: MenuActionTypes.COUNTRIES_ERROR
    });
  }
}

export const getCounties = ()  => (dispatch: any) => {
  try { 
    const token: string = localStorage.getItem('token') || '';
    fetch(process.env.REACT_APP_API_HOST+'/users/counties', {
      headers: {
        'Authorization': token
      }
    })
      .then(result => result.json())
      .then(result => {
        if (result.error) {
          throw (result.error);
        }
        return dispatch({
          type: MenuActionTypes.COUNTIES_SUCCESS,
          payload: result
        });
      })
      .catch(error => {
        return dispatch({
          type: MenuActionTypes.COUNTIES_ERROR,
          errors: error
        });
      })
      return dispatch({
        type: MenuActionTypes.COUNTIES_ERROR
      })
  } catch (e) {
    return dispatch({
      type: MenuActionTypes.COUNTIES_ERROR
    });
  }
}


export const getRoles = ()  => (dispatch: any) => {
  try { 
    const token: string = localStorage.getItem('token') || '';
    fetch(process.env.REACT_APP_API_HOST+'/users/roles', {
      headers: {
        'Authorization': token
      }
    })
      .then(result => result.json())
      .then(result => {
        if (result.error) {
          throw (result.error);
        }
        return dispatch({
          type: MenuActionTypes.ROLES_SUCCESS,
          payload: result
        });
      })
      .catch(error => {
        return dispatch({
          type: MenuActionTypes.ROLES_ERROR,
          errors: error
        });
      })
      return dispatch({
        type: MenuActionTypes.ROLES_ERROR
      })
  } catch (e) {
    return dispatch({
      type: MenuActionTypes.ROLES_ERROR
    });
  }
}

export const appendTreeSelection = (type: string, id: string)  => (dispatch: any) => {
    try { 
      const token: string = localStorage.getItem('token') || '';
      fetch(process.env.REACT_APP_API_HOST+'/products/tree/'+type+'/'+id, {
        headers: {
          'Authorization': token
        }
      })
        .then(result => result.json())
        .then(result => {
          if (result.error) {
            throw (result.error);
          }
          dispatch({
            type: MenuActionTypes.APPEND_STATE,
            payload: result
          });
          return dispatch({
            type: MenuActionTypes.BREADCRUMB_SUCCESS,
            payload: result
          });
        })
        .catch(error => {
          return dispatch({
            type: MenuActionTypes.BREADCRUMB_ERROR,
            errors: error
          });
        })
        return dispatch({
          type: MenuActionTypes.BREADCRUMB_ERROR
        })
    } catch (e) {
      return dispatch({
        type: MenuActionTypes.BREADCRUMB_ERROR
      });
    }
}

export const fetchMenu: AppThunk = () => {
  return (dispatch: Dispatch): Action => {
    try {
      const token: string = localStorage.getItem('token') || '';
      fetch(process.env.REACT_APP_API_HOST+'/products/text', {
        headers: {
          'Authorization': token
        }
      })
        .then(text => text.json())
        .then(text => {
          if (text.error) {
            throw (text.error);
          }
          fetch(process.env.REACT_APP_API_HOST+'/products/treeInit', {
            headers: {
              'Authorization': token
            }
          })
            .then(menu => menu.json())
            .then(menu => {
                  return dispatch({
                    type: MenuActionTypes.FETCH_SUCCESS,
                    menu: menu,
                    text: text
                  });
                });
                fetch(process.env.REACT_APP_API_HOST+'/products/tree', {
                  headers: {
                    'Authorization': token
                  }
                })
                  .then(menu => menu.json())
                  .then(menu => {
                        return dispatch({
                          type: MenuActionTypes.FETCH_SUCCESS,
                          menu: menu,
                          text: text
                        });
                      });
        })
        .catch(error => {
          return dispatch({
            type: MenuActionTypes.FETCH_ERROR,
            errors: error
          });
        })
        return dispatch({
          type: MenuActionTypes.FETCH_LOADING
        })
    } catch (e) {
      return dispatch({
        type: MenuActionTypes.FETCH_ERROR
      });
    }
  };
};

export const fetchText: AppThunk = () => {
  return (dispatch: Dispatch): Action => {
    try {
      const token: string = localStorage.getItem('token') || '';
      fetch(process.env.REACT_APP_API_HOST+'/menu/tree', {
        headers: {
          'Authorization': token
        }
      })
        .then(result => result.json())
        .then(result => {
          if (result.error) {
            throw (result.error);
          }
          return dispatch({
            type: MenuActionTypes.FETCH_SUCCESS,
            payload: result
          });
        })
        .catch(error => {
          return dispatch({
            type: MenuActionTypes.FETCH_ERROR,
            errors: error
          });
        })
        return dispatch({
          type: MenuActionTypes.FETCH_ERROR
        })
    } catch (e) {
      return dispatch({
        type: MenuActionTypes.FETCH_ERROR
      });
    }
  };
};
