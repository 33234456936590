import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: "relative",
        borderWidth: 0,
        borderRadius: 0,
        padding: 0,
        boxShadow: "0px",
        border: "0px",
        "& $notchedOutline": {
          borderWidth: 0,
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderWidth: 0,
          },
        },
        "&$focused $notchedOutline": {
          borderWidth: 0,
        },
        "&&& $input": {
          padding: "1px",
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#4A90E2",
        },
      },
    },
    MuiRadio: {
      root: {
        color: "#DA2232",
      },
      colorSecondary: {
        "&$checked": {
          color: "#DA2232",
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: "#DA2232",
      },
      colorSecondary: {
        "&$checked": {
          color: "#DA2232",
        },
      },
    },
    MuiToolbar: {
      regular: {
        height: "38px",
        minHeight: "38px",
        maxWidth: "1200px",
        alignSelf: "center",
        width: "100%",
        "@media(min-width:600px)": {
          minHeight: "38px",
          paddingLeft: "0px",
          paddingRight: "0px",
        },
      },
      gutters: {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    MuiTab: {
      textColorInherit: {
        opacity: 1,
      },
    },
  },
});
