import React, { ChangeEvent, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, MenuItem, Select } from "@material-ui/core";
import { GoogleMap, StandaloneSearchBox, Marker } from "@react-google-maps/api";
import { Employee, Projects } from "../models/types";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { deleteProject, getEmployees, updateProject } from "../store/users/action";
import { FormattedMessage, useIntl } from "react-intl";
import InputTextField from "./TextField";
import { ApplicationState } from "../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxTitle: {
      paddingBottom: 10,
      paddingTop: 10,
      fontWeight: "bold",
      fontSize: "18px",
    },
    buttonBox: {
      paddingTop: "20px",
      textAlign: "right",
    },
    cancelBox: {
      paddingTop: "20px",
      textAlign: "left",
    },
    checkbox: {
      color: "#000000",
    },
    button: {
      width: "120px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    container: {
      alignSelf: "center",
      width: "100%",
      maxWidth: "1000px",
      margin: "0 auto",
    },
    back: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    backIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    center: {
      lat: 0,
      lng: -180,
    },
    buttonEnd: {
      textAlign: "end",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    buttonStart: {
      textAlign: "start",
      alignSelf: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    buttonDelete: {
      width: "120px",
      backgroundColor: "#333333",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#000000",
        color: "#ffffff",
      },
    },
    text: {
      color: "#333333",
    },
    selectField: {
      width: "100%",
      height: "38px",
      fontSize: "16px",
      paddingLeft: "8px",
      backgroundColor: "#ffffff",
      border: "1px solid #e3e3e3",
      margin: "0px",
      "&$focused $notchedOutline": {
        border: "1px solid #e3e3e3",
      },
      "& label.Mui-focused": {
        color: "#333333",
        backgroundColor: "#ffffff",
      },
      "& label": {
        color: "#333333",
        backgroundColor: "#ffffff",
      },
    },
  })
);

interface IProps {
  project?: any;
  type?: string;
  newProject: (status: any) => any;
}

interface propsFromDispatch {
  updateProject: (payload: any) => any;
  deleteProject: (id: number) => any;
  getEmployees: () => any;
}

interface PropsFromState {
  employees: Employee[];
  employeesLoading: boolean;
}

type AllProps = IProps & propsFromDispatch & PropsFromState;

const ProfileProjectsEdit: React.FC<AllProps> = ({
  project,
  type,
  employees,
  employeesLoading,
  newProject,
  updateProject,
  deleteProject,
  getEmployees
}) => {
  
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !employeesLoading && employees.length === 0 ? getEmployees() : null;
  }, [project]);

  const [formState, setFormState] = useState<Projects>(project || {});
  const [name, setName] = useState<string>(project?.name || "");

  const validateForm = () => {
    return true;
  };

  const saveProject = () => {
    if (validateForm()) {
      let project = {
        project_id: formState.project_id,
        employee_id: formState.employee_id,
        name: name,
        country: formState.country,
        address: formState.address,
        lgn: formState.lgn,
        lat: formState.lat,
      };
      updateProject(project);

      if (type === "new") newProject(false);
    }
  };

  const classes = useStyles();

  const [currentLocation, setCurrentLocation] = React.useState({
    lat: 56.9496,
    lng: 24.1052,
  });

  const [markers, setMarkers] = React.useState({
    lat: 0,
    lng: 0,
  });

  const intl = useIntl();

  const onMapLoad = (map: any) => {
    const geocoder = new google.maps.Geocoder();
    if (formState.lat && formState.lgn) {
      setMarkers({
        lat: Number(formState.lat),
        lng: Number(formState.lgn),
      });
      setCurrentLocation({
        lat: Number(formState.lat),
        lng: Number(formState.lgn),
      });
    } else {
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          setCurrentLocation({
            lat: lat,
            lng: lng,
          });
        }
      );
    }
    google.maps.event.addDomListener(map, "click", (event: any) => {
      geocoder.geocode(
        { location: { lat: event.latLng.lat(), lng: event.latLng.lng() } },
        (response: any) => {
          if (response) {
            setFormState({
              ...formState,
              address: response[0]?.formatted_address,
              lat: event?.latLng?.lat().toString(),
              lgn: event?.latLng?.lng().toString(),
            });
          }
        }
      );
      setMarkers({
        lat: event?.latLng?.lat(),
        lng: event?.latLng?.lng(),
      });
      setCurrentLocation({
        lat: event?.latLng?.lat(),
        lng: event?.latLng?.lng(),
      });
    });
  };

  const onSBLoad = (address: any) => {
    google.maps.event.addDomListener(address, "change", () => {
      address.setComponentRestrictions({
        country: ["lv", "lt", "ee"],
        zoom: "14",
      });
    });
    google.maps.event.addListener(address, "places_changed", () => {
      let place = address.getPlaces();
      let place1 = place[0];
      setFormState({
        ...formState,
        address: place1?.formatted_address,
        country: place1?.address_components[4].long_name,
        lat: place1?.geometry?.location.lat(),
        lgn: place1?.geometry?.location.lng(),
      });
      setCurrentLocation({
        lat: place1?.geometry?.location.lat(),
        lng: place1?.geometry?.location.lng(),
      });
      setMarkers({
        lat: place1?.geometry?.location.lat(),
        lng: place1?.geometry?.location.lng(),
      });
    });
  };

  const setLocalAddress = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFormState({
      ...formState,
      address: value,
    });
  };

  const handleSelectChange = (e: any) => {
    const { value } = e.target;
    setFormState({
      ...formState,
      employee_id: value,
    });
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setName(value);
  };

  return (
    <Box justifyContent="center">
      <Grid container spacing={2} direction="row" justify="center">
        <Grid item md={12} className={classes.text}>
          <b>
            <FormattedMessage id="mapAddress" />
          </b>
        </Grid>
        <Grid item xs={12} md={12}>
          <InputTextField
            name="name"
            title={intl.formatMessage({ id: "profile.objectName" })}
            value={name || ""}
            formChange={handleNameChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {!employeesLoading && employees.length > 0 ? (
            <Select
              value={formState.employee_id || ''}
              onChange={handleSelectChange}
              className={classes.selectField}
              name="employee_id"
              id="employee_id"
              placeholder={intl.formatMessage({ id: "repairForm.contacts" })}
              variant="outlined"
            >
              {employees?.map((employee) => (
                <MenuItem value={employee.id} id={String(employee.id)} key={employee.id}>
                  {employee?.name + " " + employee?.surname}
                </MenuItem>
              ))}
            </Select> 
            ) : ( 
              null 
            )}
        </Grid>
        <Grid item xs={12} md={12}>
          <StandaloneSearchBox onLoad={onSBLoad}>
            <input
              type="text"
              placeholder="Piegades adrese"
              value={formState.address || ""}
              onChange={setLocalAddress}
              style={{
                boxSizing: `border-box`,
                border: `1px solid #E3E3E3`,
                width: `100%`,
                height: `36px`,
                padding: `10px 12px`,
                borderRadius: `0px`,
                maxWidth: `100%`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
              }}
            />
          </StandaloneSearchBox>
        </Grid>
        <Grid item xs={12} md={12}>
          <GoogleMap
            id="search-box-example"
            mapContainerStyle={{
              minHeight: "400px",
              maxHeight: "600px",
              minWidth: "290px",
              maxWidth: "100%",
            }}
            zoom={12}
            center={currentLocation}
            onLoad={(map) => onMapLoad(map)}
          >
            <Marker
              key="place"
              icon={{ url: "/images/marker.svg" }}
              position={markers}
            />
          </GoogleMap>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={6} md={6} className={classes.buttonStart}>
              <span
                onClick={() => newProject(false)}
                className={classes.backIcon}
              >
                {" "}
                &lt;{" "}
              </span>
              <span onClick={() => newProject(false)} className={classes.back}>
                <FormattedMessage id="back" />
              </span>
            </Grid>
            <Grid item xs={6} md={6} className={classes.buttonEnd}>
              {type !== "new" ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => deleteProject(Number(formState?.project_id))}
                    size="large"
                    className={classes.buttonDelete}
                  >
                    <FormattedMessage id="delete" />
                  </Button>
                  &nbsp;
                </>
              ) : null}
              <Button
                variant="contained"
                onClick={() => saveProject()}
                size="large"
                className={classes.button}
              >
                <FormattedMessage id="save" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ user, language }: ApplicationState) => ({
  employees: user.profile.employees,
  employeesLoading: user.employeeLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    updateProject: (payload: any) => {
      dispatch(updateProject(payload));
    },
    deleteProject: (id: number) => {
      dispatch(deleteProject(id));
    },
    getEmployees: () => {
      dispatch(getEmployees());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileProjectsEdit);
