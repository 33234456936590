import React from "react";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Image } from "../models/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      transform: "translateZ(0)",
      width: "100%",
      padding: "3px",
    },
    gridListTile: {
      height: "100px",
    },
    gridListTileSelected: {
      height: "90px",
      border: "1px solid #DA2232",
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
    imageGrey: {
      filter: "grayscale(80%)",
      maxHeight: "99%",
      maxWidth: "100%",
      width: "auto",
      height: "auto",
      verticalAlign: "center",
    },
    image: {
      maxHeight: "100%",
      maxWidth: "100%",
      width: "auto",
      height: "auto",
      verticalAlign: "center",
    },
  })
);

interface IProps {
  images: Image[];
  currentSelection: number;
  clickHandler: (index: number) => void;
}

type AllProps = IProps;

const ProductPhotoSliderHorizontal: React.FC<AllProps> = ({
  images,
  currentSelection,
  clickHandler,
}) => {
  const classes = useStyles();

  return (
    <>
      {images ? (
        <div className={classes.root}>
          <GridList cols={3} className={classes.gridList} cellHeight={120}>
            {images.map((item, index) => (
              <GridListTile
                classes={{
                  tile: "gridListTile",
                }}
                key={index}
                className={
                  index === currentSelection
                    ? classes.gridListTileSelected
                    : classes.gridListTile
                }
              >
                <img
                  src={
                    process.env.REACT_APP_IMAGE_HOST +
                      "/files/product_images/" +
                      item.img || ""
                  }
                  alt={item.description}
                  className={
                    index !== currentSelection
                      ? classes.imageGrey
                      : classes.image
                  }
                  onClick={() => clickHandler(index || 0)}
                />
              </GridListTile>
            ))}
          </GridList>
        </div>
      ) : null}
    </>
  );
};

export default ProductPhotoSliderHorizontal;
