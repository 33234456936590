import { Reducer } from "redux";
import { UserActionTypes, UserState } from "./types";

export const initialState: UserState = {
  user: {
    id: 0,
    token: "",
    profile: {
      name: "Unknown",
    },
  },
  errors: "",
  login: false,
  loading: false,
  registerLoading: false,
  profileLoading: false,
  cartLoading: false,
  projectsLoading: false,
  invoicesLoading: false,
  serviceLoading: false,
  transportLoading: false,
  employeeLoading: false,
  contractsLoading: false,
  ordersLoading: false,
  orderStatusListLoading: false,
  employeeListLoading: false,
  gvpLoading: false,
  productsLoading: false,
  inboxLoading: false,
  inboxDetailsLoading: false,
  profile: {
    employees: [],
    projects: [],
    invoices: [],
    inbox: [],
    inboxDetails: [],
    contracts: [],
    service: [],
    transport: [],
    orders: [],
    products: [],
    gvp: {},
  },
  orderDetails: {
    count: 0,
  },
  orderDetailsLoading: false,
  cart: {
    count: 0,
    delivery: {},
  },
  orderStatusList: {},
  employeeList: [],
};

const reducer: Reducer<UserState> = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.LOGIN_REQUEST: {
      return { ...state, loading: true };
    }
    case UserActionTypes.LOGIN_ERROR: {
      return { ...state, login: false, errors: action.payload };
    }
    case UserActionTypes.LOGIN_SUCCESS: {
      return { ...state, login: true, errors: "" };
    }
    case UserActionTypes.SMART_REQUEST: {
      return { ...state, smartId: action.payload };
    }
    case UserActionTypes.SMART_ERROR: {
      return { ...state, smartId: action.payload, errors: action.payload };
    }
    case UserActionTypes.SMART_SUCCESS: {
      return { ...state, smartId: action.payload };
    }
    case UserActionTypes.STATUS_REQUEST: {
      return { ...state, loading: true };
    }
    case UserActionTypes.STATUS_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case UserActionTypes.STATUS_SUCCESS: {
      return { ...state, loading: false, user: action.payload };
    }
    case UserActionTypes.PROFILE_REQUEST: {
      return { ...state, profileLoading: true };
    }
    case UserActionTypes.PROFILE_ERROR: {
      return { ...state, profileLoading: false, errors: action.payload };
    }
    case UserActionTypes.PROFILE_SUCCESS: {
      return {
        ...state,
        profileLoading: false,
        profile: {
          ...state.profile,
          deliveryAddress: action.payload?.deliveryAddress,
          contractCode: action.payload?.contractCode,
          contracts: action.payload?.contracts,
          creditLimit: action.payload?.creditLimit,
          creditLimitAvailable: action.payload?.creditLimitAvailable,
          officeAddress: action.payload?.officeAddress,
          registrationAddress: action.payload?.registrationAddress,
          registrationNumber: action.payload?.registrationNumber,
          payDue: action.payload?.payDue,
          type: action.payload?.type,
          vatNumber: action.payload?.vatNumber,
          inboxNew: action.payload?.inboxNew,
          inboxNewCount: action.payload?.inboxNewCount,
          inbox: action.payload?.inbox,
        },
      };
    }
    case UserActionTypes.CART_REQUEST: {
      return { ...state, cartLoading: true };
    }
    case UserActionTypes.CART_ERROR: {
      return { ...state, cartLoading: false, errors: action.payload };
    }
    case UserActionTypes.CART_SUCCESS: {
      return { ...state, cartLoading: false, cart: action.payload };
    }
    case UserActionTypes.DELIVERY_REQUEST: {
      return { ...state, deliveryLoading: true };
    }
    case UserActionTypes.DELIVERY_ERROR: {
      return { ...state, deliveryLoading: false, errors: action.payload };
    }
    case UserActionTypes.DELIVERY_SUCCESS: {
      return {
        ...state,
        deliveryLoading: false,
        cart: { ...state.cart, delivery: action.payload },
      };
    }
    case UserActionTypes.REGISTER_REQUEST: {
      return { ...state, registerLoading: true };
    }
    case UserActionTypes.REGISTER_ERROR: {
      return { ...state, registerLoading: false, errors: action.error };
    }
    case UserActionTypes.REGISTER_SUCCESS: {
      return { ...state, registerLoading: false, errors: "" };
    }
    case UserActionTypes.SERVICE_REQUEST: {
      return { ...state, serviceLoading: true };
    }
    case UserActionTypes.SERVICE_ERROR: {
      return { ...state, serviceLoading: false, errors: action.payload };
    }
    case UserActionTypes.SERVICE_SUCCESS: {
      return {
        ...state,
        serviceLoading: false,
        profile: { ...state.profile, service: action.payload },
      };
    }
    case UserActionTypes.TRANSPORT_REQUEST: {
      return { ...state, transportLoading: true };
    }
    case UserActionTypes.TRANSPORT_ERROR: {
      return { ...state, transportLoading: false, errors: action.payload };
    }
    case UserActionTypes.TRANSPORT_SUCCESS: {
      return {
        ...state,
        transportLoading: false,
        profile: { ...state.profile, transport: action.payload },
      };
    }
    case UserActionTypes.INVOICES_REQUEST: {
      return { ...state, invoicesLoading: true };
    }
    case UserActionTypes.INVOICES_ERROR: {
      return { ...state, invoicesLoading: false, errors: action.payload };
    }
    case UserActionTypes.INVOICES_SUCCESS: {
      return {
        ...state,
        invoicesLoading: false,
        profile: { ...state.profile, invoices: action.payload },
      };
    }
    case UserActionTypes.PRODUCTS_REQUEST: {
      return { ...state, productsLoading: true };
    }
    case UserActionTypes.PRODUCTS_ERROR: {
      return { ...state, productsLoading: false, errors: action.payload };
    }
    case UserActionTypes.PRODUCTS_SUCCESS: {
      return {
        ...state,
        productsLoading: false,
        profile: { ...state.profile, products: action.payload },
      };
    }
    case UserActionTypes.PROJECTS_REQUEST: {
      return { ...state, projectsLoading: true };
    }
    case UserActionTypes.PROJECTS_ERROR: {
      return { ...state, projectsLoading: false, errors: action.payload };
    }
    case UserActionTypes.PROJECTS_SUCCESS: {
      return {
        ...state,
        projectsLoading: false,
        profile: { ...state.profile, projects: action.payload },
      };
    }
    case UserActionTypes.CONTRACTS_REQUEST: {
      return { ...state, projectsLoading: true };
    }
    case UserActionTypes.CONTRACTS_ERROR: {
      return { ...state, contractsLoading: false, errors: action.payload };
    }
    case UserActionTypes.CONTRACTS_SUCCESS: {
      return {
        ...state,
        contractsLoading: false,
        profile: { ...state.profile, contracts: action.payload },
      };
    }
    case UserActionTypes.INBOX_REQUEST: {
      return { ...state, inboxLoading: true };
    }
    case UserActionTypes.INBOX_ERROR: {
      return { ...state, inboxLoading: false, errors: action.payload };
    }
    case UserActionTypes.INBOX_SUCCESS: {
      return {
        ...state,
        inboxLoading: false,
        profile: { ...state.profile, inbox: action.payload.inbox, inboxNew: action.payload.inboxNew, inboxNewCount: action.payload.inboxNewCount },
      };
    }
    case UserActionTypes.INBOX_DETAILS_REQUEST: {
      return { ...state, inboxDetailsLoading: true };
    }
    case UserActionTypes.INBOX_DETAILS_ERROR: {
      return { ...state, inboxDetailsLoading: false, errors: action.payload };
    }
    case UserActionTypes.INBOX_DETAILS_SUCCESS: {
      console.log(action.payload)
      return {
        ...state,
        inboxDetailsLoading: false,
        profile: { ...state.profile, inboxDetails: action.payload },
      };
    }
    case UserActionTypes.ORDERS_REQUEST: {
      return { ...state, ordersLoading: true };
    }
    case UserActionTypes.ORDERS_ERROR: {
      return { ...state, ordersLoading: false, errors: action.payload };
    }
    case UserActionTypes.ORDERS_SUCCESS: {
      return {
        ...state,
        ordersLoading: false,
        profile: { ...state.profile, orders: action.payload },
      };
    }
    case UserActionTypes.EMPLOYEES_REQUEST: {
      return { ...state, employeesLoading: true };
    }
    case UserActionTypes.EMPLOYEES_ERROR: {
      return { ...state, employeesLoading: false, errors: action.payload };
    }
    case UserActionTypes.EMPLOYEES_SUCCESS: {
      return {
        ...state,
        employeesLoading: false,
        profile: { ...state.profile, employees: action.payload },
      };
    }
    case UserActionTypes.GVP_REQUEST: {
      return { ...state, gvpLoading: true };
    }
    case UserActionTypes.GVP_ERROR: {
      return { ...state, gvpLoading: false, errors: action.payload };
    }
    case UserActionTypes.GVP_SUCCESS: {
      return {
        ...state,
        gvpLoading: false,
        profile: { ...state.profile, gvp: action.payload },
      };
    }
    case UserActionTypes.GVP_STATUS_SUCCESS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          gvp: {
            ...state.profile.gvp,
            status: action.payload.status,
            fileName: action.payload.fileName,
          },
        },
      };
    }
    case UserActionTypes.ORDER_DETAILS_REQUEST: {
      return { ...state, orderDetailsLoading: true };
    }
    case UserActionTypes.ORDER_DETAILS_ERROR: {
      return { ...state, orderDetailsLoading: false, errors: action.payload };
    }
    case UserActionTypes.ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        orderDetailsLoading: false,
        orderDetails: action.payload,
      };
    }
    case UserActionTypes.PRODUCT_DETAILS_REQUEST: {
      return { ...state, productDetailsLoading: true };
    }
    case UserActionTypes.PRODUCT_DETAILS_ERROR: {
      return { ...state, productDetailsLoading: false, errors: action.payload };
    }
    case UserActionTypes.PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        productDetailsLoading: false,
        productDetails: action.payload,
      };
    }
    case UserActionTypes.LOGOUT_REQUEST: {
      return { ...state, loading: true };
    }
    case UserActionTypes.LOGOUT_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case UserActionTypes.LOGOUT_SUCCESS: {
      return { ...state, loading: false, login: false, user: {}, profile: {}, cart: { count: 0, delivery: {},} };
    }
    case UserActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case UserActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, user: action.payload };
    }
    case UserActionTypes.ORDER_STATUS_SUCCESS: {
      return { ...state, orderStatusList: action.payload };
    }
    case UserActionTypes.EMPLOYEE_LIST_SUCCESS: {
      return { ...state, employeeList: action.payload };
    }
    case UserActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as UserReducer };
