import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { ProductsData } from "../models/types";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ProductCard from "./ProductCard";
import { Box, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden'
    },
    gridList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
    },
    gridListTile: {
      height: 300,
    },
    sliderBox: {
      color: '#333333',
      fontSize: 18,
      fontWeight: 'bold'
    }
  }),
);

interface PropsFromState {
  title: string;
  language: string;
  dataLoading: boolean;
  data: ProductsData[];
}

type AllProps = PropsFromState;

const ProductSlider: React.FC<AllProps> = ({
  title,
  language,
  dataLoading,
  data
}) => {

  const classes = useStyles();

  return (
    <>
      {!dataLoading && data.length > 0 ? (
        <Box pt={5} pb={1} className={classes.sliderBox}>
          {title ? (
            <Box pb={2}>{title}</Box>
          ) : (null)}

          <Grid
            container spacing={2}
            direction="row" justify="center" alignContent="center"
          >
            {data.map((item, index) => (
              index < 6 ? (
                <Grid item xs={6} md={2} key={item.code}>
                  <ProductCard product={item} language={language} />
                </Grid>
              ) : (null)
            ))}
          </Grid>
        </Box>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ language }: ApplicationState) => ({
  language: language.language
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSlider);
