import React, { ChangeEvent, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectData, SmartId } from "../models/types";
import InputTextField from "./TextField";
import Checkbox from "@material-ui/core/Checkbox";
import InputSelectField from "./SelectField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxTitle: {
      paddingBottom: 10,
      paddingTop: 10,
      fontWeight: "bold",
      fontSize: "18px",
    },
    buttonBox: {
      paddingTop: "20px",
      textAlign: "right",
    },
    cancelBox: {
      paddingTop: "20px",
      textAlign: "left",
    },
    checkbox: {
      color: "#000000",
    },
    button: {
      width: "250px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    container: {
      alignSelf: "center",
      width: "100%",
      maxWidth: "1000px",
      margin: "0 auto",
    },
    back: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    backIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    labelError: {
      color: "red",
      alignSelf: "center",
      fontSize: "14px",
      textAlign: "center",
    },
  })
);

interface IProps {
  show: string;
  countries: SelectData[];
  counties: SelectData[];
  formState: any;
  smartId?: SmartId;
  skipSmartId: boolean;
  registerEnabled: boolean;
  formErrors: any;
  formChange: (e: ChangeEvent<HTMLInputElement>) => any;
  selectChange: (e: any) => any;
  back: (step: string) => any;
  formSubmit: () => any;
}

const RegisterPrivate: React.FC<IProps> = ({
  countries,
  counties,
  formState,
  formErrors,
  registerEnabled,
  formChange,
  selectChange,
  back,
  formSubmit,
}) => {
  useEffect(() => {}, []);

  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box m={3} justifyContent="center">
      <Box className={classes.container}>
        <Grid container spacing={3} direction="row">
          {formErrors?.duplicate ? (
            <Grid item xs={12} md={12}>
              <Box textAlign="center">
                <span className={classes.labelError}>
                  {intl.formatMessage({ id: "register.duplicateUser" })}
                </span>
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} md={4}>
            <InputTextField
              name="companyName"
              title={intl.formatMessage({ id: "register.companyName" })}
              value={formState.companyName}
              error={formErrors.companyName}
              enabled={registerEnabled && true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="companyRegistrationNumber"
              title={intl.formatMessage({
                id: "register.companyRegistrationNumber",
              })}
              value={formState.companyRegistrationNumber}
              error={formErrors.companyRegistrationNumber}
              enabled={registerEnabled && true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="companyVAT"
              title={intl.formatMessage({ id: "register.companyVAT" })}
              value={formState.companyVAT}
              error={formErrors.companyVAT}
              enabled={registerEnabled && true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormattedMessage id="register.responsiblePerson" />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="code"
              title={intl.formatMessage({ id: "register.code" })}
              value={formState.ssn}
              error={formErrors.code}
              enabled={false}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="ssn"
              title={intl.formatMessage({ id: "register.ssn" })}
              value={formState.ssn || ""}
              error={formErrors.ssn}
              enabled={registerEnabled && !formState.skipSmartId ? false : true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="name"
              title={intl.formatMessage({ id: "register.name" })}
              value={formState.name || ""}
              error={formErrors.name}
              enabled={registerEnabled && !formState.skipSmartId ? false : true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="surname"
              title={intl.formatMessage({ id: "register.surname" })}
              value={formState.surname || ""}
              enabled={registerEnabled && !formState.skipSmartId ? false : true}
              error={formErrors.surname}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormattedMessage id="register.companyContacts" />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="email"
              title={intl.formatMessage({ id: "register.email" })}
              value={formState.email || ""}
              error={formErrors.email}
              enabled={registerEnabled && true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="phone"
              title={intl.formatMessage({ id: "register.phone" })}
              value={formState.phone || ""}
              error={formErrors.phone}
              enabled={registerEnabled && true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="address"
              title={intl.formatMessage({ id: "register.address" })}
              value={formState.address || ""}
              error={formErrors.address}
              enabled={registerEnabled && true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="city"
              title={intl.formatMessage({ id: "register.city" })}
              value={formState.city || ""}
              error={formErrors.city}
              enabled={registerEnabled && true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="postalCode"
              title={intl.formatMessage({ id: "register.postalCode" })}
              value={formState.postalCode || ""}
              error={formErrors.postalCode}
              enabled={registerEnabled && true}
              formChange={formChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputSelectField
              name="county"
              title={intl.formatMessage({ id: "register.county" })}
              list={counties}
              value={formState.county || ""}
              error={formErrors.county}
              enabled={registerEnabled && true}
              formChange={selectChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputSelectField
              name="country"
              title={intl.formatMessage({ id: "register.country" })}
              list={countries}
              value={formState.country || ""}
              error={formErrors.country}
              enabled={registerEnabled && true}
              formChange={selectChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Checkbox
              checked={formState.agreeMarketing}
              onChange={formChange}
              name="agreeMarketing"
              id="agreeMarketing"
              className={classes.checkbox}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <FormattedMessage id="register.marketing" />
          </Grid>
          <Grid item xs={12} md={12}>
            <b>
              <FormattedMessage id="register.channel" />
            </b>
          </Grid>
          <Grid item xs={12} md={12}>
            <RadioGroup
              row
              name="communicationChannel"
              onChange={formChange}
              value={formState.communicationChannel}
            >
              <FormControlLabel
                value="sms"
                id="sms"
                control={<Radio />}
                label="SMS"
              />
              <FormControlLabel
                value="email"
                id="email"
                control={<Radio />}
                label="E-mail"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box className={classes.cancelBox}>
              <span onClick={() => back("step1")} className={classes.backIcon}>
                {" "}
                &lt;{" "}
              </span>
              <span onClick={() => back("step1")} className={classes.back}>
                {" "}
                <FormattedMessage id="register.back" />
              </span>
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box className={classes.buttonBox}>
              <Button
                variant="contained"
                onClick={formSubmit}
                size="large"
                className={classes.button}
              >
                <FormattedMessage id="register.finish" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RegisterPrivate;
