import { Reducer } from "redux";
import { LanguageActionTypes, LanguageState } from "./types";

export const initialState: LanguageState = {
  language: "lv"
};

const reducer: Reducer<LanguageState> = (state = initialState, action) => {
  switch (action.type) {
    case LanguageActionTypes.CHANGE: {
      return { ...state, language: action.language };
    }
    default: {
      return state;
    }
  }
};

export { reducer as LanguageReducer };
