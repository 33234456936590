/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Projects } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import ProfileProjectsEdit from "./ProfileProjectsEdit";
import { getUserProjects } from "../store/users/action";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ApplicationState } from "../store";
import { LoadScript } from "@react-google-maps/api";
import { FormattedMessage, useIntl } from "react-intl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ProfileNoRows from "./ProfileNoRows";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    accordionDetails: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      paddingBottom: "20px",
    },
    accordionDetailsOpen: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      paddingBottom: "20px",
      borderBottom: "1px solid #E3E3E3",
    },
    heading: {
      flexBasis: "100%",
      flexShrink: 0,
      fontSize: "14px",
      fontWeight: "bold",
      color: "#333333",
    },
    titleBig: {
      marginBottom: 2,
      height: "auto",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      paddingTop: "10px",
      paddingBottom: "10px",
      color: "#333333",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    tableHeader: {
      borderBottom: "1px solid #E3E3E3",
      padding: "0px",
    },
    tableHeaderOpen: {
      padding: "0px",
    },
    tableTitle: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
    },
    tableText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "14px",
        padding: "2px",
      },
    },
    statusGreen: {
      background: "#D7EC84",
      fontSize: "12px",
      padding: "6px",
    },
    statusRed: {
      background: "#EC574E",
      fontSize: "12px",
      padding: "6px",
      color: "#ffffff",
    },
    statusGrey: {
      backgroundColor: "#E3E3E3 !important",
      fontSize: "12px",
      padding: "6px",
    },
    orderLink: {
      fontSize: "18px",
      lineHeight: "20px",
      textDecorationLine: "underline",
      color: "#333333",
      padding: "6px",
      textAlign: "right",
    },
    openLink: {
      textAlign: "right",
    },
    buttonEnd: {
      textAlign: "end",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    button: {
      width: "300px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
      paddingTop: "10px",
      paddingBottom: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "150px",
        fontSize: "12px",
      },
    },
    checkbox: {
      color: "#333333",
    },
    buttonStart: {
      textAlign: "start",
      alignSelf: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    back: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    backIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    title: {
      marginBottom: 2,
      height: "29px",
      fontSize: "14px",
      letterSpacing: "0.25px",
      color: "#333333",
      textAlign: "left",
    },
    hr: {
      border: "none",
      height: "1px",
      color: "#e3e3e3",
      backgroundColor: "#e3e3e3",
    },
  })
);

interface propsFromDispatch {
  getProjects: () => any;
}

interface PropsFromState {
  projects: Projects[];
  projectsLoading: boolean;
}

type AllProps = PropsFromState & propsFromDispatch;

const ProfileProjects: React.FC<AllProps> = ({
  projects,
  projectsLoading,
  getProjects,
}) => {
  useEffect(() => {
    getProjects();
  }, []);

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [newProject, setNewProject] = React.useState<boolean | false>(false);

  const classes = useStyles();
  const intl = useIntl();

  const handlePanelChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const closeProject = (value: boolean) => {
    setNewProject(value);
    setExpanded(false);
  };
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY || ""}
      libraries={["places"]}
    >
      {!newProject ? (
        <Grid container direction="row" justify="center">
          <Grid item xs={6} md={6} className={classes.titleBig}>
            <FormattedMessage id="profile.myObjects" />
          </Grid>
          <Grid item xs={6} md={6} className={classes.buttonEnd}>
            <Button
              variant="contained"
              size="large"
              className={classes.button}
              onClick={() => setNewProject(true)}
            >
              <FormattedMessage id="profile.newObject" />
            </Button>
          </Grid>
          {!projectsLoading && projects?.length > 0 ? (
            <>
              <Hidden smDown>
                <Grid container className={classes.tableHeader}>
                  <Grid item md={4}>
                    <Typography className={classes.tableTitle}>
                      Objekta nosaukums
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography className={classes.tableTitle}>
                      Objekta adrese
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography className={classes.tableTitle}>
                      Atbildīgā persona
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography className={classes.tableTitle}>
                      Atbildīgās personas tel. nr.
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
              <Grid md={12} className={classes.titleBig}>
                {!projectsLoading
                  ? projects?.map((project, index) => (
                      <Box pb={1} key={index}>
                        <Accordion
                          className={classes.accordion}
                          expanded={expanded === project.project_id}
                          onChange={handlePanelChange(project.project_id)}
                        >
                          <AccordionSummary
                            id={project.project_id}
                            className={
                              expanded === project.project_id
                                ? classes.tableHeaderOpen
                                : classes.tableHeader
                            }
                          >
                            <Grid container>
                              <Hidden smDown>
                                <Grid item md={4}>
                                  <Typography>{project?.name}</Typography>
                                </Grid>
                              </Hidden>
                              <Grid item xs={10} md={4}>
                                <Typography>{project?.address}</Typography>
                              </Grid>
                              <Hidden smDown>
                                <Grid item md={2}>
                                  <Typography>
                                    {project?.contactName ? project?.contactName : ""}
                                  </Typography>
                                </Grid>
                                <Grid item md={1}>
                                  <Typography>
                                    {project?.contactPhone}
                                  </Typography>
                                </Grid>
                              </Hidden>
                              <Grid
                                item
                                xs={2}
                                md={1}
                                className={classes.openLink}
                              >
                                {expanded === project.project_id ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails
                            className={
                              expanded === project.project_id
                                ? classes.accordionDetailsOpen
                                : classes.accordionDetails
                            }
                          >
                            <ProfileProjectsEdit
                              project={project}
                              newProject={closeProject}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    ))
                  : null}
              </Grid>
            </>
          ) : (
            <ProfileNoRows
              text={intl.formatMessage({ id: "profile.noProjects" })}
            />
          )}
        </Grid>
      ) : (
        <Grid container>
          <Grid md={12} className={classes.titleBig}>
            <FormattedMessage id="profile.newObject" />
          </Grid>
          <Grid item md={12}>
            <ProfileProjectsEdit type="new" newProject={setNewProject} />
          </Grid>
        </Grid>
      )}
    </LoadScript>
  );
};

const mapStateToProps = ({ user }: ApplicationState) => ({
  projects: user.profile.projects,
  projectsLoading: user.projectsLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getProjects: () => {
      dispatch(getUserProjects());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileProjects);
