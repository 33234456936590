import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: "block",
      height: "300px",
      transition: "max-height 0.15s ease-out",
      border: "1px solid #dadada",
      borderRadius: "0px",
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
        border: "1px solid #da2232",
      },
    },
    title: {
      marginBottom: 2,
      height: "120px",
      fontSize: "16px",
      fontWeight: "bold",
      textAlign: "center",
      color: "#333333",
    },
    media: {
      height: "150px",
      width: "100%",
      textAlign: "center",
      backgroundSize: "contain",
    },
    link: {
      textDecoration: "none",
    },
    content: {
      backgroundColor: "#fafafa",
    },
  })
);

interface IProps {
  category: string;
  name: string;
  image: string;
}

const ProductCategoryCard: React.FC<IProps> = ({ category, name, image }) => {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Link to={"/product/category/" + category} className={classes.link}>
      <Card classes={{ root: classes.card }}>
        <CardMedia
          className={classes.media}
          image={
            process.env.REACT_APP_IMAGE_HOST +
              "/files/menu_pictures/" +
              image || ""
          }
        />
        <CardContent className={classes.content}>
          <Box pt={5}>
            <Typography component="h1" className={classes.title}>
              {name || ""}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export default ProductCategoryCard;
