import React, { ChangeEvent } from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import InputTextField from "./TextField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '400px',
            display: 'inline-grid',
            alignSelf: 'center'
        },
        label: {
            fontSize: '20px',
            color: '#D3D3D3',
        },
        labelSelected: {
            fontSize: '16px',
            color: '#D3D3D3',
            textDecoration: 'underline',
            textDecorationColor: '#DA2232',
            textDecorationThickness: '2px',
            textUnderlineOffset: '8px',
        },
        link: {
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#000000',
            paddingBottom: '16px',
            textDecoration: 'underline'
        },
        checkbox: {
            color: '#000000'
        },
        button: {
            width: '250px',
            backgroundColor: '#da2232',
            color: '#ffffff',
            borderRadius: '0px',
            '&:hover': {
                backgroundColor: '#da2232',
                color: '#ffffff',
            },
        },
        title: {
            marginBottom: 2,
            height: '24px',
            fontSize: '16px',
            weight: 800,
            fontWeight: 'bold',
            color: '#333333',
        },
        titleBig: {
            marginBottom: 2,
            height: '31px',
            fontSize: '22px',
            fontWeight: 600,
            lineHeight: '31px',
            color: '#333333',
            textAlign: 'left'
        },
    })
);

interface IProps {
    formState: any,
    formErrors: any,
    onNext: (step: number) => any,
    handleFormChange: (e: ChangeEvent<HTMLInputElement>) => any,
}

const CartBillingAddress: React.FC<IProps> = ({
    formState,
    formErrors,
    onNext,
    handleFormChange,
}) => {

    const classes = useStyles();
    const intl = useIntl();

    return (
        <>
            <Grid item md={12} >
                <Typography className={classes.titleBig}>
                    <FormattedMessage id="payment.information" />
                </Typography>
            </Grid>
            <Grid item md={12} >
                <Typography className={classes.title}>
                    <FormattedMessage id="payment.buyer" />
                </Typography>
            </Grid>
            <Grid item md={12} >
                <RadioGroup row
                    name="clientType"
                    onChange={handleFormChange}
                    value={formState.clientType}
                >
                    <FormControlLabel value="business" id='business' control={<Radio />} label="Juridiska persona" />
                    <FormControlLabel value="individual" id="individual" control={<Radio />} label="Fiziska persona" />
                </RadioGroup>
            </Grid>
            {formState.clientType === 'business' ? (
                <Grid item md={12} >
                    <Grid
                        container
                        spacing={3}
                        direction="row">
                        <Grid item xs={12} md={6}>
                            <InputTextField name="companyName" title={intl.formatMessage({ id: 'register.companyName' })} value={formState?.companyName} error={formErrors?.companyName} enabled={true} formChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputTextField name="companyRegistrationNumber" title={intl.formatMessage({ id: 'register.companyRegistrationNumber' })} value={formState?.companyRegistrationNumber} error={formErrors?.companyRegistrationNumber} enabled={true} formChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputTextField name="phone" title={intl.formatMessage({ id: 'register.phone' })} value={formState?.phone} error={formErrors?.phone} enabled={true} formChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputTextField name="email" title={intl.formatMessage({ id: 'register.email' })} value={formState?.email} error={formErrors?.email} enabled={true} formChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Checkbox
                                checked={formState?.agreeMarketing}
                                onChange={handleFormChange}
                                name="agreeMarketing"
                                id="agreeMarketing"
                                className={classes.checkbox}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <FormattedMessage id="register.marketing" />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputTextField name="additionalInformation" title="Papildus informacija" value={formState?.additionalInformation} error={formErrors?.additionalInformation} enabled={true} formChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button variant="contained" onClick={() => onNext(1)}
                                size="large" className={classes.button}>
                                <FormattedMessage id="next" />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (null)}
            {formState.clientType === 'individual' ? (
                <Grid item md={12} >
                    <Grid
                        container
                        spacing={3}
                        direction="row">
                        <Grid item xs={12} md={6}>
                            <InputTextField name="personName" title={intl.formatMessage({ id: 'register.name' })} value={formState?.companyName} error={formErrors?.companyName} enabled={true} formChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputTextField name="personSurname" title={intl.formatMessage({ id: 'register.surname' })} value={formState?.companyRegistrationNumber} error={formErrors?.companyRegistrationNumber} enabled={true} formChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputTextField name="phone" title={intl.formatMessage({ id: 'register.phone' })} value={formState?.phone} error={formErrors?.phone} enabled={true} formChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputTextField name="email" title={intl.formatMessage({ id: 'register.email' })} value={formState?.email} error={formErrors?.email} enabled={true} formChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Checkbox
                                checked={formState?.agreeMarketing}
                                onChange={handleFormChange}
                                name="agreeMarketing"
                                id="agreeMarketing"
                                className={classes.checkbox}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <FormattedMessage id="register.marketing" />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputTextField name="additionalInformation" title="Papildus informacija" value={formState?.additionalInformation} error={formErrors?.additionalInformation} enabled={true} formChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button variant="contained" onClick={() => onNext(1)}
                                size="large" className={classes.button}>
                                <FormattedMessage id="next" />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (null)}
        </>

    )
};

export default (CartBillingAddress);
