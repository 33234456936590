import React from "react";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Image } from '../models/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      margin: '0px',
    },
    gridList: {
      width: '100px',
      height: 'auto',
      maxHeight: '350px',
    },
    gridListTile: {
      border: '1px solid #e7e7e7',
      marginTop: '5px',
    },
    gridListTileSelected: {
      border: '1px solid #DA2232',
      marginTop: '5px',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    imageGrey: {
      filter: 'grayscale(80%)',
      maxHeight: '100px',
      maxWidth: '100px',
      width: 'auto',
      height: 'auto',
      verticalAlign: 'center'
    },
    image: {
      maxHeight: '100px',
      maxWidth: '100px',
      width: 'auto',
      height: 'auto'
    }
  }),
);

interface IProps {
    images: Image[];
    currentSelection: number;
    clickHandler: (index: number) => void;
}

type AllProps = IProps;

const ProductPhotoSliderVertical: React.FC<AllProps> = ({
    images,
    currentSelection,
    clickHandler,
  }) => {
  const classes = useStyles();


  return (
    <>
    {images ? (
      <div className={classes.root}>
      <GridList cols={1} className={classes.gridList} cellHeight={100} style={{ margin: '0px' }}>
          {images.map((item, index) => (
              <GridListTile className={index === currentSelection ? classes.gridListTileSelected : classes.gridListTile} key={index}>
                <img src={process.env.REACT_APP_IMAGE_HOST + '/files/product_images/' + item.img || ''} alt={item.description} className={index !== currentSelection ? classes.imageGrey : classes.image } onClick={() => clickHandler(index || 0)} />
              </GridListTile>
          ))}
        </GridList>
      </div>
    ) : null}
    </>
  );
};

export default ProductPhotoSliderVertical;