import React, { useEffect } from "react";
import { MenuData } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: "block",
      height: "195px",
      [theme.breakpoints.down("sm")]: {
        width: "110px",
      },
      width: "195px",
      transition: "max-height 0.15s ease-out",
      border: "1px solid #cac9c9",
      borderRadius: "0px",
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
        border: "1px solid #da2232",
      },
    },
    title: {
      marginTop: "1rem",
      height: "60px",
      fontSize: "14px",
      color: "#333333",
    },
    media: {
      margin: "auto 0",
      width: "50",
      height: "50",
    },
    link: {
      textDecoration: "none",
      textAlign: "center",
      paddingLeft: "1px",
    },
  })
);

interface IProps {
  category: MenuData;
  name: string;
}

const CategoryCardSmall: React.FC<IProps> = ({ category, name }) => {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Link to={"/product/category/" + category.code} className={classes.link}>
      <Card classes={{ root: classes.card }}>
        <CardContent>
          <Box textAlign="center">
            <img
              src={
                process.env.REACT_APP_IMAGE_HOST +
                  "/files/menu_pictures/" +
                  category.code +
                  ".jpg" || ""
              }
              alt={name}
              height="90px"
            />
          </Box>
          <Typography component="h2" className={classes.title}>
            {name || ""}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default CategoryCardSmall;
