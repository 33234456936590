import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { SelectData } from "../models/types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectField: {
            width: '100%',
            height: '38px',
            fontSize: '16px',
            paddingLeft: '8px',
            backgroundColor: '#ffffff',
            border: '1px solid #e3e3e3',
            margin: '0px',
            '&$focused $notchedOutline': {
                border: '1px solid #e3e3e3'
            },
            '& label.Mui-focused': {
                color: '#333333',
                backgroundColor: '#ffffff',
            },
            '& label': {
                color: '#333333',
                backgroundColor: '#ffffff',
            },
        },
        focused: {
            border: '1px solid #000000',
            margin: '0px'
        },
        outlinedInput: {
            '&$focused $notchedOutline': {
                border: '1px solid #e3e3e3'
            },
        },
        notchedOutline: {},
        labelError: {
            color: 'red',
            alignSelf: 'center',
            fontSize: '12px'
        },
    }),
);

interface IProps {
    name: string;
    title: string,
    value: string,
    list: SelectData[],
    error?: string,
    enabled?: boolean,
    formChange: (e: any) => any,
}

const InputSelectField: React.FC<IProps> = ({
    name,
    title,
    list,
    value,
    error,
    enabled = true,
    formChange
}) => {
    useEffect(() => { }, []);

    const classes = useStyles();

    return (
        <>
            {list?.length > 0 ? (
            <Select
                value={value || ''}
                onChange={formChange}
                className={classes.selectField}
                name={name}
                id={name}
                placeholder={name}
                displayEmpty
                variant="outlined"
            >
                <MenuItem value="">{title}</MenuItem>
                {list?.map((item) => (
                    <MenuItem value={item?.code} id={item?.code} key={item?.code}>{item?.value['lv']}</MenuItem>
                ))}
            </Select>
            ) : null}
            {error ? (<span className={classes.labelError}><FormattedMessage id={error} /></span>) : (null)}
        </>
    );
};

export default (InputSelectField);
