import React, { useEffect, useState } from "react";
import { MenuData, MenuTextData, ProductDetails } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { getProductDetails } from "../store/users/action";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { ApplicationState } from "../store";
import { env } from "process";
import ProfileSpinner from "./ProfileSpinner";
import ProfileDocumentViewer from "./ProfileDocumentViewer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    backIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#666666",
      paddingBottom: "16px",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    tableHeader: {
      borderBottom: "1px solid #E3E3E3",
      padding: "0px",
    },
    tableHeaderOpen: {
      padding: "0px",
    },
    tableTitle: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
    },
    tableText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#333333",
      padding: "6px",
    },
    buttonEnd: {
      textAlign: "end",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    buttonStart: {
      textAlign: "start",
      alignSelf: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  })
);

interface IProps {
  editProductId?: string;
  editRent: boolean;
  setRentProduct: (editProduct: boolean) => any;
}

interface PropsFromState {
  productDetails: ProductDetails;
  productDetailsLoading: boolean;
}

interface propsFromDispatch {
  getProductDetails: (productId: string) => any;
}

type AllProps = IProps & propsFromDispatch & PropsFromState;

const ProfileProductsRent: React.FC<AllProps> = ({
  editProductId,
  editRent,
  productDetails,
  productDetailsLoading,
  setRentProduct,
  getProductDetails,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [isDocumentOpen, handleDocument] = useState(false);
  const [documentName, setDocumentName] = useState("");

  useEffect(() => {
    if (editProductId && editRent) getProductDetails(editProductId);
  }, [editProductId, editRent]);

  const popupHandler = () => {
    handleDocument((current) => !current);
  };

  const openDocument = (documentName: string) => {
    setDocumentName(documentName);
    popupHandler();
  };

  return (
    <Box p={2}>
      {!productDetailsLoading ? (
        <>
          <ProfileDocumentViewer
            fileName={documentName}
            isDocumentOpen={isDocumentOpen}
            closeHandler={popupHandler}
          />
        </>
      ) : null}
      {!productDetailsLoading && productDetails?.rent ? (
        <Grid container spacing={2} direction="row" justify="center">
          {productDetails?.rent.length > 0 && editRent ? (
            <>
              <Grid item md={2}>
                <Typography className={classes.tableTitle}>
                  Sākuma datums
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className={classes.tableTitle}>
                  Beigu Datums
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className={classes.tableTitle}>Skaits</Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className={classes.tableTitle}>Summa</Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className={classes.tableTitle}>
                  Defektācija
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className={classes.tableTitle}>Rēķins</Typography>
              </Grid>
            </>
          ) : null}
          {productDetails?.rent &&
          productDetails?.rent.length > 0 &&
          !editRent ? (
            <>
              {productDetails?.rent.map((rent, index) => (
                <>
                  <Grid item md={2}>
                    <Typography className={classes.tableText}>
                      {moment(rent?.startDate).format("DD-MM-YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography className={classes.tableText}>
                      {moment(rent?.endDate).format("DD-MM-YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography className={classes.tableText}>
                      {rent?.quantity}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography className={classes.tableText}>
                      {rent?.amount}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    {rent?.defectDocument ? (
                      <img
                        src={"/images/product/pdf.png"}
                        alt={""}
                        height="30px"
                        onClick={() => openDocument(rent?.defectDocument)}
                      />
                    ) : null}
                  </Grid>
                  <Grid item md={2}>
                    {rent?.invoiceDocument ? (
                      <img
                        src={"/images/product/pdf.png"}
                        alt={""}
                        height="30px"
                        onClick={() => openDocument(rent?.invoiceDocument)}
                      />
                    ) : null}
                  </Grid>
                </>
              ))}
              <Grid item md={6} className={classes.buttonStart}>
                <>
                  <span
                    onClick={() => setRentProduct(false)}
                    className={classes.backIcon}
                  >
                    {" "}
                    &lt;{" "}
                  </span>
                  <span
                    onClick={() => setRentProduct(false)}
                    className={classes.back}
                  >
                    <FormattedMessage id="back" />
                  </span>
                </>
              </Grid>
              <Grid item md={6} className={classes.buttonEnd}></Grid>
            </>
          ) : null}
          ;
        </Grid>
      ) : (
        <Grid container>
          <Grid item md={12}>
            <ProfileSpinner />
          </Grid>
          <Grid item md={6} className={classes.buttonStart}>
            <>
              <span
                onClick={() => setRentProduct(false)}
                className={classes.backIcon}
              >
                {" "}
                &lt;{" "}
              </span>
              <span
                onClick={() => setRentProduct(false)}
                className={classes.back}
              >
                <FormattedMessage id="back" />
              </span>
            </>
          </Grid>
          <Grid item md={6} className={classes.buttonEnd}></Grid>
        </Grid>
      )}
    </Box>
  );
};

const mapStateToProps = ({ user }: ApplicationState) => ({
  productDetails: user.productDetails,
  productDetailsLoading: user.productDetailsLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getProductDetails: (productId: string) => {
      dispatch(getProductDetails(productId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileProductsRent);
