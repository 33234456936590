/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getUserInboxDetails, saveMessage } from "../store/users/action";
import { connect } from "react-redux";
import { InboxDetails, UserData } from "../models/types";
import Loader from "react-loader-spinner";
import InputTextField from "./TextField";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      height: "22px",
      fontSize: "16px",
      letterSpacing: "0.25px",
      color: "#333333",
      textAlign: "left",
    },
    titleDate: {
      height: "29px",
      fontSize: "14px",
      color: "#333333",
      textAlign: "left",
    },
    text: {
      height: "22px",
      fontSize: "14px",
    },
    image: {
      maxWidth: "80%",
      maxHeight: "80px",
      border: "1px solid #E3E3E3",
    },
    message: {
      border: "1px solid #000000",
      background: "#f9f9f9",
      borderRadius: "15px 15px 15px 15px",
      padding: "20px",
    },
    container: {
      paddingTop: "15px",
    },
    button: {
      width: "120px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      }
    },
    alignRigh: {
      textAlign: "right"
    }
  })
);

interface PropsFromState {
  inboxDetails: InboxDetails[];
  inboxDetailsLoading: boolean;
  user: UserData;
}

interface PropsFromDispatch {
  getUserInboxDetails: (messageFlowId: string) => any;
  saveMessage: (payload: any, messageFlowId: string) => any;
}

interface IProps {
  messageFlowId: string;
  visible: boolean;
}

type AllProps = IProps & PropsFromState & PropsFromDispatch;

const ProfileInboxDetails: React.FC<AllProps> = ({
  messageFlowId,
  visible,
  inboxDetails,
  inboxDetailsLoading,
  user,
  getUserInboxDetails,
  saveMessage
}) => {
  useEffect(() => {
    if (visible) getUserInboxDetails(messageFlowId);
  }, [messageFlowId, visible]);

  const classes = useStyles();
  const [message, setMessage] = useState<string>("");
  const intl = useIntl();

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setMessage(value);
  };

  const validateForm = () => {
    return true;
  };

  const send = () => {
    if (validateForm()) {
      let request = {
        message: message
      };
      saveMessage(request, messageFlowId);
      setMessage("");
    }
  };

  return (
    <Grid container direction="row">
      {!inboxDetailsLoading ? (
        <>
          {inboxDetails?.map((message, index) => (
            <>
              <Grid md={6} xs={12}>
                <Grid container direction="row" className={classes.container}>
                  <Grid item md={12} className={message.stats === 1 ? classes.message : null}>
                    <Grid container>
                      <Grid item md={12}>
                        {message.stats === 1 ? (
                          <img src="/images/stats-rent-logo.svg" height="20px" alt=""/>
                        ) : null}
                      </Grid>
                      <Grid item md={12}>
                        {message.stats === 1 ? (
                          <Typography className={classes.titleDate}>
                            {message.creationDate}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid item md={12}>
                        {message.stats === 1 ? (
                          <Typography className={classes.text}>
                            {message.message}
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={6} xs={12}>
                <Grid container direction="row" className={classes.container}>
                  <Grid item md={12} className={message.stats === 0 ? classes.message : null}>
                    {message.stats === 0 ? (
                      <>
                      <Typography className={classes.title}>
                        {user.profile.title ? user.profile.organization[0].name : user.profile.name + " " + user.profile.surname}
                      </Typography>
                      <Typography className={classes.titleDate}>
                          {message.creationDate}
                      </Typography>
                      <Typography className={classes.text}>
                        {message.message}
                      </Typography>
                      </>
                      
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))}
          <Grid item xs={12} md={6}>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box pt={6} />
            <Grid container direction="row" spacing={2}>
              <Grid item md={12}>
                <InputTextField
                  name="name"
                  title={intl.formatMessage({ id: "profile.inboxResponse" })}
                  value={message || ""}
                  formChange={handleFormChange}
                />
              </Grid>
              <Grid item md={12} className={classes.alignRigh}>
                <Button
                  variant="contained"
                  onClick={() => send()}
                  size="large"
                  className={classes.button}
                >
                  <FormattedMessage id="send" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader
          type="Bars"
          color="#da2232"
          height={30}
          width={30}
          timeout={5000}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = ({ user }: ApplicationState) => ({
  inboxDetailsLoading: user.inboxDetailsLoading,
  inboxDetails: user.profile.inboxDetails,
  user: user.user
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserInboxDetails: (messageFlowId: string) => {
      dispatch(getUserInboxDetails(messageFlowId));
    },
    saveMessage: (payload: any, messageFlowId: string) => {
      dispatch(saveMessage(payload, messageFlowId))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInboxDetails);
