import {
  UserData,
  UserProfile,
  Cart,
  UserOrderDetails,
  SmartId,
  SelectData,
  MenuTextData,
  ProductDetails,
} from "../../models/types";

export enum UserActionTypes {
  LOGIN_REQUEST = "@@User/LOGIN_REQUEST",
  LOGIN_ERROR = "@@User/LOGIN_ERROR",
  LOGIN_SUCCESS = "@@User/LOGIN_SUCCESS",
  SMART_REQUEST = "@@User/SMART_REQUEST",
  SMART_ERROR = "@@User/SMART_ERROR",
  SMART_SUCCESS = "@@User/SMART_SUCCESS",
  REGISTER_REQUEST = "@@User/REGISTER_REQUEST",
  REGISTER_ERROR = "@@User/REGISTER_ERROR",
  REGISTER_SUCCESS = "@@User/REGISTER_SUCCESS",
  STATUS_REQUEST = "@@User/STATUS_REQUEST",
  STATUS_ERROR = "@@User/STATUS_ERROR",
  STATUS_SUCCESS = "@@User/STATUS_SUCCESS",
  LOGOUT_REQUEST = "@@User/LOGOUT_REQUEST",
  LOGOUT_ERROR = "@@User/LOGOUT_ERROR",
  LOGOUT_SUCCESS = "@@User/LOGOUT_SUCCESS",
  PROFILE_REQUEST = "@@User/PROFILE_REQUEST",
  PROFILE_ERROR = "@@User/PROFILE_ERROR",
  PROFILE_SUCCESS = "@@User/PROFILE_SUCCESS",
  CART_REQUEST = "@@User/CART_REQUEST",
  CART_ERROR = "@@User/CART_ERROR",
  CART_SUCCESS = "@@User/CART_SUCCESS",
  DELIVERY_REQUEST = "@@User/DELIVERY_REQUEST",
  DELIVERY_ERROR = "@@User/DELIVERY_ERROR",
  DELIVERY_SUCCESS = "@@User/DELIVERY_SUCCESS",
  EMPLOYEES_REQUEST = "@@User/EMPLOYEES_REQUEST",
  EMPLOYEES_ERROR = "@@User/EMPLOYEES_ERROR",
  EMPLOYEES_SUCCESS = "@@User/EMPLOYEES_SUCCESS",
  GVP_REQUEST = "@@User/GVP_REQUEST",
  GVP_ERROR = "@@User/GVP_ERROR",
  GVP_SUCCESS = "@@User/GVP_SUCCESS",
  GVP_STATUS_REQUEST = "@@User/GVP_STATUS_REQUEST",
  GVP_STATUS_ERROR = "@@User/GVP_STATUS_ERROR",
  GVP_STATUS_SUCCESS = "@@User/GVP_STATUS_SUCCESS",
  PRODUCTS_REQUEST = "@@User/PRODUCTS_REQUEST",
  PRODUCTS_ERROR = "@@User/PRODUCTS_ERROR",
  PRODUCTS_SUCCESS = "@@User/PRODUCTS_SUCCESS",
  ORDER_DETAILS_REQUEST = "@@User/ORDER_DETAILS_REQUEST",
  ORDER_DETAILS_ERROR = "@@User/ORDER_DETAILS_ERROR",
  ORDER_DETAILS_SUCCESS = "@@User/ORDER_DETAILS_SUCCESS",
  PRODUCT_DETAILS_REQUEST = "@@User/PRODUCT_DETAILS_REQUEST",
  PRODUCT_DETAILS_ERROR = "@@User/PRODUCT_DETAILS_ERROR",
  PRODUCT_DETAILS_SUCCESS = "@@User/PRODUCT_DETAILS_SUCCESS",
  ORDERS_REQUEST = "@@User/ORDERS_REQUEST",
  ORDERS_ERROR = "@@User/ORDERS_ERROR",
  ORDERS_SUCCESS = "@@User/ORDERS_SUCCESS",
  CONTRACTS_REQUEST = "@@User/CONTRACTS_REQUEST",
  CONTRACTS_ERROR = "@@User/CONTRACTS_ERROR",
  CONTRACTS_SUCCESS = "@@User/CONTRACTS_SUCCESS",
  INVOICES_REQUEST = "@@User/INVOICES_REQUEST",
  INVOICES_ERROR = "@@User/INVOICES_ERROR",
  INVOICES_SUCCESS = "@@User/INVOICES_SUCCESS",
  SERVICE_REQUEST = "@@User/SERVICE_REQUEST",
  SERVICE_ERROR = "@@User/SERVICE_ERROR",
  SERVICE_SUCCESS = "@@User/SERVICE_SUCCESS",
  TRANSPORT_REQUEST = "@@User/TRANSPORT_REQUEST",
  TRANSPORT_ERROR = "@@User/TRANSPORT_ERROR",
  TRANSPORT_SUCCESS = "@@User/TRANSPORT_SUCCESS",
  INVOICE_REQUEST = "@@User/INVOICE_REQUEST",
  INVOICE_ERROR = "@@User/INVOICE_ERROR",
  INVOICE_SUCCESS = "@@User/INVOICE_SUCCESS",
  INBOX_REQUEST = "@@User/INBOX_REQUEST",
  INBOX_ERROR = "@@User/INBOX_ERROR",
  INBOX_SUCCESS = "@@User/INBOX_SUCCESS",
  INBOX_DETAILS_REQUEST = "@@User/INBOX_DETAILS_REQUEST",
  INBOX_DETAILS_ERROR = "@@User/INBOX_DETAILS_ERROR",
  INBOX_DETAILS_SUCCESS = "@@User/INBOX_DETAILS_SUCCESS",
  PROJECTS_REQUEST = "@@User/PROJECTS_REQUEST",
  PROJECTS_ERROR = "@@User/PROJECTS_ERROR",
  PROJECTS_SUCCESS = "@@User/PROJECTS_SUCCESS",
  FETCH_REQUEST = "@@User/FETCH_REQUEST",
  FETCH_SUCCESS = "@@User/FETCH_SUCCESS",
  FETCH_ERROR = "@@User/FETCH_ERROR",
  COMPANY_UPDATE_REQUEST = "@@User/COMPANY_UPDATE_REQUEST",
  COMPANY_UPDATE_SUCCESS = "@@User/COMPANY_UPDATE_SUCCESS",
  COMPANY_UPDATE_ERROR = "@@User/COMPANY_UPDATE_ERROR",
  OBJECT_UPDATE_REQUEST = "@@User/OBJECT_UPDATE_REQUEST",
  OBJECT_UPDATE_SUCCESS = "@@User/OBJECT_UPDATE_SUCCESS",
  OBJECT_UPDATE_ERROR = "@@User/OBJECT_UPDATE_ERROR",
  ORDER_STATUS_REQUEST = "@@User/ORDER_STATUS_REQUEST",
  ORDER_STATUS_SUCCESS = "@@User/ORDER_STATUS_SUCCESS",
  ORDER_STATUS_ERROR = "@@User/ORDER_STATUS_ERROR",
  EMPLOYEE_LIST_REQUEST = "@@User/EMPLOYEE_LIST_REQUEST",
  EMPLOYEE_LIST_SUCCESS = "@@User/EMPLOYEE_LIST_SUCCESS",
  EMPLOYEE_LIST_ERROR = "@@User/EMPLOYEE_LIST_ERROR",
}

export interface UserState {
  readonly login: boolean;
  readonly smartId?: SmartId;
  readonly user: UserData;
  readonly profile: UserProfile;
  readonly cart: Cart;
  readonly orderDetails: UserOrderDetails;
  readonly productDetails?: ProductDetails;
  readonly employeeList: SelectData[];
  readonly orderStatusList: MenuTextData;
  readonly loading: boolean;
  readonly registerLoading: boolean;
  readonly profileLoading: boolean;
  readonly cartLoading: boolean;
  readonly projectsLoading: boolean;
  readonly invoicesLoading: boolean;
  readonly serviceLoading: boolean;
  readonly transportLoading: boolean;
  readonly employeeLoading: boolean;
  readonly contractsLoading: boolean;
  readonly ordersLoading: boolean;
  readonly orderDetailsLoading: boolean;
  readonly productDetailsLoading?: boolean;
  readonly employeeListLoading: boolean;
  readonly orderStatusListLoading: boolean;
  readonly gvpLoading: boolean;
  readonly productsLoading: boolean;
  readonly inboxLoading: boolean;
  readonly inboxDetailsLoading: boolean;
  readonly errors: string;
}
