/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { Categories, MenuTextData } from "../models/types";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Grid, Hidden } from "@material-ui/core";
import CategoryCard from "./CategoryCard";
import { searchListCategories } from "../store/products/action";
import CategoryCardSmall from "./CategoryCardSmall";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
    },
    gridList: {
      flexWrap: "nowrap",
      transform: "translateZ(0)",
      width: "100%",
    },
    sliderBox: {
      backgroundColor: "#FFFFFF",
      color: "#000000",
      fontSize: 18,
      fontWeight: "bold",
    },
    gridCell: {
      height: "200px",
      textAlign: "center",
    },
    title: {
      fontSize: "24px",
    },
  })
);

interface IProps {
  id?: string;
  title?: string;
}

interface PropsFromState {
  language: string;
  text: MenuTextData;
  textLoading: boolean;
  loading: boolean;
  categories: Categories[];
}

interface propsFromDispatch {
  searchCategories: (id?: string) => any;
}

type AllProps = IProps & PropsFromState & propsFromDispatch;

const CategorySlider: React.FC<AllProps> = ({
  id,
  title,
  language,
  text,
  textLoading,
  loading,
  categories,
  searchCategories,
}) => {
  useEffect(() => {
    searchCategories(id || "");
  }, [id]);

  const classes = useStyles();

  return (
    <>
      {!loading && !textLoading ? (
        <Box pt={5} pb={5} bgcolor="#f9f9f9" className={classes.sliderBox}>
          {title ? (
            <Box pb={2} className={classes.title}>
              {title}
            </Box>
          ) : null}
          <>
            <Hidden smUp>
              <Grid container spacing={1} style={{ margin: "3px" }}>
                {categories.map((item, index) => (
                  <Grid item sm={4} md={4} key={index}>
                    <CategoryCardSmall
                      category={item}
                      name={text[item.code].value[language]}
                    />
                  </Grid>
                ))}
              </Grid>
            </Hidden>
            <Hidden xsDown>
              <Grid container alignItems="flex-start">
                {categories.map((item, index) => (
                  <Grid item md className={classes.gridCell} key={index}>
                    <CategoryCard
                      category={item}
                      name={text[item.code].value[language]}
                    />
                  </Grid>
                ))}
              </Grid>
            </Hidden>
          </>
        </Box>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ menu, products, language }: ApplicationState) => ({
  text: menu.text,
  textLoading: menu.loading,
  language: language.language,
  loading: products.categoriesLoading,
  categories: products.categories,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    searchCategories: (id?: string) => {
      dispatch(searchListCategories(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySlider);
