import { MenuData, MenuTextData, SelectData } from "../../models/types";


export enum MenuActionTypes {
  FETCH_REQUEST = "@@Menu/FETCH_REQUEST",
  FETCH_LOADING = "@@Menu/FETCH_LOADING",
  FETCH_SUCCESS = "@@Menu/FETCH_SUCCESS",
  COUNTRIES_REQUEST = "@@Menu/COUNTRIES_REQUEST",
  COUNTRIES_LOADING = "@@Menu/COUNTRIES_LOADING",
  COUNTRIES_SUCCESS = "@@Menu/COUNTRIES_SUCCESS",
  COUNTRIES_ERROR = "@@Menu/COUNTRIES_ERROR",
  COUNTIES_REQUEST = "@@Menu/COUNTIES_REQUEST",
  COUNTIES_LOADING = "@@Menu/COUNTIES_LOADING",
  COUNTIES_SUCCESS = "@@Menu/COUNTIES_SUCCESS",
  COUNTIES_ERROR = "@@Menu/COUNTIES_ERROR",
  ROLES_REQUEST = "@@Menu/ROLES_REQUEST",
  ROLES_LOADING = "@@Menu/ROLES_LOADING",
  ROLES_SUCCESS = "@@Menu/ROLES_SUCCESS",
  ROLES_ERROR = "@@Menu/ROLES_ERROR",
  FETCH_ERROR = "@@Menu/FETCH_ERROR",
  UPDATE_STATE = "@@Menu/UPDATE_STATE",
  APPEND_STATE = "@@Menu/APPEND_STATE",
  BREADCRUMB_REQUEST = "@@Menu/BREADCRUMB_REQUEST",
  BREADCRUMB_LOADING = "@@Menu/BREADCRUMB_LOADING",
  BREADCRUMB_SUCCESS = "@@Menu/BREADCRUMB_SUCCESS",
  BREADCRUMB_ERROR = "@@Menu/BREADCRUMB_ERROR"
}

export interface MenuState {
  readonly menu: MenuData[];
  readonly text: MenuTextData;
  readonly countries: SelectData[];
  readonly counties: SelectData[];
  readonly roles: SelectData[];
  readonly treeState: string[];
  readonly breadcrumb: string[];
  readonly loading: boolean;
  readonly errors?: string;
}
