import React, { ChangeEvent, useState } from "react";
import { UserProfile } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

interface IProps {
  loading: boolean;
}

const ProfileFilters: React.FC<IProps> = ({ loading }) => {
  const [values, setValues] = useState({ code: undefined });
  const [errors, setErrors] = useState({});

  const handleChange = (e: any) => {
    e.persist();
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  return null;
};

export default ProfileFilters;
