/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Employee, SelectData } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getRoles } from "../store/menu/action";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { getEmployees } from "../store/users/action";
import ProfileEmployeesEdit from "./ProfileEmployeesEdit";
import ProfileSpinner from "./ProfileSpinner";
import { FormattedMessage, useIntl } from "react-intl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    accordionDetails: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      paddingBottom: "20px",
    },
    accordionDetailsOpen: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      paddingBottom: "20px",
      borderBottom: "1px solid #E3E3E3",
    },
    heading: {
      flexBasis: "100%",
      flexShrink: 0,
      fontSize: "14px",
      fontWeight: "bold",
      color: "#333333",
    },
    titleBig: {
      marginBottom: 2,
      height: "auto",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      paddingTop: "10px",
      paddingBottom: "10px",
      color: "#333333",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    tableHeader: {
      borderBottom: "1px solid #E3E3E3",
      padding: "0px",
    },
    tableHeaderOpen: {
      padding: "0px",
    },
    tableTitle: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
    },
    tableText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      padding: "6px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        lineHeight: "14px",
        padding: "2px",
      },
    },
    statusGreen: {
      background: "#D7EC84",
      fontSize: "12px",
      padding: "6px",
    },
    statusRed: {
      background: "#EC574E",
      fontSize: "12px",
      padding: "6px",
      color: "#ffffff",
    },
    statusGrey: {
      backgroundColor: "#E3E3E3 !important",
      fontSize: "12px",
      padding: "6px",
    },
    orderLink: {
      fontSize: "18px",
      lineHeight: "20px",
      textDecorationLine: "underline",
      color: "#333333",
      padding: "6px",
    },
    openLink: {
      textAlign: "right",
    },
    buttonEnd: {
      textAlign: "end",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    button: {
      width: "200px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        width: "150px",
        fontSize: "14px",
        padding: "5px",
      },
    },
  })
);

interface propsFromDispatch {
  getRoles: () => any;
  getEmployees: () => any;
}
interface PropsFromState {
  roles: SelectData[];
  employees: Employee[];
  employeesLoading: boolean;
}

type AllProps = PropsFromState & propsFromDispatch;

const ProfileEmployees: React.FC<AllProps> = ({
  employees,
  employeesLoading,
  roles,
  getRoles,
  getEmployees,
}) => {
  useEffect(() => {
    getRoles();
    getEmployees();
  }, []);

  const classes = useStyles();
  const intl = useIntl();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [newEmployee, setNewEmployee] = React.useState<boolean | false>(false);
  const handlePanelChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const closeEmployee = (value: boolean) => {
    setNewEmployee(value);
    setExpanded(false);
  };

  return !newEmployee ? (
    <>
      <Grid container>
        <Grid item xs={6} md={6} className={classes.titleBig}>
          <FormattedMessage id="profile.employees" />
        </Grid>
        <Grid item xs={6} md={6} className={classes.buttonEnd}>
          <Button
            variant="contained"
            size="large"
            className={classes.button}
            onClick={() => setNewEmployee(true)}
          >
            <FormattedMessage id="profile.newEmployee" />
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12}>
          {employees?.length > 0 ? (
            <Hidden smDown>
              <Grid container className={classes.tableHeader}>
                <Grid item md={3}>
                  <Typography className={classes.tableTitle}>
                    <FormattedMessage id="register.name" />,{" "}
                    <FormattedMessage id="register.surname" />
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography className={classes.tableTitle}>
                    <FormattedMessage id="profile.employeeEmail" />
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography className={classes.tableTitle}>
                    <FormattedMessage id="profile.employeePhone" />
                  </Typography>
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Hidden>
          ) : null}
          {!employeesLoading ? (
            employees?.map((employee, index) => (
              <Accordion
                className={classes.accordion}
                expanded={expanded === employee.id.toString()}
                onChange={handlePanelChange(employee.id.toString())}
              >
                <AccordionSummary
                  id={employee.id.toString()}
                  className={
                    expanded === employee.id.toString()
                      ? classes.tableHeaderOpen
                      : classes.tableHeader
                  }
                >
                  <Grid container>
                    <Grid item md={3} xs={6}>
                      <Typography className={classes.tableText}>
                        {employee.name || ""} {employee.surname || ""}
                      </Typography>
                    </Grid>
                    <Hidden smDown>
                      <Grid item md={3}>
                        <Typography className={classes.tableText}>
                          {employee.email || ""}
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Typography className={classes.tableText}>
                          {employee.phone || ""}
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Grid
                      item
                      xs={4}
                      md={2}
                      className={
                        employee.active === true
                          ? classes.statusGreen
                          : classes.statusRed
                      }
                    >
                      {employee.active === true
                        ? intl.formatMessage({ id: "profile.employeeActive" })
                        : intl.formatMessage({
                            id: "profile.employeeInactive",
                          })}
                    </Grid>
                    <Grid item md={2} className={classes.openLink}>
                      {expanded === employee.id.toString() ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === employee.id.toString()
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <ProfileEmployeesEdit
                    employee={employee}
                    roles={roles}
                    newEmployee={closeEmployee}
                  />
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <ProfileSpinner />
          )}
        </Grid>
      </Grid>
    </>
  ) : (
    <Grid container>
      <Grid md={12} className={classes.titleBig}>
        <FormattedMessage id="profile.newEmployee" />
      </Grid>
      <Grid item md={12}>
        <ProfileEmployeesEdit
          type="new"
          roles={roles}
          newEmployee={setNewEmployee}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ user, menu }: ApplicationState) => ({
  roles: menu.roles,
  employees: user.profile.employees,
  employeesLoading: user.employeeLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getRoles: () => {
      dispatch(getRoles());
    },
    getEmployees: () => {
      dispatch(getEmployees());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEmployees);
