import React from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        partners: {
            color: '#333333',
            fontSize: 14,
            textAlign: 'center'
        },
        link: {
            color: '#da2232'
        },
    }),
);

const Error404: React.FC = () => {

    const classes = useStyles();

    return (
        <Box pt={10} pb={10} pl={2} pr={2} className={classes.partners}>
            <img src="/images/404/404.png" alt="Error" />
            <br />
            <br />
            <FormattedMessage id="404.1" />
            <br />
            <br />
            <FormattedMessage id="404.2" /> 
            <Link to="/" className={classes.link}>
                <FormattedMessage id="404.3" />
            </Link>.
            <br />

        </Box>
    );
};

export default (Error404);
