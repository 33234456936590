import ReactGA from "react-ga4";
import generateClientIdGa from "./generate-client-id-ga";
import { UaEventOptions } from "react-ga4/types/ga4";

enum HitTypes {
    PageView = "pageview"
}

const ga = {
    initGoogleAnalytics() {
        const trackingId = process.env.REACT_APP_TRACKING_ID || '';
        if (!trackingId) console.warn("No tracking id is found for Google Analytics")
        ReactGA.initialize([
            {
                trackingId,
                gaOptions: {
                    anonymizeIp: true,
                    clientId: generateClientIdGa()
                }
            }
        ]);
    },
    setOption(key: string, value: unknown) {
        ReactGA.set({ [key]: value });
    },
    setUserId(userId: string | number) {
        this.setOption('userId', userId);
    },
    sendData(type: HitTypes, data: Object) {
        ReactGA.send({ hitType: type, ...data });
    },
    trackPageView(pageTitle?: string, pagePath?: string) {
        if (!pageTitle) {
            pageTitle = document.title;
        }

        if (!pagePath) {
            pagePath = window.location.href;
        }

        this.sendData(HitTypes.PageView, { page: pagePath, title: pageTitle });
    },
    trackEventBuilder(categoryName: string) {
        return (options: Omit<UaEventOptions, 'category'>) => {
            ReactGA.event({ category: categoryName, ...options });
        };
    }
};

export default ga;