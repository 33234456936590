/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { ProductsData } from "../models/types";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import { searchProducts } from "../store/products/action";
import ProductSlider from "./ProductSlider";
import { Box } from "@material-ui/core";
import CategorySlider from "./CategorySlider";
import LocalHelmet from "./Helmet";
import { useIntl } from "react-intl";
import CategorySliderStats from "./CategorySliderStats";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    banner: {
      width: "100%",
      maxWidth: "1200px",
    },
    catalog: {
      textAlign: "center",
    },
    catalogList: {
      textAlign: "left",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    },
    subscribe: {
      textAlign: "left",
      backgroundColor: "#F2F2F2",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    },
  })
);

interface PropsFromState {
  newProducts: ProductsData[];
  newProductsLoading: boolean;
}

interface propsFromDispatch {
  searchProducts: (type: string) => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const Landing: React.FC<AllProps> = ({
  newProducts,
  newProductsLoading,
  searchProducts,
}) => {
  useEffect(() => {
    searchProducts("new");
    searchProducts("recent");
  }, []);

  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Box pt={2} className={classes.catalog}>
        <LocalHelmet title={intl.formatMessage({ id: "main" })} />
        <Link to="/product/category">
          <img
            src="/images/landing/banner.png"
            className={classes.banner}
            alt=""
          />
        </Link>
        <Box className={classes.catalogList}>
          <CategorySliderStats
            title={intl.formatMessage({ id: "byCategories" })}
          />
        </Box>
      </Box>
      <Box className={classes.catalogList}>
        <ProductSlider
          title={intl.formatMessage({ id: "bySeason" })}
          dataLoading={newProductsLoading}
          data={newProducts}
        />
      </Box>
    </>
  );
};

const mapStateToProps = ({ menu, products, user }: ApplicationState) => ({
  newProducts: products.newProducts,
  newProductsLoading: products.newLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    searchProducts: (type: string) => {
      dispatch(searchProducts(type));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
