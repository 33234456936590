import { Reducer } from "redux";
import { MenuActionTypes, MenuState } from "./types";

export const initialState: MenuState = {
  menu: [],
  countries: [],
  counties: [],
  roles: [],
  breadcrumb: [],
  text: {},
  errors: undefined,
  loading: false,
  treeState: ["X"],
};

const reducer: Reducer<MenuState> = (state = initialState, action) => {
  switch (action.type) {
    case MenuActionTypes.UPDATE_STATE: {
      return { ...state, treeState: action.payload };
    }
    case MenuActionTypes.APPEND_STATE: {
      return { ...state, treeState: action.payload };
      //return { ...state, treeState: state.treeState.concat(action.payload) };
    }
    case MenuActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case MenuActionTypes.FETCH_SUCCESS: {
      return { ...state, text: action.text, menu: action.menu, loading: false };
    }
    case MenuActionTypes.FETCH_LOADING: {
      return { ...state, loading: true };
    }
    case MenuActionTypes.BREADCRUMB_SUCCESS: {
      return { ...state, breadcrumb: action.payload };
    }
    case MenuActionTypes.COUNTRIES_SUCCESS: {
      return { ...state, countries: action.payload };
    }
    case MenuActionTypes.COUNTIES_SUCCESS: {
      return { ...state, counties: action.payload };
    }
    case MenuActionTypes.ROLES_SUCCESS: {
      return { ...state, roles: action.payload };
    }
    case MenuActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as MenuReducer };
