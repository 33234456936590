/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  loginEmployee,
  loginSmartId,
  loginSmartIdClean,
  loginSmartIdStatus,
} from "../store/users/action";
import { connect } from "react-redux";
import { SmartId } from "../models/types";
import SmartIdInput from "./SmartIdInput";
import InputTextField from "./TextField";
import { FormattedMessage, useIntl } from "react-intl";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 0.1,
    },
    selectText: {
      fontSize: "14px",
      fontWeight: "bold",
      paddingTop: "3px",
      paddingLeft: "5px",
    },
    code: {
      width: "100%",
      fontSize: "35px",
      backgroundColor: "#ffffff",
      textDecoration: "bold",
      textAlign: "center",
      color: "#333333",
      margin: "0px",
    },
    error: {
      width: "100%",
      fontSize: "16px",
      backgroundColor: "#ffffff",
      textDecoration: "bold",
      textAlign: "center",
      color: "#da2232",
    },
    focused: {},
    outlinedInput: {
      "&$focused $notchedOutline": {
        border: "1px solid #e3e3e3",
      },
    },
    notchedOutline: {},
    button: {
      width: "100%",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    title: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#000000",
      paddingBottom: "16px",
    },
    information: {
      fontSize: "13px",
      fontWeight: "normal",
      color: "#000000",
      paddingBottom: "16px",
      paddingTop: "18px",
      textAlign: "center",
    },
    link: {
      color: "#da2232",
    },
    registration: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#000000",
      paddingBottom: "16px",
      textDecoration: "underline",
    },
    formWidth: {
      maxWidth: "440px",
    },
  })
);

interface PropsFromState {
  login: boolean;
  smartId?: SmartId;
  error?: string;
}

interface propsFromDispatch {
  loginSmartId: (userData: any) => any;
  loginSmartIdStatus: (hash: string, cookie: any, setCookie: any) => any;
  loginEmployee: (userData: any, setCookie: any) => any;
  loginSmartIdClean: () => any;
}

interface IProps {
  isLoginOpen: boolean;
  closeHandler: () => any;
}

type AllProps = IProps & PropsFromState & propsFromDispatch;

const Login: React.FC<AllProps> = ({
  isLoginOpen,
  closeHandler,
  login,
  smartId,
  error,
  loginSmartId,
  loginSmartIdStatus,
  loginEmployee,
  loginSmartIdClean,
}) => {
  const [loop, setLoop] = useState(0);
  const [cookies, setCookie] = useCookies();
  const classes = useStyles();
  const intl = useIntl();
  const [values, setValues] = useState({
    userName: undefined,
    userPassword: undefined,
    ssn: undefined,
    hash: undefined,
    code: undefined,
    showPassword: false,
  });
  const [employeeLoginError, setEmployeeLoginError] = useState(false);

  useEffect(() => {
    if (smartId?.status === "REQUESTED" && smartId?.flow === "LOGIN") {
      setLoop(
        window.setInterval(() => {
          loginSmartIdStatus(smartId?.hash, cookies, setCookie);
        }, 1000)
      );
      setTimeout(() => clearInterval(loop), 90000);
    } else {
      clearInterval(loop);
    }
  }, [smartId?.status]);

  useEffect(() => {
    if (smartId?.status === "ERROR" && smartId?.flow === "LOGIN") {
      setTimeout(() => {
        closeHandler();
        setTimeout(() => loginSmartIdClean(), 1000);
      }, 5000);
    }
  }, [smartId?.status]);

  useEffect(() => {
    if (smartId?.status === "SUCCESS" && smartId?.flow === "LOGIN") {
      setTimeout(() => {
        closeHandler();
      }, 5000);
    }
  }, [smartId?.status]);

  useEffect(() => {
    if (error) {
      setEmployeeLoginError(true);
    } else {
      setEmployeeLoginError(false);
    }
  }, [error]);

  const handleChange = (e: any) => {
    e.persist();
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const loginHandler = async () => {
    const userData = {
      ssn: values.ssn,
      country: "LV",
    };
    loginSmartId(userData);
  };

  const loginHandlerEmployee = async () => {
    const userData = {
      username: values.userName,
      password: values.userPassword,
    };
    loginEmployee(userData, setCookie);
  };

  return (
    <Dialog
      open={isLoginOpen && !login}
      onBackdropClick={closeHandler}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <DialogContentText align="center">
          <Box className={classes.title}>
            <FormattedMessage id="login.title" />
          </Box>
        </DialogContentText>
        <Grid
          container
          spacing={4}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} md={6}>
            <Box textAlign="center" pb={2}>
              <img src="/images/smart-id.png" alt="Smart ID" />
            </Box>
            <Box
              display={smartId?.status === "REQUESTED" ? "block" : "none"}
              className={classes.code}
            >
              {smartId?.code}
            </Box>
            <Box
              display={smartId?.status === "ERROR" ? "block" : "none"}
              className={classes.error}
            >
              {smartId?.error ? <FormattedMessage id={smartId?.error} /> : null}
            </Box>
            <Box
              display={smartId?.status !== "REQUESTED" ? "block" : "none"}
              pt={5}
            >
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12} md={9} style={{ textAlign: "center" }}>
                  <SmartIdInput
                    title=""
                    value={values.ssn || ""}
                    formChange={handleChange}
                  />
                </Grid>
                <Grid xs={12} item md={3}>
                  <Button
                    variant="contained"
                    onClick={loginHandler}
                    size="large"
                    className={classes.button}
                  >
                    <FormattedMessage id="login.sub" />
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.information}>
              <FormattedMessage id="register.smartIdMore" />{" "}
              <a
                href="https://www.smart-id.com/lv/"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                {" "}
                <FormattedMessage id="register.smartIdHere" />{" "}
              </a>
              .
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              justify="center"
              className={classes.formWidth}
            >
              <Grid item xs={12} md={12}>
                <InputTextField
                  name="userName"
                  title={intl.formatMessage({ id: "login.username" })}
                  value={values.userName || ""}
                  enabled={true}
                  formChange={handleChange}
                  fieldError={employeeLoginError}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputTextField
                  name="userPassword"
                  title={intl.formatMessage({ id: "login.password" })}
                  value={values.userPassword || ""}
                  enabled={true}
                  formChange={handleChange}
                  isPassword={true}
                  fieldError={employeeLoginError}
                />
              </Grid>
              {employeeLoginError ? (
                <Grid item xs={12} md={12} className={classes.error}>
                  <FormattedMessage id="login.error" />
                </Grid>
              ) : null}
              <Grid xs={12} item md={12}>
                <Button
                  variant="contained"
                  onClick={loginHandlerEmployee}
                  size="large"
                  className={classes.button}
                >
                  <FormattedMessage id="login.sub" />
                </Button>
              </Grid>
            </Grid>
            <Box className={classes.information}>
              
            </Box>
          </Grid>
        </Grid>
        <Box pt={3} className={classes.information}>
          <Link
            to="/registracija"
            onClick={closeHandler}
            className={classes.registration}
          >
            <FormattedMessage id="login.new" />
          </Link>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({ user }: ApplicationState) => ({
  login: user.login,
  smartId: user.smartId,
  error: user.errors,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    loginSmartId: (userData: any) => {
      dispatch(loginSmartId(userData));
    },
    loginSmartIdStatus: (hash: string, cookie: any, setCookies: any) => {
      dispatch(loginSmartIdStatus(hash, cookie, setCookies));
    },
    loginEmployee: (userData: any, setCookie: any) => {
      dispatch(loginEmployee(userData, setCookie));
    },
    loginSmartIdClean: () => {
      dispatch(loginSmartIdClean());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
