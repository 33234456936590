import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    partners: {
      color: "#333333",
      fontSize: 14,
      textAlign: "center",
    },
    link: {
      color: "#da2232",
    },
  })
);

const CartSuccess: React.FC = () => {
  const classes = useStyles();
  let history = useHistory();

  useEffect(() => {
    setTimeout(() => history.push("/"), 10000);
  }, []);

  return (
    <Box pt={10} pb={10} pl={2} pr={2} className={classes.partners}>
      <FormattedMessage id="payment.thanks" />
      <br />
      <br />
      <Link to="/ka-iznomat" className={classes.link}>
        <FormattedMessage id="payment.mw1" />
      </Link>
      .
      <br />
      <br />
    </Box>
  );
};

export default CartSuccess;
