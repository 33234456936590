import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { History } from "history";

import { MenuReducer } from "./menu/reducer";
import { MenuState } from "./menu/types";

import { UserReducer } from "./users/reducer";
import { UserState } from "./users/types";

import { ProductsReducer } from './products/reducer';
import { ProductState } from './products/types';

import { LanguageReducer } from './language/reducer';
import { LanguageState } from './language/types';

import { RouterState } from "connected-react-router";

export interface ApplicationState {
    menu: MenuState;
    products: ProductState;
    user: UserState;
    router: RouterState;
    language: LanguageState;
}

export const createRootReducer = (history: History) =>
    combineReducers({
        menu: MenuReducer,
        products: ProductsReducer,
        user: UserReducer,
        language: LanguageReducer,
        router: connectRouter(history)
    });
