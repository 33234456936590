/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from "react";
import { MenuTextData, Orders, SelectData } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
  Hidden,
  Popover,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ProfileOrderDetails from "./ProfileOrderDetails";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../store";
import {
  getEmployeeList,
  getOrderStatusList,
  getUserOrders,
} from "../store/users/action";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import ProfileNoRows from "./ProfileNoRows";
import ProfileSpinner from "./ProfileSpinner";
import InputTextField from "./TextField";
import InputSelectField from "./SelectField";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import FilterListIcon from '@material-ui/icons/FilterList';
import DatePicker, { registerLocale } from "react-datepicker";
import { getDay } from "date-fns";
import { lv } from "date-fns/locale";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    accordionDetails: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      paddingBottom: "20px",
    },
    accordionDetailsOpen: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      paddingBottom: "20px",
      borderBottom: "1px solid #E3E3E3",
    },
    heading: {
      flexBasis: "100%",
      flexShrink: 0,
      fontSize: "14px",
      fontWeight: "bold",
      color: "#333333",
    },
    titleBig: {
      marginBottom: 2,
      height: "auto",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      paddingBottom: "20px",
      color: "#333333",
      textAlign: "left",
    },
    tableHeader: {
      borderBottom: "1px solid #E3E3E3",
      padding: "0px",
    },
    tableHeaderOpen: {
      padding: "0px",
    },
    tableTitle: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
    },
    tableText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      padding: "6px",
    },
    statusGreen: {
      background: "#D7EC84",
      fontSize: "12px",
      padding: "6px",
    },
    statusRed: {
      background: "#EC574E",
      fontSize: "12px",
      padding: "6px",
      color: "#ffffff",
    },
    statusGrey: {
      backgroundColor: "#E3E3E3 !important",
      fontSize: "12px",
      padding: "6px",
    },
    orderLink: {
      fontSize: "18px",
      lineHeight: "20px",
      textDecorationLine: "underline",
      color: "#333333",
      padding: "6px",
    },
    filter: {
      color: "#EC574E",
      fontSize: "22px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    openLink: {
      textAlign: "right",
    },
    search: {
      maxWidth: "600px",
      minHeight: "500px",
      padding: "10px",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    divider: {
      padding: "10px",
    },
    checkbox: {
      color: "#333333",
    },
    inputInput: {
      padding: theme.spacing(2, 1.5, 1.5, 0),
      transition: theme.transitions.create("width"),
      paddingLeft: `calc(0.1em + ${theme.spacing(1)}px)`,
      width: "94%",
      borders: "0px",
      background: "#ffffff",
      borderTop: "0px",
      borderRight: "0px",
      borderLeft: "0px",
      fontSize: "16px",
      color: "#333333",
      borderBottomColor: "1px solid #e3e3e3",
    },
    button: {
      width: "100px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
  })
);

registerLocale("lv", {
  ...lv,
  options: {
    weekStartsOn: 1,
    lastDayOfWeek: 0,
    firstWeekContainsDate: 1,
  },
});

const isWeekday = (date: Date) => {
  const day: number = getDay(date);
  return day !== 0;
};

interface PropsFromState {
  orders: Orders[];
  language: string;
  ordersLoading: boolean;
  orderStatusList: MenuTextData;
  orderStatusLoading: boolean;
  employeeList: SelectData[];
  employeeListLoading: boolean;
}

interface propsFromDispatch {
  getUserOrders: (payload: any) => any;
  getEmployeeList: () => any;
  getOrderStatusList: () => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const ProfileOrders: React.FC<AllProps> = ({
  orders,
  language,
  ordersLoading,
  orderStatusList,
  orderStatusLoading,
  employeeList,
  employeeListLoading,
  getUserOrders,
  getEmployeeList,
  getOrderStatusList,
}) => {
  useEffect(() => {
    getUserOrders({});
    getEmployeeList();
    getOrderStatusList();
  }, []);

  const classes = useStyles();
  const intl = useIntl();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handlePanelChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;
    setFilterState({
      ...filterState,
      [name]: value,
    });
  };

  const [filterState, setFilterState] = useState<any>({});
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value, type } = e.currentTarget;
    const setValue = type === "checkbox" ? checked : value;
    let filter = {
      ...filterState,
      [name]: setValue,
    };
    setFilterState({ ...filter });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  
  const handleFilterOpen = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    let filter = {
      startDate: startDate,
      endDate: endDate,
      amountFrom: filterState.amountFrom,
      amountTo: filterState.amountTo,
      invoiceNumber: filterState.invoice,
      payed: filterState.payed
    }
    handleFilterClose();
    console.log(filter)
  };

  useEffect(() => {
    let request = {
      orderNumber: filterState.orderNumber,
      orderEmployee: filterState.orderEmployee,
      orderStatus: filterState.orderStatus,
      orderAmountFrom: filterState.orderAmountFrom,
      orderAmountTo: filterState.orderAmountTo
    };
    getUserOrders(request);
  }, [filterState]);


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Grid item md={12} className={classes.titleBig}>
        <FormattedMessage id="profile.myOrders" />
          &nbsp;&nbsp;
          <FilterListIcon
                      className={classes.filter}
                      onClick={handleFilterOpen}
                    />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            style={{
                overflow: "hidden !important",
                height: "600px",
            }}
          >
            <Grid container className={classes.search}>
              <Grid item md={12}>
                <Typography className={classes.titleBig}>
                  <FormattedMessage id="profile.search" />
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} className={classes.divider}>
                <InputTextField
                  name="orderAmountFrom"
                  title={intl.formatMessage({ id: "search.amountFrom" })}
                  value={filterState?.orderAmountFrom || ""}
                  enabled={true}
                  formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                    handleFormChange(ev)
                  }
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.divider}>
                <InputTextField
                  name="orderAmountTo"
                  title={intl.formatMessage({ id: "search.amountTo" })}
                  value={filterState?.orderAmountTo || ""}
                  enabled={true}
                  formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                    handleFormChange(ev)
                  }
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.divider}>
                <InputTextField
                  name="orderNumber"
                  title={intl.formatMessage({ id: "profile.orderNumber" })}
                  value={filterState?.orderNumber || ""}
                  enabled={true}
                  formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                    handleFormChange(ev)
                  }
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.divider}>
                  {!employeeListLoading ? (
                    <InputSelectField
                      name="orderEmployee"
                      title={intl.formatMessage({ id: "profile.user" })}
                      list={employeeList}
                      value={filterState?.orderEmployee || ""}
                      formChange={handleSelectChange}
                    />
                  ) : null}
              </Grid>
              <Grid item md={6} xs={12} className={classes.divider}>
                <DatePicker
                  selected={
                    startDate
                      ? moment(startDate, "yyyy-MM-DD").toDate()
                      : undefined
                  }
                  onChange={(date: Date) => setStartDate(date)}
                  selectsStart
                  locale={lv}
                  filterDate={isWeekday}
                  dateFormat="dd.MM.yyyy"
                  placeholderText={intl.formatMessage({ id: "search.dateFrom" })}
                  monthsShown={1}
                  className={classes.inputInput}
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.divider}>
                <DatePicker
                  selected={
                    endDate
                      ? moment(endDate, "yyyy-MM-DD").toDate()
                      : undefined
                  }
                  onChange={(date: Date) => setEndDate(date)}
                  selectsStart
                  locale={lv}
                  minDate={startDate || moment().toDate()}
                  filterDate={isWeekday}
                  dateFormat="dd.MM.yyyy"
                  placeholderText={intl.formatMessage({ id: "search.dateTo" })}
                  monthsShown={1}
                  className={classes.inputInput}
                />
              </Grid>
              <Grid md={12} xs={12} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  size="small"
                  className={classes.button}
                  onClick={() => handleSearch()}
                >
                  <FormattedMessage id="search" />
                </Button>
              </Grid>
            </Grid>
          </Popover>
      </Grid>
      {orders?.length > 0 ? (
        <Hidden smDown>
          <Grid container className={classes.tableHeader}>
            <Grid item md={2}>
              <Typography className={classes.tableTitle}>
                Pasūtījuma numurs
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.tableTitle}>Statuss</Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className={classes.tableTitle}>
                Piegādes termiņš
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.tableTitle}>
                Defektu atklāšanas ziņojums
              </Typography>
            </Grid>
            <Grid item md={2}>
            </Grid>
          </Grid>
        </Hidden>
      ) : null}
      {!ordersLoading ? (
        orders?.length > 0 ? (
          orders?.map((order, index) => (
            <Accordion
              className={classes.accordion}
              expanded={expanded === order.order_id}
              onChange={handlePanelChange(order.order_id)}
              key={index}
            >
              <AccordionSummary
                id={order.order_id}
                className={
                  expanded === order.order_id
                    ? classes.tableHeaderOpen
                    : classes.tableHeader
                }
              >
                <Grid container>
                  <Grid item md={2} xs={6}>
                    <Typography className={classes.tableText}>
                      {order.order_id}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    xs={4}
                    className={
                      order.status === "000000001"
                        ? classes.statusGrey
                        : order.status === "000000004"
                        ? classes.statusRed
                        : classes.statusGreen
                    }
                  >
                    {orderStatusList[order.status]?.value[language]}
                  </Grid>
                  <Hidden smDown>
                    <Grid item md={1}></Grid>
                    <Grid item md={2}>
                      <Typography className={classes.tableText}>
                        {order.creationDate}
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      {order.defectDocument ? (
                        <Link to="/" className={classes.orderLink}>
                          ?
                        </Link>
                      ) : null}
                    </Grid>
                  </Hidden>
                  <Grid item xs={2} md={2} className={classes.openLink}>
                    {expanded === order.order_id ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </Grid>
                  {order?.notes ? (
                    <Grid item xs={12} md={12} className={classes.tableText}>
                    { intl.formatMessage({ id: "cart.notes" }) + ": " + order?.notes}
                  </Grid>
                  ) : ( null )}
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                className={
                  expanded === order.order_id
                    ? classes.accordionDetailsOpen
                    : classes.accordionDetails
                }
              >
                <ProfileOrderDetails
                  orderId={order.order_id}
                  visible={expanded === order.order_id}
                />
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <ProfileNoRows
            text={intl.formatMessage({ id: "profile.noOrders" })}
          />
        )
      ) : (
        <ProfileSpinner />
      )}
    </>
  );
};

const mapStateToProps = ({ user, language }: ApplicationState) => ({
  orders: user.profile.orders,
  language: language.language,
  ordersLoading: user.ordersLoading,
  orderStatusList: user.orderStatusList,
  orderStatusLoading: user.orderStatusListLoading,
  employeeList: user.employeeList,
  employeeListLoading: user.employeeListLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserOrders: (payload: any) => {
      dispatch(getUserOrders(payload));
    },
    getOrderStatusList: () => {
      dispatch(getOrderStatusList());
    },
    getEmployeeList: () => {
      dispatch(getEmployeeList());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOrders);
