/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getUserOrderDetails } from "../store/users/action";
import { connect } from "react-redux";
import { UserOrderDetails } from "../models/types";
import Loader from "react-loader-spinner";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 2,
      height: "29px",
      fontSize: "14px",
      letterSpacing: "0.25px",
      color: "#333333",
      textAlign: "left",
    },
    total: {
      marginBottom: 2,
      minHeight: "60px",
      fontSize: "14px",
      fontWeight: "bold",
      letterSpacing: "0.25px",
      color: "#333333",
      textAlign: "right",
    },
    hr: {
      border: "none",
      height: "1px",
      color: "#e3e3e3",
      backgroundColor: "#e3e3e3",
    },
    tableHeader: {
      padding: "0px",
    },
    tableTitle: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
    },
    tableTitleCenter: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
      textAlign: "center"
    },
    image: {
      maxWidth: "80%",
      maxHeight: "80px",
      border: "1px solid #E3E3E3",
    },
    imageTab: {
      textAlign: "center",
    },
  })
);

interface PropsFromState {
  orderDetails: UserOrderDetails;
  orderDetailsLoading: boolean;
  language: string;
}

interface PropsFromDispatch {
  getUserOrderDetails: (orderId: string) => any;
}

interface IProps {
  orderId: string;
  visible: boolean;
}

type AllProps = IProps & PropsFromState & PropsFromDispatch;

const ProfileOrderDetails: React.FC<AllProps> = ({
  orderId,
  visible,
  orderDetails,
  orderDetailsLoading,
  language,
  getUserOrderDetails,
}) => {
  useEffect(() => {
    if (visible) getUserOrderDetails(orderId);
  }, [orderId, visible]);

  const classes = useStyles();

  return (
    <Grid container spacing={0} direction="row" justify="center">
      {!orderDetailsLoading ? (
        <>
          <Hidden smDown>
            <Grid item md={5}>
              <Typography className={classes.tableTitle}>
                PRECES NOSAUKUMS
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.tableTitle}>
                nomas periods
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className={classes.tableTitle}>
                preču daudzums
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className={classes.tableTitleCenter}>summa</Typography>
            </Grid>
          </Hidden>
          {orderDetails.products?.map((product, index) => (
            <>
              <Grid md={12}>
                <hr className={classes.hr} />
              </Grid>
              <Grid md={2} xs={3} className={classes.imageTab}>
                <img
                  src={
                    process.env.REACT_APP_IMAGE_HOST +
                      "/files/product_images/" +
                      product?.image[0].img || ""
                  }
                  alt={product?.title[language]}
                  className={classes.image}
                />
              </Grid>
              <Grid item xs={9} md={3}>
                {product?.title[language] ? (
                  <>
                    <Typography className={classes.title}>
                      <b>{product?.title[language] || ""}</b>
                    </Typography>
                    <Hidden smUp>
                      <Typography className={classes.title}>
                        {product?.orderQuantity}{" "}
                        <FormattedMessage id="cart.2" />
                      </Typography>
                    </Hidden>
                  </>
                ) : null}
              </Grid>
              <Grid item className={classes.title} xs={12} md={3}>
                {product?.startDate ? (
                  <Typography className={classes.title}>
                    {product?.startDate} - {product?.endDate}
                    <br/>
                    ({product?.period}{" "}
                    <FormattedMessage id="profile.days" />)
                  </Typography>
                ) : null}
              </Grid>
              <Hidden smDown>
                <Grid item md={2} xs={12}>
                  {product?.orderQuantity ? (
                    <Typography className={classes.title}>
                      {product?.orderQuantity} <FormattedMessage id="cart.2" />
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item md={2}>
                  {product?.totalProduct ? (
                    <Typography className={classes.total}>
                      <b>€ {product?.totalProduct}</b>
                    </Typography>
                  ) : null}
                </Grid>
              </Hidden>
            </>
          ))}
          <Grid item xs={12} md={8}></Grid>
          <Grid item xs={12} md={4}>
            <Grid
              container
              spacing={0}
              direction="row"
              justify="center"
              className={classes.total}
            >
              <Grid item md={6} xs={6}>
                <FormattedMessage id="transport" />:
              </Grid>
              <Grid item md={6} xs={6}>
                € {orderDetails.transport}
              </Grid>
              <Grid item md={6} xs={6}>
                <FormattedMessage id="cart.1" />:
              </Grid>
              <Grid item md={6} xs={6}>
                € {orderDetails.total}
              </Grid>
              <Grid item md={6} xs={6}>
                <FormattedMessage id="cart.withVAT" />:
              </Grid>
              <Grid item md={6} xs={6}>
                € {orderDetails.totalVAT}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader
          type="Bars"
          color="#da2232"
          height={30}
          width={30}
          timeout={5000}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = ({ user, language }: ApplicationState) => ({
  orderDetailsLoading: user.orderDetailsLoading,
  orderDetails: user.orderDetails,
  language: language.language,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserOrderDetails: (orderId: string) => {
      dispatch(getUserOrderDetails(orderId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileOrderDetails);
