
import { LanguageActionTypes } from "./types";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../index";

export type AppThunk = ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
>;

export const changeLanguage: AppThunk = (language: string) => {
  return (dispatch: Dispatch): Action => {
    return dispatch({
      type: LanguageActionTypes.CHANGE,
      language: language
    })
  }
}
