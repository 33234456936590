import { ProductActionTypes } from "./types";
import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../index";

export type AppThunk = ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
>;

export const searchProducts =
  (type?: string, id?: string) => (dispatch: any) => {
    switch (type) {
      case "new": {
        dispatch({ type: ProductActionTypes.FETCH_NEW_REQUEST });
        break;
      }
      case "recent": {
        dispatch({ type: ProductActionTypes.FETCH_VIEWED_REQUEST });
        break;
      }
      case "similar": {
        dispatch({ type: ProductActionTypes.FETCH_SIMILAR_REQUEST });
        break;
      }
      case "id": {
        dispatch({ type: ProductActionTypes.FETCH_ID_REQUEST });
        break;
      }
      default:
        dispatch({ type: ProductActionTypes.FETCH_REQUEST });
        break;
    }

    const token: string = localStorage.getItem("token") || "";
    if (token) {
      let path = "";
      if (type !== "id") path = "/" + type;
      if (id) path = path + "/" + id;
      fetch(process.env.REACT_APP_API_HOST + "/products" + path, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) =>
          response.text().then(function (text) {
            return text ? JSON.parse(text) : {};
          })
        )
        .then((data) => {
          switch (type) {
            case "new": {
              dispatch({
                type: ProductActionTypes.FETCH_NEW_SUCCESS,
                payload: data,
              });
              break;
            }
            case "recent": {
              dispatch({
                type: ProductActionTypes.FETCH_VIEWED_SUCCESS,
                payload: data,
              });
              break;
            }
            case "similar": {
              dispatch({
                type: ProductActionTypes.FETCH_SIMILAR_SUCCESS,
                payload: data,
              });
              break;
            }
            case "id": {
              dispatch({
                type: ProductActionTypes.FETCH_ID_SUCCESS,
                payload: data,
              });
              break;
            }
            default:
              dispatch({
                type: ProductActionTypes.FETCH_SUCCESS,
                payload: data,
              });
              break;
          }
        })
        .catch((error) => {
          dispatch({ type: ProductActionTypes.FETCH_ERROR, error: error });
        });
    }
  };

export const searchListCategories = (parentId?: string) => (dispatch: any) => {
  dispatch({ type: ProductActionTypes.FETCH_CATEGORY_REQUEST });
  fetch(process.env.REACT_APP_API_HOST + "/products/parent/" + parentId, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      dispatch({
        type: ProductActionTypes.FETCH_CATEGORY_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: ProductActionTypes.FETCH_ERROR, error: error });
    });
};

export const searchCategories = (parentId?: string) => (dispatch: any) => {
  dispatch({ type: ProductActionTypes.FETCH_LIST_REQUEST });
  fetch(process.env.REACT_APP_API_HOST + "/products/parent/" + parentId, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      dispatch({ type: ProductActionTypes.FETCH_LIST_SUCCESS, payload: data });
    })
    .catch((error) => {
      dispatch({ type: ProductActionTypes.FETCH_ERROR, error: error });
    });

  dispatch({ type: ProductActionTypes.FETCH_REQUEST });
  fetch(process.env.REACT_APP_API_HOST + "/products/category/" + parentId, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      dispatch({ type: ProductActionTypes.FETCH_SUCCESS, payload: data });
    })
    .catch((error) => {
      dispatch({ type: ProductActionTypes.FETCH_ERROR, error: error });
    });
};

export const searchTopCategories = () => (dispatch: any) => {
  dispatch({ type: ProductActionTypes.FETCH_MENU_REQUEST });
  fetch(process.env.REACT_APP_API_HOST + "/products/parent/", {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      dispatch({ type: ProductActionTypes.FETCH_MENU_SUCCESS, payload: data });
    })
    .catch((error) => {
      dispatch({ type: ProductActionTypes.FETCH_ERROR, error: error });
    });
};

export const searchProduct =
  (type?: string, id?: string) => (dispatch: any) => {
    const token: string = localStorage.getItem("token") || "";
    if (token) {
      dispatch({ type: ProductActionTypes.FETCH_ID_REQUEST });
      if (token) {
        let path = "";
        if (id) path = path + "/" + id;
        fetch(process.env.REACT_APP_API_HOST + "/products" + path, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((response) =>
            response.text().then(function (text) {
              return text ? JSON.parse(text) : {};
            })
          )
          .then((data) => {
            dispatch({
              type: ProductActionTypes.FETCH_ID_SUCCESS,
              payload: data,
            });
          })
          .catch((error) => {
            dispatch({ type: ProductActionTypes.SEARCH_ERROR, error: error });
          });
      }
    }
  };

export const searchText = (text: string) => (dispatch: any) => {
  const token: string = localStorage.getItem("token") || "";
  if (token) {
    dispatch({ type: ProductActionTypes.SEARCH_REQUEST });
    if (text.length >= 1) {
      fetch(process.env.REACT_APP_API_HOST + "/products/search", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          searchText: text,
        }),
      })
        .then((response) =>
          response.text().then(function (text) {
            return text ? JSON.parse(text) : {};
          })
        )
        .then((data) => {
          dispatch({ type: ProductActionTypes.SEARCH_SUCCESS, payload: data });
        })
        .catch((error) => {
          dispatch({ type: ProductActionTypes.FETCH_ERROR, error: error });
        });
    }
  }
};

export const getProductPrice = async (request: string): Promise<any> => {
  const token: string = localStorage.getItem("token") || "";
  if (token) {
    return await fetch(process.env.REACT_APP_API_HOST + "/products/price", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(request),
    }).then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    );
  }
};
