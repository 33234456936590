import { UserActionTypes } from "./types";
import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../index";
import { UserProfile } from "./../../models/types";

export type AppThunk = ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
>;

const callAPI =
  (
    uri: string,
    start: UserActionTypes,
    success: UserActionTypes,
    error: UserActionTypes
  ) =>
  (dispatch: any) => {
    dispatch({ type: start });
    fetch(process.env.REACT_APP_API_HOST + uri, {
      method: "get",
      headers: {
        Authorization: localStorage.getItem("token") || "",
      },
    })
      .then((response) =>
        response.text().then(function (text) {
          return text ? JSON.parse(text) : {};
        })
      )
      .then((data) => {
        dispatch({ type: success, payload: data });
      })
      .catch((error) => {
        dispatch({ type: error, error: error });
      });
  };

const callAPIRefresh =
  (
    uri: string,
    operation: string,
    data: any,
    start: UserActionTypes,
    success: UserActionTypes,
    error: UserActionTypes
  ) =>
  (dispatch: any) => {
    dispatch({ type: start });
    fetch(process.env.REACT_APP_API_HOST + uri, {
      method: operation,
      headers: {
        Authorization: localStorage.getItem("token") || "",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    })
      .then((response) =>
        response.text().then(function (text) {
          return text ? JSON.parse(text) : {};
        })
      )
      .then((data) => {
        dispatch({ type: success, payload: data });
      })
      .catch((errorMessage) => {
        dispatch({ type: error, error: errorMessage });
      });
  };

export const getUserCart = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/products/cart",
      UserActionTypes.CART_REQUEST,
      UserActionTypes.CART_SUCCESS,
      UserActionTypes.CART_ERROR
    )
  );
};

export const getUserInvoices = (payload: any) => (dispatch: any) => {
  dispatch(
    callAPIRefresh(
      "/users/profile/invoices",
      "post",
      payload,
      UserActionTypes.INVOICES_REQUEST,
      UserActionTypes.INVOICES_SUCCESS,
      UserActionTypes.INVOICES_ERROR
    )
  );
};

export const getUserOrders = (payload: any) => (dispatch: any) => {
  dispatch(
    callAPIRefresh(
      "/users/profile/orders",
      "post",
      payload,
      UserActionTypes.ORDERS_REQUEST,
      UserActionTypes.ORDERS_SUCCESS,
      UserActionTypes.ORDERS_ERROR
    )
  );
};

export const getUserContracts = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/contracts",
      UserActionTypes.CONTRACTS_REQUEST,
      UserActionTypes.CONTRACTS_SUCCESS,
      UserActionTypes.CONTRACTS_ERROR
    )
  );
};

export const getUserOrdersActive = (payload: any) => (dispatch: any) => {
  dispatch(
    callAPIRefresh(
      "/users/profile/orders/active",
      "post",
      payload,
      UserActionTypes.ORDERS_REQUEST,
      UserActionTypes.ORDERS_SUCCESS,
      UserActionTypes.ORDERS_ERROR
    )
  );
};

export const getEmployees = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/employees",
      UserActionTypes.EMPLOYEES_REQUEST,
      UserActionTypes.EMPLOYEES_SUCCESS,
      UserActionTypes.EMPLOYEES_ERROR
    )
  );
};

export const getGVP = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/gvp",
      UserActionTypes.GVP_REQUEST,
      UserActionTypes.GVP_SUCCESS,
      UserActionTypes.GVP_ERROR
    )
  );
};

export const getMyProducts = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/products",
      UserActionTypes.PRODUCTS_REQUEST,
      UserActionTypes.PRODUCTS_SUCCESS,
      UserActionTypes.PRODUCTS_ERROR
    )
  );
};

export const getGVPStatus = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/gvp/status",
      UserActionTypes.GVP_STATUS_REQUEST,
      UserActionTypes.GVP_STATUS_SUCCESS,
      UserActionTypes.GVP_STATUS_ERROR
    )
  );
};

export const setGVPEdit = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/gvp/edit",
      UserActionTypes.OBJECT_UPDATE_REQUEST,
      UserActionTypes.OBJECT_UPDATE_SUCCESS,
      UserActionTypes.OBJECT_UPDATE_ERROR
    )
  );
  setTimeout(function () {
    dispatch(getGVP());
  }, 1000);
};

export const getUserServices = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/service",
      UserActionTypes.SERVICE_REQUEST,
      UserActionTypes.SERVICE_SUCCESS,
      UserActionTypes.SERVICE_ERROR
    )
  );
};

export const getUserProjects = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/project",
      UserActionTypes.PROJECTS_REQUEST,
      UserActionTypes.PROJECTS_SUCCESS,
      UserActionTypes.PROJECTS_ERROR
    )
  );
};

export const getUserInboxDetails = (messageFlowId: string) => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/inbox/" + messageFlowId,
      UserActionTypes.INBOX_DETAILS_REQUEST,
      UserActionTypes.INBOX_DETAILS_SUCCESS,
      UserActionTypes.INBOX_DETAILS_ERROR
    )
  );
};

export const getUserInbox = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/inbox",
      UserActionTypes.INBOX_REQUEST,
      UserActionTypes.INBOX_SUCCESS,
      UserActionTypes.INBOX_ERROR
    )
  );
};

export const getUserTransport = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/transport",
      UserActionTypes.TRANSPORT_REQUEST,
      UserActionTypes.TRANSPORT_SUCCESS,
      UserActionTypes.TRANSPORT_ERROR
    )
  );
};

export const getUserProfile = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile",
      UserActionTypes.PROFILE_REQUEST,
      UserActionTypes.PROFILE_SUCCESS,
      UserActionTypes.PROFILE_ERROR
    )
  );
};

export const getUserOrderDetails = (orderId: string) => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/orders/" + orderId,
      UserActionTypes.ORDER_DETAILS_REQUEST,
      UserActionTypes.ORDER_DETAILS_SUCCESS,
      UserActionTypes.ORDER_DETAILS_ERROR
    )
  );
};

export const getProductDetails = (orderId: string) => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/products/" + orderId,
      UserActionTypes.PRODUCT_DETAILS_REQUEST,
      UserActionTypes.PRODUCT_DETAILS_SUCCESS,
      UserActionTypes.PRODUCT_DETAILS_ERROR
    )
  );
};

export const getOrderStatusList = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/orderStatusList",
      UserActionTypes.ORDER_STATUS_REQUEST,
      UserActionTypes.ORDER_STATUS_SUCCESS,
      UserActionTypes.ORDER_STATUS_ERROR
    )
  );
};

export const getEmployeeList = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/users/profile/employeeList",
      UserActionTypes.EMPLOYEE_LIST_REQUEST,
      UserActionTypes.EMPLOYEE_LIST_SUCCESS,
      UserActionTypes.EMPLOYEE_LIST_ERROR
    )
  );
};

export const loginUser = (userData: any) => (dispatch: any) => {
  dispatch({ type: UserActionTypes.LOGIN_REQUEST });
  fetch(process.env.REACT_APP_API_HOST + "/auth/login", {
    method: "post",
    headers: {
      Authorization: localStorage.getItem("token") || "",
    },
    body: JSON.stringify({
      ...userData,
    }),
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      const token = `Bearer ${data.token}`;
      localStorage.setItem("token", token);
      dispatch(getUserData());
      dispatch({ type: UserActionTypes.LOGIN_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.LOGIN_ERROR, error: error });
    });
};

export const loginSmartId = (userData: any) => (dispatch: any) => {
  dispatch(
    callAPI(
      "/smartid/auth/ssn/" + userData.country + "/" + userData.ssn,
      UserActionTypes.SMART_REQUEST,
      UserActionTypes.SMART_SUCCESS,
      UserActionTypes.SMART_ERROR
    )
  );
};

export const signSmartId = () => (dispatch: any) => {
  dispatch(
    callAPI(
      "/smartid/auth/sign",
      UserActionTypes.SMART_REQUEST,
      UserActionTypes.SMART_SUCCESS,
      UserActionTypes.SMART_ERROR
    )
  );
};

export const registerSmartId = (userData: any) => (dispatch: any) => {
  dispatch(
    callAPI(
      "/smartid/register/ssn/" + userData.country + "/" + userData.ssn,
      UserActionTypes.SMART_REQUEST,
      UserActionTypes.SMART_SUCCESS,
      UserActionTypes.SMART_ERROR
    )
  );
};

export const loginSmartIdStatus =
  (hash: any, cookie: any, setCookie: any) => (dispatch: any) => {
    fetch(process.env.REACT_APP_API_HOST + "/smartid/status/" + hash, {
      method: "get",
      headers: {
        Authorization: localStorage.getItem("token") || "",
      },
    })
      .then((response) =>
        response.text().then(function (text) {
          return text ? JSON.parse(text) : {};
        })
      )
      .then((data) => {
        if (data.status === "SUCCESS") {
          dispatch({
            type: UserActionTypes.SMART_SUCCESS,
            payload: { status: data.status },
          });
          const token = `Bearer ${data.token}`;
          localStorage.setItem("token", token);
          setCookie("statsrent", token);
          dispatch(getUserData());
          dispatch({ type: UserActionTypes.LOGIN_SUCCESS });
        }
        if (data.status === "ERROR") {
          dispatch({
            type: UserActionTypes.SMART_ERROR,
            payload: { status: data.status, error: data.error },
          });
        }
      })
      .catch((error) => {
        dispatch({ type: UserActionTypes.LOGIN_ERROR, error: error });
      });
  };

export const loginEmployee =
  (userData: any, setCookie: any) => (dispatch: any) => {
    fetch(process.env.REACT_APP_API_HOST + "/auth/login", {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token") || "",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        ...userData,
      }),
    })
      .then((response) =>
        response.text().then(function (text) {
          return text ? JSON.parse(text) : {};
        })
      )
      .then((data) => {
        if (data.token) {
          const token = `Bearer ${data.token}`;
          localStorage.setItem("token", token);
          setCookie("statsrent", token);
          dispatch(getUserData());
          dispatch({ type: UserActionTypes.LOGIN_SUCCESS });
        } else {
          dispatch({
            type: UserActionTypes.LOGIN_ERROR,
            payload: "AUTH_ERROR",
          });
        }
      })
      .catch((error) => {
        dispatch({ type: UserActionTypes.LOGIN_ERROR, error: error });
      });
  };

export const loginSmartIdClean = () => (dispatch: any) => {
  dispatch({ type: UserActionTypes.SMART_SUCCESS, payload: {} });
};

export const registerSmartIdStatus = (hash: any) => (dispatch: any) => {
  fetch(process.env.REACT_APP_API_HOST + "/smartid/register/" + hash, {
    method: "get",
    headers: {
      Authorization: localStorage.getItem("token") || "",
    },
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      if (data.status === "SUCCESS") {
        dispatch({ type: UserActionTypes.SMART_SUCCESS, payload: data });
      }
      if (data.status === "ERROR") {
        dispatch({
          type: UserActionTypes.SMART_ERROR,
          payload: { status: data.status, error: data.error },
        });
      }
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.SMART_ERROR, error: error });
    });
};

export const registerParty = (payload: any) => (dispatch: any) => {
  fetch(process.env.REACT_APP_API_HOST + "/users/register", {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem("token") || "",
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      if (data.status === "SUCCESS") {
        dispatch({ type: UserActionTypes.REGISTER_SUCCESS });
      }
      if (data.status === "ERROR") {
        dispatch({
          type: UserActionTypes.REGISTER_ERROR,
          payload: data.message,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.REGISTER_ERROR, error: error });
    });
};

export const registerSmartIdClean = () => (dispatch: any) => {
  dispatch({ type: UserActionTypes.SMART_SUCCESS, payload: {} });
};

export const updateCompany = (payload: any) => (dispatch: any) => {
  dispatch({ type: UserActionTypes.COMPANY_UPDATE_REQUEST });
  fetch(process.env.REACT_APP_API_HOST + "/users/profile/company", {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem("token") || "",
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      dispatch({ type: UserActionTypes.COMPANY_UPDATE_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.COMPANY_UPDATE_ERROR, error: error });
    });
};

export const register = (payload: any) => (dispatch: any) => {
  dispatch({ type: UserActionTypes.REGISTER_REQUEST });
  fetch(process.env.REACT_APP_API_HOST + "/users/register", {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem("token") || "",
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      if (data.status === "SUCCESS") {
        dispatch({ type: UserActionTypes.REGISTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: UserActionTypes.REGISTER_ERROR, error: data.message });
      }
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.REGISTER_ERROR, error: error });
    });
};

export const updateGVP = (payload: any) => (dispatch: any) => {
  dispatch(
    callAPIRefresh(
      "/users/gvp",
      "post",
      payload,
      UserActionTypes.OBJECT_UPDATE_REQUEST,
      UserActionTypes.OBJECT_UPDATE_SUCCESS,
      UserActionTypes.OBJECT_UPDATE_ERROR
    )
  );
  setTimeout(function () {
    dispatch(getGVP());
  }, 1000);
};

export const updateEmployee = (payload: any) => (dispatch: any) => {
  dispatch(
    callAPIRefresh(
      "/users/profile/employee",
      "post",
      payload,
      UserActionTypes.OBJECT_UPDATE_REQUEST,
      UserActionTypes.OBJECT_UPDATE_SUCCESS,
      UserActionTypes.OBJECT_UPDATE_ERROR
    )
  );
  setTimeout(function () {
    dispatch(getUserData());
  }, 2000);
};

export const deleteEmployee = (id?: number) => (dispatch: any) => {
  dispatch(
    callAPIRefresh(
      "/users/profile/employee/" + id || "",
      "delete",
      "",
      UserActionTypes.OBJECT_UPDATE_REQUEST,
      UserActionTypes.OBJECT_UPDATE_SUCCESS,
      UserActionTypes.OBJECT_UPDATE_ERROR
    )
  );
  setTimeout(function () {
    dispatch(getEmployees());
  }, 1000);
};

export const updateProject = (payload: any) => (dispatch: any) => {
  dispatch(
    callAPIRefresh(
      "/users/profile/project",
      "post",
      payload,
      UserActionTypes.OBJECT_UPDATE_REQUEST,
      UserActionTypes.OBJECT_UPDATE_SUCCESS,
      UserActionTypes.OBJECT_UPDATE_ERROR
    )
  );
  setTimeout(function () {
    dispatch(getUserProjects());
  }, 1000);
};

export const deleteProject = (id?: number) => (dispatch: any) => {
  dispatch(
    callAPIRefresh(
      "/users/profile/project/" + id || "",
      "delete",
      "",
      UserActionTypes.OBJECT_UPDATE_REQUEST,
      UserActionTypes.OBJECT_UPDATE_SUCCESS,
      UserActionTypes.OBJECT_UPDATE_ERROR
    )
  );
  setTimeout(function () {
    dispatch(getUserProjects());
  }, 1000);
};

export const saveProduct =
  (payload: any, formData: FormData) => (dispatch: any) => {
    const token: string = localStorage.getItem("token") || "";
    dispatch({ type: UserActionTypes.OBJECT_UPDATE_REQUEST });
    fetch(process.env.REACT_APP_API_HOST + "/users/profile/products", {
      method: "post",
      headers: {
        "Content-type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        ...payload,
      }),
    })
      .then((response) =>
        response.text().then(function (text) {
          return text ? JSON.parse(text) : {};
        })
      )
      .then((data) => {
        fetch(
          process.env.REACT_APP_API_HOST +
            "/users/profile/products/" +
            data?.id +
            "/images",
          {
            method: "post",
            headers: {
              Authorization: token,
            },
            body: formData,
          }
        );
        dispatch({ type: UserActionTypes.OBJECT_UPDATE_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: UserActionTypes.OBJECT_UPDATE_ERROR, error: error });
      });

    setTimeout(function () {
      dispatch(getMyProducts());
    }, 1000);
  };

export const deleteProduct = (id?: string) => (dispatch: any) => {
  dispatch(
    callAPIRefresh(
      "/users/profile/products/" + id || "",
      "delete",
      "",
      UserActionTypes.OBJECT_UPDATE_REQUEST,
      UserActionTypes.OBJECT_UPDATE_SUCCESS,
      UserActionTypes.OBJECT_UPDATE_ERROR
    )
  );
  setTimeout(function () {
    dispatch(getMyProducts());
  }, 1000);
};

export const deleteProductAvailability =
  (productId?: string, id?: string) => (dispatch: any) => {
    dispatch(
      callAPIRefresh(
        "/users/profile/products/" + productId + "/availability/" + id || "",
        "delete",
        "",
        UserActionTypes.OBJECT_UPDATE_REQUEST,
        UserActionTypes.OBJECT_UPDATE_SUCCESS,
        UserActionTypes.OBJECT_UPDATE_ERROR
      )
    );
    setTimeout(function () {
      dispatch(getMyProducts());
    }, 1000);
  };

export const deleteProductImage =
  (productId?: string, id?: string) => (dispatch: any) => {
    dispatch(
      callAPIRefresh(
        "/users/profile/products/" + productId + "/image/" + id || "",
        "delete",
        "",
        UserActionTypes.OBJECT_UPDATE_REQUEST,
        UserActionTypes.OBJECT_UPDATE_SUCCESS,
        UserActionTypes.OBJECT_UPDATE_ERROR
      )
    );
    setTimeout(function () {
      dispatch(getMyProducts());
    }, 1000);
  };
  
export const getUserData = () => (dispatch: any) => {
  dispatch({ type: UserActionTypes.FETCH_REQUEST });
  const token: string = localStorage.getItem("token") || "";
  fetch(process.env.REACT_APP_API_HOST + "/users/me", {
    method: "get",
    headers: {
      Authorization: localStorage.getItem("token") || "",
    },
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      if (data.statusCode === 401) {
        getStatus(null, null);
        return;
      }
      dispatch({
        type: UserActionTypes.FETCH_SUCCESS,
        payload: { token: token, ...data },
      });
      if (data.id) {
        dispatch({ type: UserActionTypes.LOGIN_SUCCESS });
        dispatch(getUserProfile());
        dispatch(getUserCart());
        dispatch(getUserOrders({}));
        dispatch(getUserProjects());
        dispatch(getUserServices());
        dispatch(getUserTransport());
        dispatch(getUserInvoices({}));
        dispatch(getEmployees());
      }
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.FETCH_ERROR, error: error });
    });
};

export const getStatus = (cookie: any, setCookie: any) => (dispatch: any) => {
  dispatch({ type: UserActionTypes.STATUS_REQUEST });
  if (cookie.statsrent) {
    localStorage.setItem("token", cookie.statsrent);
    dispatch(getUserData());
  } else {
    fetch(process.env.REACT_APP_API_HOST + "/status", {
      method: "get",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((response) =>
        response.text().then(function (text) {
          return text ? JSON.parse(text) : {};
        })
      )
      .then((data) => {
        const token = `Bearer ${data.token}`;
        localStorage.setItem("token", token);
        setCookie("statsrent", token);
      })
      .then(() => {
        dispatch(getUserData());
      })
      .catch((error) => {
        dispatch({ type: UserActionTypes.STATUS_ERROR, error: error });
      });
  }
};

export const getUserProfileSync = () => (dispatch: UserProfile) => {
  const token: string = localStorage.getItem("token") || "";
  fetch(process.env.REACT_APP_API_HOST + "/users/", {
    method: "get",
    headers: {
      Authorization: token,
    },
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      return dispatch;
    })
    .catch((error) => {});
};

export const setMessageRead = (messageFlowId: string) => (dispatch: any) => {
  const token: string = localStorage.getItem("token") || "";
  fetch(process.env.REACT_APP_API_HOST + "/users/profile/inbox/read/" + messageFlowId, {
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: token,
    }
  })
    .then(() => {
      dispatch(getUserInbox());
    })
};

export const addCart = (payload: any) => (dispatch: any) => {
  const token: string = localStorage.getItem("token") || "";
  dispatch({ type: UserActionTypes.CART_REQUEST });
  fetch(process.env.REACT_APP_API_HOST + "/users/addCart", {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then(() => {
      dispatch(getUserCart());
      dispatch({ type: UserActionTypes.CART_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.CART_ERROR, error: error });
    });
};

export const setCartItemQuantityUp = (cartId?: number) => (dispatch: any) => {
  if (cartId) {
    const token: string = localStorage.getItem("token") || "";
    fetch(process.env.REACT_APP_API_HOST + "/users/cart/" + cartId + "/add", {
      method: "get",
      headers: {
        "Content-type": "application/json",
        Authorization: token,
      },
    }).then(() => {
      dispatch(getUserCart());
    });
  }
};

export const setCartItemQuantityDown = (cartId?: number) => (dispatch: any) => {
  if (cartId) {
    const token: string = localStorage.getItem("token") || "";
    fetch(
      process.env.REACT_APP_API_HOST + "/users/cart/" + cartId + "/remove",
      {
        method: "get",
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
      }
    ).then(() => {
      dispatch(getUserCart());
    });
  }
};

export const deleteCartItem = (id?: number) => (dispatch: any) => {
  if (id) {
    const token: string = localStorage.getItem("token") || "";
    fetch(
      process.env.REACT_APP_API_HOST + "/users/deleteCartItem/" + id || "",
      {
        method: "delete",
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
      }
    ).then(() => {
      dispatch(getUserCart());
    });
  }
};

export const saveService =
  (payload: any, formData: FormData) => (dispatch: any) => {
    const token: string = localStorage.getItem("token") || "";
    fetch(process.env.REACT_APP_API_HOST + "/users/service", {
      method: "post",
      headers: {
        "Content-type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        ...payload,
      }),
    })
      .then((response) =>
        response.text().then(function (text) {
          return text ? JSON.parse(text) : {};
        })
      )
      .then((data) => {
        fetch(
          process.env.REACT_APP_API_HOST +
            "/users/profile/service/" +
            data?.id +
            "/images",
          {
            method: "post",
            headers: {
              Authorization: token,
            },
            body: formData,
          }
        );
        dispatch({ type: UserActionTypes.OBJECT_UPDATE_SUCCESS });
      })
      .then(() => {
        dispatch(getUserServices());
      })
      .catch((error) => {
        dispatch({ type: UserActionTypes.SERVICE_ERROR, error: error });
      });
  };

export const saveTransport = (payload: any) => (dispatch: any) => {
  const token: string = localStorage.getItem("token") || "";
  fetch(process.env.REACT_APP_API_HOST + "/users/profile/transport", {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then(() => {
      dispatch(getUserTransport());
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.SERVICE_ERROR, error: error });
    });
};

export const saveNewMessage = (payload: any) => (dispatch: any) => {
  const token: string = localStorage.getItem("token") || "";
  fetch(process.env.REACT_APP_API_HOST + "/users/profile/inbox", {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then(() => {
      dispatch(getUserInbox());
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.SERVICE_ERROR, error: error });
    });
};

export const saveMessage = (payload: any, messageFlowId: string) => (dispatch: any) => {
  const token: string = localStorage.getItem("token") || "";
  fetch(process.env.REACT_APP_API_HOST + "/users/profile/inbox/" + messageFlowId, {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then(() => {
      dispatch(getUserInboxDetails(messageFlowId));
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.SERVICE_ERROR, error: error });
    });
};

export const submitCart = (payload: any) => (dispatch: any) => {
  const token: string = localStorage.getItem("token") || "";
  dispatch({ type: UserActionTypes.CART_REQUEST });
  fetch(process.env.REACT_APP_API_HOST + "/users/submitCart", {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then(() => {
      dispatch(getUserData());
      dispatch(getUserCart());
      dispatch({ type: UserActionTypes.CART_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.CART_ERROR, error: error });
    });
};

export const logoutUser = (setCookie: any) => (dispatch: any) => {
  fetch(process.env.REACT_APP_API_HOST + "/status", {
    method: "get",
    headers: {
      "Content-type": "application/json",
    },
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      const token = `Bearer ${data.token}`;
      localStorage.setItem("token", token);
      setCookie("statsrent", token);
      dispatch({ type: UserActionTypes.LOGOUT_SUCCESS });
    })
    .then(() => {
      dispatch(getUserData());
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.STATUS_ERROR, error: error });
    });
};

export const switchUser =
  (setCookie: any, companyId: string) => (dispatch: any) => {
    fetch(process.env.REACT_APP_API_HOST + "/auth/switchUser/" + companyId, {
      method: "get",
      headers: {
        Authorization: localStorage.getItem("token") || "",
        "Content-type": "application/json",
      },
    })
      .then((response) =>
        response.text().then(function (text) {
          return text ? JSON.parse(text) : {};
        })
      )
      .then((data) => {
        const token = `Bearer ${data.token}`;
        localStorage.setItem("token", token);
        setCookie("statsrent", token);
        dispatch({ type: UserActionTypes.LOGIN_SUCCESS });
      })
      .then(() => {
        dispatch(getUserData());
      })
      .catch((error) => {
        dispatch({ type: UserActionTypes.STATUS_ERROR, error: error });
      });
  };

export const calculateDelivery = (payload: any) => (dispatch: any) => {
  fetch(process.env.REACT_APP_API_HOST + "/users/calculateDelivery", {
    method: "post",
    headers: {
      Authorization: localStorage.getItem("token") || "",
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    )
    .then((data) => {
      dispatch({ type: UserActionTypes.DELIVERY_SUCCESS, payload: data });
    })
    .catch((error) => {
      dispatch({ type: UserActionTypes.DELIVERY_ERROR, error: error });
    });
};

export const subscribeMarketing = (payload: any) => (dispatch: any) => {
  fetch(process.env.REACT_APP_API_HOST + "/users/subscribe", {
    method: "post",
    headers: {
      Authorization: localStorage.getItem("token") || "",
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      ...payload,
    }),
  }).then((response) =>
    response.text().then(function (text) {
      return text ? JSON.parse(text) : {};
    })
  );
};
