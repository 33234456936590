
import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";
import { getProductPrice } from "../store/products/action";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        price: {
            marginBottom: 5,
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#da2232',
        },
        priceVAT: {
            fontSize: '11px',
            color: '#c2c2c2',
        },
    }),
);

interface IProps {
    code: string,
    startDate: string,
    endDate: string
}

const ProductPriceCalculator: React.FC<IProps> = ({
    code,
    startDate,
    endDate
}) => {
    const [price, setPrices] = React.useState<any>({});
    useEffect(() => {
        async function getProductPrices(request: any) {
            let finalPrice = await getProductPrice(request);
            setPrices(finalPrice);
        }

        if (code && startDate && endDate) {
            let request = { code: code, startDate: startDate, endDate: endDate }
            getProductPrices(request);
        }
    }, [code, startDate, endDate]);

    const classes = useStyles();

    return (
        price.code ? (
            <Box textAlign="center" pt={2} pb={3}>
                <span className={classes.price}>
                    €{parseFloat(price.price).toFixed(2)}
                </span>&nbsp;
                <span className={classes.priceVAT}>
                    {parseFloat(price.priceVAT).toFixed(2)}
                    <FormattedMessage id="products.withVAT" />
                </span>
            </Box>
        ) : (null)
    );
};

export default (ProductPriceCalculator);
