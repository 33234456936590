import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@material-ui/core";
import LocalHelmet from "./Helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    largeTitle: {
      paddingBottom: 15,
      fontWeight: "bold",
      fontSize: "25px",
    },
    text: {
      fontSize: "16px",
      lineHeight: "25px",
      textAlign: "left",
    },
    banner: {
      width: "100%",
    },
    about: {
      textAlign: "left",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    },
    accordion: {
      borderRadius: "0 !important",
    },
    accordionSummary: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    accordionDetails: {
      borderRadius: "0 !important",
      paddingBottom: "20px",
      paddingLeft: "30px",
    },
    accordionDetailsOpen: {
      borderRadius: "0 !important",
      boxShadow: "none",
      padding: "0px",
      paddingBottom: "20px",
    },
    link: {
      color: "#da2232",
      fontSize: "16px",
      fontWeight: "bold",
      textDecoration: "underline"
    },
    title: {
      color: "#000000",
      fontSize: "20px",
      fontWeight: "bold",
    },
    bannerBox: {
      textAlign: "center",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    },
  })
);

const Rent: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const classes = useStyles();
  const intl = useIntl();

  const handlePanelChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box alignItems="center" display="block">
      <LocalHelmet title={intl.formatMessage({ id: "rent.title" })} />
      <img
        src="/images/rent/rent.png"
        className={classes.banner}
        alt={intl.formatMessage({ id: "rent.title" })}
      />
      <Box className={classes.about}>
        <Grid container className={classes.about} spacing={9}>
          <Grid item xs={12} sm={12}>
            <Box pt={1} className={classes.text}>
              <Box className={classes.title}>
                <FormattedMessage id="rent.1"/>
              </Box>
              <Box className={classes.bannerBox}>
                <img
                  src="/images/howto.png"
                  style={{ maxWidth: "50%" }}
                  alt={intl.formatMessage({ id: "rent.title" })}
                />
              </Box>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "1"}
                onChange={handlePanelChange("1")}
              >
                <AccordionSummary className={classes.accordionSummary} id="1">
                  <FormattedMessage id="rent.3" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "1"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.4" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "2"}
                onChange={handlePanelChange("2")}
              >
                <AccordionSummary className={classes.accordionSummary} id="2">
                  <FormattedMessage id="rent.5" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "2"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.6" />
                    <ul>
                      <li>
                        <FormattedMessage id="rent.7" />
                      </li>
                      <li>
                        <FormattedMessage id="rent.8" />
                      </li>
                      <li>
                        <FormattedMessage id="rent.9" />
                      </li>
                      <li>
                        <FormattedMessage id="rent.10" />
                      </li>
                    </ul>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "3"}
                onChange={handlePanelChange("3")}
              >
                <AccordionSummary className={classes.accordionSummary} id="3">
                  <FormattedMessage id="rent.12" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "3"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.13" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "4"}
                onChange={handlePanelChange("4")}
              >
                <AccordionSummary className={classes.accordionSummary} id="4">
                  <FormattedMessage id="rent.14" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "4"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.15" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "5"}
                onChange={handlePanelChange("5")}
              >
                <AccordionSummary className={classes.accordionSummary} id="5">
                  <FormattedMessage id="rent.16" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "5"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.17" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "6"}
                onChange={handlePanelChange("6")}
              >
                <AccordionSummary className={classes.accordionSummary} id="6">
                  <FormattedMessage id="rent.18" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "6"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.19" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "7"}
                onChange={handlePanelChange("7")}
              >
                <AccordionSummary className={classes.accordionSummary} id="7">
                  <FormattedMessage id="rent.20" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "7"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.21" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "8"}
                onChange={handlePanelChange("8")}
              >
                <AccordionSummary className={classes.accordionSummary} id="8">
                  <FormattedMessage id="rent.22" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "8"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.23" />
                    <Link to="/kontakti" className={classes.link}>
                      <FormattedMessage id="rent.37" />
                    </Link>
                    <FormattedMessage id="rent.38" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "9"}
                onChange={handlePanelChange("9")}
              >
                <AccordionSummary className={classes.accordionSummary} id="9">
                  <FormattedMessage id="rent.24" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "9"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.25" />
                    <Link to="/transports" className={classes.link}>
                      <FormattedMessage id="rent.26" />
                    </Link>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "10"}
                onChange={handlePanelChange("10")}
              >
                <AccordionSummary className={classes.accordionSummary} id="10">
                  <FormattedMessage id="rent.27" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "10"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.28" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "11"}
                onChange={handlePanelChange("11")}
              >
                <AccordionSummary className={classes.accordionSummary} id="11">
                  <FormattedMessage id="rent.29" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "11"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.30" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "12"}
                onChange={handlePanelChange("12")}
              >
                <AccordionSummary className={classes.accordionSummary} id="12">
                  <FormattedMessage id="rent.31" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "12"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.32" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "13"}
                onChange={handlePanelChange("13")}
              >
                <AccordionSummary className={classes.accordionSummary} id="13">
                  <FormattedMessage id="rent.33" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "13"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <FormattedMessage id="rent.34" />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordion}
                expanded={expanded === "14"}
                onChange={handlePanelChange("14")}
              >
                <AccordionSummary className={classes.accordionSummary} id="14">
                  <FormattedMessage id="rent.36" />
                </AccordionSummary>
                <AccordionDetails
                  className={
                    expanded === "14"
                      ? classes.accordionDetailsOpen
                      : classes.accordionDetails
                  }
                >
                  <Box pl={3}>
                    <Grid container>
                      <Grid item xs={12} sm={12} alignContent="center">
                        <Box textAlign="center">
                          <Link to="/documents/Nomas_ligums_TMPL.doc" target="_blank" className={classes.link}><img src="images/word.png" alt="Nomas ligums" width="40px" className={classes.link}/>
                                <br/>
                                Nomas līgums
                          </Link>
                        </Box>
                      </Grid> 
                      <Grid item xs={12} sm={12}>
                        <Box textAlign="center" pt={3}>
                          <Link to="/documents/Pilnvara_nomai_2024_STATS_RENT.doc" target="_blank" className={classes.link}>
                            <img src="images/word.png" alt="Pilnvara nomai" width="40px" />
                            <br/>
                            Pilnvara nomai
                          </Link>
                        </Box>
                      </Grid> 
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {};
};

export default connect(null, mapDispatchToProps)(Rent);
