import React, { ChangeEvent, useEffect, useState } from "react";
import { SelectData, UserProfile } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Button, Checkbox, Grid, Hidden, Popover, Typography } from "@material-ui/core";
import { getLink } from "./../store/payment/action";
import { FormattedMessage, useIntl } from "react-intl";
import ProfileNoRows from "./ProfileNoRows";
import ProfileDocumentViewer from "./ProfileDocumentViewer";
import FilterListIcon from '@material-ui/icons/FilterList';
import FileDownloadIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InputTextField from "./TextField";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { lv } from "date-fns/locale";
import { getDay } from "date-fns";
import InputSelectField from "./SelectField";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getEmployeeList, getUserInvoices } from "../store/users/action";
import { connect } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 2,
      height: "40px",
      fontSize: "16px",
      paddingTop: "8px",
      weight: 400,
      color: "#333333",
      textAlign: "left",
      textDecoration: "underline",
    },
    titleBig: {
      marginBottom: 2,
      height: "auto",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "31px",
      paddingTop: "10px",
      paddingBottom: "10px",
      color: "#333333",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    middle: {
      textAlign: "center",
    },
    button: {
      width: "100px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    tableHeader: {
      borderBottom: "1px solid #E3E3E3",
      padding: "0px",
    },
    tableTitle: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#858585",
    },
    tableText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      padding: "6px",
    },
    tableTextDue: {
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "#da2232",
      padding: "6px",
      fontWeight: 700,
    },
    row: {
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    filter: {
      color: "#EC574E",
      fontSize: "22px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    edit: {
      color: "#000000",
      fontSize: "35px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    red: {
      color: "#EC574E",
      fontSize: "35px",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    search: {
      maxWidth: "600px",
      minHeight: "500px",
      padding: "10px",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    divider: {
      padding: "10px",
    },
    checkbox: {
      color: "#333333",
    },
    inputInput: {
      padding: theme.spacing(2, 1.5, 1.5, 0),
      transition: theme.transitions.create("width"),
      paddingLeft: `calc(0.1em + ${theme.spacing(1)}px)`,
      width: "94%",
      borders: "0px",
      background: "#ffffff",
      borderTop: "0px",
      borderRight: "0px",
      borderLeft: "0px",
      fontSize: "16px",
      color: "#333333",
      borderBottomColor: "1px solid #e3e3e3",
    },
  })
);

interface PropsFromState {
  employeeList: SelectData[];
  employeeListLoading: boolean;
}


interface propsFromDispatch {
  getUserInvoices: (payload: any) => any;
  getEmployeeList: () => any;
}

interface IProps {
  profile: UserProfile;
  loading: boolean;
}

type AllProps = IProps & PropsFromState & propsFromDispatch;

registerLocale("lv", {
  ...lv,
  options: {
    weekStartsOn: 1,
    lastDayOfWeek: 0,
    firstWeekContainsDate: 1,
  },
});

const isWeekday = (date: Date) => {
  const day: number = getDay(date);
  return day !== 0;
};

const ProfileInvoices: React.FC<AllProps> = ({ 
  loading, 
  profile,
  employeeList,
  employeeListLoading,
  getUserInvoices,
  getEmployeeList 
}) => {
  useEffect(() => {
    getEmployeeList();
    getUserInvoices({});
  }, []);

  const classes = useStyles();
  const intl = useIntl();
  const [isDocumentOpen, handleDocument] = useState(false);
  const [documentName, setDocumentName] = useState("");


  const makePayment = async (invoice: string) => {
    let link: any = await getLink(invoice);
    if (link.status === "initial") window.location = link.link;
  };

  const popupHandler = () => {
    handleDocument((current) => !current);
  };

  const openDocument = (documentName: string) => {
    setDocumentName(documentName);
    popupHandler();
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;
    setFilterState({
      ...filterState,
      [name]: value,
    });
  };

  const [filterState, setFilterState] = useState<any>({});
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value, type } = e.currentTarget;
    const setValue = type === "checkbox" ? checked : value;
    let filter = {
      ...filterState,
      [name]: setValue,
    };
    setFilterState({ ...filter });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  
  const handleFilterOpen = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    let filter = {
      startDate: startDate,
      endDate: endDate,
      amountFrom: filterState.amountFrom,
      amountTo: filterState.amountTo,
      invoiceNumber: filterState.invoice,
      payed: filterState.payed
    }
    handleFilterClose();
    console.log(filter)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  return (
    <Grid container direction="row" justify="center">
      <Grid md={12} xs={12} className={classes.titleBig}>
        <FormattedMessage id="profile.myBills" />
          &nbsp;&nbsp;
          <FilterListIcon
                      className={classes.filter}
                      onClick={handleFilterOpen}
                    />
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleFilterClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      style={{
                          overflow: "hidden !important",
                          height: "600px",
                      }}
                    >
                      <Grid container className={classes.search}>
                        <Grid item md={12}>
                          <Typography className={classes.titleBig}>
                            <FormattedMessage id="profile.search" />
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.divider}>
                          <InputTextField
                            name="orderAmountFrom"
                            title={intl.formatMessage({ id: "search.amountFrom" })}
                            value={filterState?.orderAmountFrom || ""}
                            enabled={true}
                            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                              handleFormChange(ev)
                            }
                          />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.divider}>
                          <InputTextField
                            name="orderAmountTo"
                            title={intl.formatMessage({ id: "search.amountTo" })}
                            value={filterState?.orderAmountTo || ""}
                            enabled={true}
                            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                              handleFormChange(ev)
                            }
                          />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.divider}>
                          <InputTextField
                            name="orderNumber"
                            title={intl.formatMessage({ id: "profile.orderNumber" })}
                            value={filterState?.orderNumber || ""}
                            enabled={true}
                            formChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                              handleFormChange(ev)
                            }
                          />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.divider}>
                            {!employeeListLoading ? (
                              <InputSelectField
                                name="orderEmployee"
                                title={intl.formatMessage({ id: "profile.user" })}
                                list={employeeList}
                                value={filterState?.orderEmployee || ""}
                                formChange={handleSelectChange}
                              />
                            ) : null}
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.divider}>
                          <DatePicker
                            selected={
                              startDate
                                ? moment(startDate, "yyyy-MM-DD").toDate()
                                : undefined
                            }
                            onChange={(date: Date) => setStartDate(date)}
                            selectsStart
                            locale={lv}
                            filterDate={isWeekday}
                            dateFormat="dd.MM.yyyy"
                            placeholderText={intl.formatMessage({ id: "search.dateFrom" })}
                            monthsShown={1}
                            className={classes.inputInput}
                          />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.divider}>
                          <DatePicker
                            selected={
                              endDate
                                ? moment(endDate, "yyyy-MM-DD").toDate()
                                : undefined
                            }
                            onChange={(date: Date) => setEndDate(date)}
                            selectsStart
                            locale={lv}
                            minDate={startDate || moment().toDate()}
                            filterDate={isWeekday}
                            dateFormat="dd.MM.yyyy"
                            placeholderText={intl.formatMessage({ id: "search.dateTo" })}
                            monthsShown={1}
                            className={classes.inputInput}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Checkbox
                            checked={filterState?.payed}
                            onChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                              handleFormChange(ev)
                            }
                            name="payed"
                            id="payed"
                            className={classes.checkbox}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />{" "}
                          <FormattedMessage id="search.payed" />
                        </Grid>
                        <Grid md={12} xs={12} style={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            size="small"
                            className={classes.button}
                            onClick={() => handleSearch()}
                          >
                            <FormattedMessage id="search" />
                          </Button>
                        </Grid>
                      </Grid>
                    </Popover>
      </Grid>
      {profile.invoices.length > 0 ? (
        <Hidden smDown>
          <Grid md={12} xs={12}>
            <Grid container className={classes.tableHeader}>
              <Grid item md={1}>
                <Typography className={classes.tableTitle}>
                  <FormattedMessage id="transportForm.date" />
                </Typography>
              </Grid>
              <Grid item md={1}>
                <Typography className={classes.tableTitle}>
                  <FormattedMessage id="profile.paymentTerms" />
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className={classes.tableTitle}>
                  <FormattedMessage id="profile.orderNumber" />
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className={classes.tableTitle}>
                  <FormattedMessage id="profile.summary" />
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className={classes.tableTitle}>
                  <FormattedMessage id="profile.debt" />
                </Typography>
              </Grid>
              <Grid item md={1}>
                <Typography className={classes.tableTitle}>
                  <FormattedMessage id="profile.days" />
                </Typography>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </Grid>
        </Hidden>
      ) : null}
      {!loading ? (
        <>
          <ProfileDocumentViewer
            fileName={documentName}
            isDocumentOpen={isDocumentOpen}
            closeHandler={popupHandler}
          />
        </>
      ) : null}
      {profile.invoices.length > 0 ? (
        profile.invoices?.map((invoices, index) => (
          <Grid xs={12} md={12}>
            <Grid container className={classes.row}>
              <Grid item className={classes.tableText} 
                md={1} xs={6}>
                {invoices?.purchaseDate || ""}
              </Grid>
              <Hidden smDown>
                <Grid
                  item
                  className={
                    +invoices?.payDue < 0
                      ? classes.tableTextDue
                      : classes.tableText
                  }
                  md={1}
                >
                  {invoices?.payDueDate}
                </Grid>
              </Hidden>
              <Grid item 
                  className={
                    +invoices?.payDue < 0
                      ? classes.tableTextDue
                      : classes.tableText
                  } md={2} xs={6}>
                {invoices?.number || ""}
              </Grid>
              <Grid
                item
                className={
                  +invoices?.payDue < 0
                    ? classes.tableTextDue
                    : classes.tableText
                }
                md={2}
                xs={6}
              >
                {invoices?.price}
              </Grid>
              <Hidden smDown>
                <Grid item md={2}
                  className={
                    +invoices?.payDue < 0
                      ? classes.tableTextDue
                      : classes.tableText
                  }>
                  {invoices?.debt}
                </Grid>
                <Grid item 
                    className={
                      +invoices?.payDue < 0
                        ? classes.tableTextDue
                        : classes.tableText
                    } md={1} xs={6}>
                  {+invoices?.payDue < 0 ? Math.abs(+invoices?.payDue) : ""}
                </Grid>
                <Grid item md={3}>
                  <Box p={1}>
                    <Grid container justify="center">
                      <Grid item xs={6}>
                      <VisibilityIcon
                        className={classes.edit}
                        onClick={() => openDocument(invoices.filename)}
                      />
                        <a
                          href={
                            process.env.REACT_APP_IMAGE_HOST +
                              "/files/documents/" +
                              invoices.filename || ""
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FileDownloadIcon className={classes.red} />
                        </a>
                      </Grid>
                      <Grid item xs={6}>
                        {invoices?.payed ? (
                          null
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            className={classes.button}
                            onClick={() => makePayment(invoices?.number)}
                          >
                            <FormattedMessage id="pay" />
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item>
        <ProfileNoRows text={intl.formatMessage({ id: "profile.noBills" })} />
        </Grid>
      )}
    </Grid>
  );
};



const mapStateToProps = ({ user, language }: ApplicationState) => ({
  employeeList: user.employeeList,
  employeeListLoading: user.employeeListLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserInvoices: (payload: any) => {
      dispatch(getUserInvoices(payload));
    },
    getEmployeeList: () => {
      dispatch(getEmployeeList());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInvoices);