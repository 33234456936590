import React, { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import YouTube, { Options } from "react-youtube";

interface IProps {
  data: any,
  show: string
}

const GalleryVideo: React.FC<IProps> = ({
  data,
  show
}) => {
  useEffect(() => { }, []);


  const opts : Options = {
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <Box pl={3} pr={3} display={show}>
    <Box pt={2} />
        <Grid container spacing={3}>
            {data.map((item: { id: string | undefined; }) => {
                return (
                    <Grid item xs={12} sm={12} lg={6}>
                        <Box textAlign="center">
                            <YouTube videoId={item.id} opts={opts} />
                        </Box>
                    </Grid>
                )
            }
            )}
        </Grid>
    </Box>
  );
};

export default (GalleryVideo);
