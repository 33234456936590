import React, { Props, useEffect } from "react";
import { ProductsData } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: "block",
      height: "300px",
      transition: "max-height 0.15s ease-out",
      border: "1px solid #dadada",
      borderRadius: "0px",
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
        border: "1px solid #da2232",
      },
    },
    title: {
      marginBottom: 2,
      height: "60px",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#333333",
    },
    price: {
      height: "25px",
      marginBottom: 5,
      fontSize: "18px",
      fontWeight: "bold",
      color: "#da2232",
    },
    iconText: {
      paddingBottom: 5,
    },
    pos: {
      marginBottom: 12,
      color: "black",
    },
    media: {
      margin: "auto 0",
      width: "100%",
      height: "150px",
      backgroundSize: "contain",
    },
    link: {
      textDecoration: "none",
    },
    content: {
      backgroundColor: "#fafafa",
    },
    priceText: {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#da2232",
    },
    priceVAT: {
      height: "15px",
      fontSize: "11px",
      color: "#333333",
    },
  })
);

interface IProps {
  product: ProductsData;
  language: string;
}

const ProductCard: React.FC<IProps> = ({ product, language }) => {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Link to={"/product/" + product.code} className={classes.link}>
      <Card classes={{ root: classes.card }} key={product.code}>
        <CardMedia
          className={classes.media}
          image={
            process.env.REACT_APP_IMAGE_HOST +
              "/files/product_images/" +
              product.image[0].img || ""
          }
        />
        <CardContent className={classes.content}>
          <Typography component="h1" className={classes.title}>
            {product.title[language] || ""}
          </Typography>
          <Typography className={classes.price}>
            {product.price?.day ? (
              <strong>
                € {product.price?.day || ""} /
                <FormattedMessage id="products.day" />
              </strong>
            ) : null}
          </Typography>
          <Typography className={classes.priceVAT}>
            {product.price?.day ? (
              <strong>
                € {product.price?.dayVAT || ""}
                <FormattedMessage id="products.withVAT" />
              </strong>
            ) : null}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default ProductCard;
