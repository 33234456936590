import React from "react";
import "./App.css";
import { ThemeProvider } from "@material-ui/styles";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { getMessages } from "./translate";
import { theme } from "./theme/retrostyle";
import { Provider } from "react-redux";
import { ApplicationState } from "./store";
import { AnyAction, Store } from "redux";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import Routes from "./routes";
import { ThunkDispatch } from "redux-thunk";
import { CookiesProvider } from "react-cookie";
import ScrollToTop from "./components/ScrollToTop";
import loadAnalytics from "./shared/load-analytics";

interface PropsFromState {
  language: string;
}

interface MainProps {
  store: Store<ApplicationState>;
  history: History;
}

type AllProps = PropsFromState & MainProps;

const App: React.FC<AllProps> = ({ language, store, history }) => {

  loadAnalytics();
  
  return (
    <CookiesProvider>
      <IntlProvider locale={language} messages={getMessages(language)}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <ScrollToTop />
              <Routes />
            </ConnectedRouter>
          </Provider>
        </ThemeProvider>
      </IntlProvider>
    </CookiesProvider>
  );
};

const mapStateToProps = ({ language }: ApplicationState) => ({
  language: language.language,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
