import React from "react";
import { Helmet } from 'react-helmet';
import { useIntl } from "react-intl";

interface IProps {
    title?: string,
    description?: string,
}

const LocalHelmet: React.FC<IProps> = ({
    title,
    description,
}) => {

    const intl = useIntl();
  
    return (
        <Helmet>
          <title>{title ? title : intl.formatMessage({ id: "title" })}</title>
          <meta name="description" content={description ? description : intl.formatMessage({ id: "description" })} />
        </Helmet>
    );
};

export default (LocalHelmet);
