import React from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  labelText: string;
  code?: string;
  clickHandler: (selected: string) => any;
  buttonEnabled: boolean;
}

const stylesSelected = {
  backgroundColor: "#333333",
  color: "#ffffff",
  border: '0px',
  borderRadius: '0px',
  boxShadow: 'none',
  padding: "8px",
  textDecoration: "bold",
  display: "inline-block",
  fontSize: "16px",
  outline: 'none'
}

const stylesNotSelected = {
  backgroundColor: "#d3d3d3",
  color: "#333333",
  border: '0px',
  borderRadius: '0px',
  boxShadow: 'none',
  padding: "8px",
  textDecoration: "bold",
  display: "inline-block",
  fontSize: "16px",
  outline: 'none'
}


const PrimaryButton: React.FC<Props> = ({ labelText, code, clickHandler, buttonEnabled }) => {
  return (
    <button className="main-btn" style={buttonEnabled ? stylesSelected : stylesNotSelected} onClick={() => clickHandler(code || '')}><FormattedMessage id={labelText} /></button>
  );
};

export default PrimaryButton;
