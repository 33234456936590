import { Categories, ProductsData } from "../../models/types";

export enum ProductActionTypes {
  FETCH_REQUEST = "@@Product/FETCH_REQUEST",
  FETCH_SUCCESS = "@@Product/FETCH_SUCCESS",
  SEARCH_REQUEST = "@@Product/SEARCH_REQUEST",
  SEARCH_SUCCESS = "@@Product/SEARCH_SUCCESS",
  SEARCH_ERROR = "@@Product/SEARCH_ERROR",
  FETCH_ID_REQUEST = "@@Product/FETCH_ID_REQUEST",
  FETCH_ID_SUCCESS = "@@Product/FETCH_ID_SUCCESS",
  FETCH_ID_ERROR = "@@Product/FETCH_ID_ERROR",
  FETCH_SIMILAR_REQUEST = "@@SimilarProduct/FETCH_SIMILAR_REQUEST",
  FETCH_SIMILAR_SUCCESS = "@@SimilarProduct/FETCH_SIMILAR_SUCCESS",
  FETCH_NEW_REQUEST = "@@SimilarProduct/FETCH_NEW_REQUEST",
  FETCH_NEW_SUCCESS = "@@SimilarProduct/FETCH_NEW_SUCCESS",
  FETCH_VIEWED_REQUEST = "@@SimilarProduct/FETCH_VIEWED_REQUEST",
  FETCH_VIEWED_SUCCESS = "@@SimilarProduct/FETCH_VIEWED_SUCCESS",
  FETCH_CATEGORY_REQUEST = "@@SimilarProduct/FETCH_CATEGORY_REQUEST",
  FETCH_CATEGORY_SUCCESS = "@@SimilarProduct/FETCH_CATEGORY_SUCCESS",
  FETCH_LIST_REQUEST = "@@SimilarProduct/FETCH_LIST_REQUEST",
  FETCH_LIST_SUCCESS = "@@SimilarProduct/FETCH_LIST_SUCCESS",
  FETCH_MENU_REQUEST = "@@SimilarProduct/FETCH_MENU_REQUEST",
  FETCH_MENU_SUCCESS = "@@SimilarProduct/FETCH_MENU_SUCCESS",
  FETCH_ERROR = "@@Product/FETCH_ERROR"
}

export interface ProductState {
  readonly products: ProductsData[];
  readonly loading: boolean;

  readonly product: ProductsData[];
  readonly loadingId: boolean;

  readonly similarLoading: boolean;
  readonly similarProducts: ProductsData[];

  readonly newLoading: boolean;
  readonly newProducts: ProductsData[];

  readonly viewedLoading: boolean;
  readonly viewedProducts: ProductsData[];

  readonly categoriesLoading: boolean;
  readonly categories: Categories[];

  readonly categoryListLoading: boolean;
  readonly categoryList: Categories[];

  readonly categoryMenuLoading: boolean;
  readonly categoryMenu: Categories[];

  readonly searchResults: ProductsData[];
  
  readonly errors?: string;
}
