import React from "react";
import { Box } from "@material-ui/core";
import Loader from "react-loader-spinner";

const ProfileSpinner: React.FC = () => {

  return (
    <Box pt={5} pb={5} textAlign="center">
      <Loader 
        type="Bars" 
        color="#da2232" 
        height={100} 
        width={100} 
        timeout={5000} />
    </Box>
  );
};

export default ProfileSpinner;