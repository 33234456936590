import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import LocalHelmet from "./Helmet";
import { Circle, GoogleMap, LoadScript } from "@react-google-maps/api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    largeTitle: {
      paddingBottom: 15,
      fontWeight: "bold",
      fontSize: "25px",
    },
    text: {
      fontSize: "16px",
      lineHeight: "25px",
      textAlign: "left",
    },
    topBanner: {
      width: "100%",
    },
    banner: {
      width: "100%",
      maxWidth: "750px",
    },
    bannerBox: {
      textAlign: "center",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    },
    about: {
      textAlign: "left",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      color: "#333333",
      margin: "0 auto",
    },
    zone0: {
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "center",
      verticalAlign: "center",
    },
    zone1: {
      backgroundColor: "#ffffb2",
      fontSize: "14px",
      minHeight: "40px",
      lineHeight: "40px",
      fontWeight: "bold",
      textAlign: "center",
      borderTop: "1px solid #000000",
    },
    zone2: {
      backgroundColor: "#ffe5ac",
      fontSize: "14px",
      minHeight: "40px",
      lineHeight: "40px",
      fontWeight: "bold",
      textAlign: "center",
      borderTop: "1px solid #000000",
    },
    zone3: {
      backgroundColor: "#addffa",
      fontSize: "14px",
      minHeight: "40px",
      lineHeight: "40px",
      fontWeight: "bold",
      textAlign: "center",
      borderTop: "1px solid #000000",
    },
    zone4: {
      backgroundColor: "#ddecd2",
      fontSize: "14px",
      minHeight: "40px",
      lineHeight: "40px",
      fontWeight: "bold",
      textAlign: "center",
      borderTop: "1px solid #000000",
    },
    zone5: {
      backgroundColor: "#d8bbea",
      fontSize: "14px",
      minHeight: "40px",
      lineHeight: "40px",
      fontWeight: "bold",
      textAlign: "center",
      borderTop: "1px solid #000000",
    },
    zone6: {
      backgroundColor: "#e5e6e7",
      fontSize: "14px",
      minHeight: "40px",
      lineHeight: "40px",
      fontWeight: "bold",
      textAlign: "center",
      borderTop: "1px solid #000000",
    },
  })
);

const Transport: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [currentLocation] = React.useState({
    lat: 57.1,
    lng: 24.8,
  });

  const onMapLoad = (map: any) => {};

  return (
    <Box alignItems="center" display="block">
      <img
        src="/images/transport/header.png"
        className={classes.topBanner}
        alt={intl.formatMessage({ id: "repair.title" })}
      />
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY || ""}
        libraries={["places"]}
      >
        <LocalHelmet title={intl.formatMessage({ id: "transport.title" })} />
        <Box className={classes.about}>
          <Grid container className={classes.about} spacing={2}>
            <Grid item xs={12} sm={12}>
              <GoogleMap
                id="search-box-example"
                mapContainerStyle={{
                  minHeight: "400px",
                  maxHeight: "600px",
                  minWidth: "300px",
                  maxWidth: "1200px",
                }}
                zoom={8}
                center={currentLocation}
                onLoad={(map) => onMapLoad(map)}
              >
                <Circle
                  center={{ lat: 57.5476819, lng: 25.3877044 }}
                  radius={100}
                  options={{
                    fillColor: "#d92232",
                    fillOpacity: 0.3,
                    strokeColor: "#d92232",
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    zIndex: 5,
                  }}
                />
                <Circle
                  center={{ lat: 57.5476819, lng: 25.3877044 }}
                  radius={3000}
                  options={{
                    fillColor: "#feff00",
                    fillOpacity: 0.3,
                    strokeColor: "#feff00",
                    strokeOpacity: 0.4,
                    strokeWeight: 1,
                    zIndex: 5,
                  }}
                />
                <Circle
                  center={{ lat: 57.5476819, lng: 25.3877044 }}
                  radius={8000}
                  options={{
                    fillColor: "#fec102",
                    fillOpacity: 0.3,
                    strokeColor: "#fec102",
                    strokeOpacity: 0.3,
                    strokeWeight: 1,
                    zIndex: 4,
                  }}
                />
                <Circle
                  center={{ lat: 57.5476819, lng: 25.3877044 }}
                  radius={11000}
                  options={{
                    fillColor: "#00b0f1",
                    fillOpacity: 0.3,
                    strokeColor: "#00b0f1",
                    strokeOpacity: 0.3,
                    strokeWeight: 1,
                    zIndex: 3,
                  }}
                />
                <Circle
                  center={{ lat: 57.5476819, lng: 25.3877044 }}
                  radius={15000}
                  options={{
                    fillColor: "#a9d08e",
                    fillOpacity: 0.3,
                    strokeColor: "#a9d08e",
                    strokeOpacity: 0.2,
                    strokeWeight: 1,
                    zIndex: 2,
                  }}
                />
                <Circle
                  center={{ lat: 57.5476819, lng: 25.3877044 }}
                  radius={20000}
                  options={{
                    fillColor: "#9954ca",
                    fillOpacity: 0.3,
                    strokeColor: "#9954ca",
                    strokeOpacity: 0.2,
                    strokeWeight: 1,
                    zIndex: 1,
                  }}
                />
                <Circle
                  center={{ lat: 56.6639256, lng: 23.7678989 }}
                  radius={100}
                  options={{
                    fillColor: "#d92232",
                    fillOpacity: 0.3,
                    strokeColor: "#d92232",
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    zIndex: 5,
                  }}
                />
                <Circle
                  center={{ lat: 56.6639256, lng: 23.7678989 }}
                  radius={3000}
                  options={{
                    fillColor: "#feff00",
                    fillOpacity: 0.3,
                    strokeColor: "#feff00",
                    strokeOpacity: 0.4,
                    strokeWeight: 1,
                    zIndex: 5,
                  }}
                />
                <Circle
                  center={{ lat: 56.6639256, lng: 23.7678989 }}
                  radius={8000}
                  options={{
                    fillColor: "#fec102",
                    fillOpacity: 0.3,
                    strokeColor: "#fec102",
                    strokeOpacity: 0.3,
                    strokeWeight: 1,
                    zIndex: 4,
                  }}
                />
                <Circle
                  center={{ lat: 56.6639256, lng: 23.7678989 }}
                  radius={11000}
                  options={{
                    fillColor: "#00b0f1",
                    fillOpacity: 0.3,
                    strokeColor: "#00b0f1",
                    strokeOpacity: 0.3,
                    strokeWeight: 1,
                    zIndex: 3,
                  }}
                />
                <Circle
                  center={{ lat: 56.6639256, lng: 23.7678989 }}
                  radius={15000}
                  options={{
                    fillColor: "#a9d08e",
                    fillOpacity: 0.3,
                    strokeColor: "#a9d08e",
                    strokeOpacity: 0.2,
                    strokeWeight: 1,
                    zIndex: 2,
                  }}
                />
                <Circle
                  center={{ lat: 56.6639256, lng: 23.7678989 }}
                  radius={20000}
                  options={{
                    fillColor: "#9954ca",
                    fillOpacity: 0.3,
                    strokeColor: "#9954ca",
                    strokeOpacity: 0.2,
                    strokeWeight: 1,
                    zIndex: 1,
                  }}
                />
                <Circle
                  center={{ lat: 56.9618395, lng: 24.1574013 }}
                  radius={100}
                  options={{
                    fillColor: "#d92232",
                    fillOpacity: 0.3,
                    strokeColor: "#d92232",
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    zIndex: 5,
                  }}
                />
                <Circle
                  center={{ lat: 56.9618395, lng: 24.1574013 }}
                  radius={3000}
                  options={{
                    fillColor: "#feff00",
                    fillOpacity: 0.3,
                    strokeColor: "#feff00",
                    strokeOpacity: 0.4,
                    strokeWeight: 1,
                    zIndex: 5,
                  }}
                />
                <Circle
                  center={{ lat: 56.9618395, lng: 24.1574013 }}
                  radius={8000}
                  options={{
                    fillColor: "#fec102",
                    fillOpacity: 0.3,
                    strokeColor: "#fec102",
                    strokeOpacity: 0.3,
                    strokeWeight: 1,
                    zIndex: 4,
                  }}
                />
                <Circle
                  center={{ lat: 56.9618395, lng: 24.1574013 }}
                  radius={11000}
                  options={{
                    fillColor: "#00b0f1",
                    fillOpacity: 0.3,
                    strokeColor: "#00b0f1",
                    strokeOpacity: 0.3,
                    strokeWeight: 1,
                    zIndex: 3,
                  }}
                />
                <Circle
                  center={{ lat: 56.9618395, lng: 24.1574013 }}
                  radius={15000}
                  options={{
                    fillColor: "#a9d08e",
                    fillOpacity: 0.3,
                    strokeColor: "#a9d08e",
                    strokeOpacity: 0.2,
                    strokeWeight: 1,
                    zIndex: 2,
                  }}
                />
                <Circle
                  center={{ lat: 56.9618395, lng: 24.1574013 }}
                  radius={20000}
                  options={{
                    fillColor: "#9954ca",
                    fillOpacity: 0.3,
                    strokeColor: "#9954ca",
                    strokeOpacity: 0.2,
                    strokeWeight: 1,
                    zIndex: 1,
                  }}
                />
              </GoogleMap>
            </Grid>            
            <Box className={classes.bannerBox}>
                <img
                  src="/images/deliverytransport.png"
                  style={{ maxWidth: "80%" }}
                  alt={intl.formatMessage({ id: "rent.title" })}
                />
              </Box>
            <Grid item xs={12} sm={12}>
              <Box pt={1} className={classes.text}>
                <p>
                  <FormattedMessage id="transport.2" />
                </p>
                <p>
                  <FormattedMessage id="transport.3" />
                </p>
                <p>
                  <FormattedMessage id="transport.4" />
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </LoadScript>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {};
};

export default connect(null, mapDispatchToProps)(Transport);
