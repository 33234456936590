import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { MenuTextData, ProductsData } from "../models/types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import LocalHelmet from "./Helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    breadcrumbLink: {
      fontSize: "15px",
      textDecoration: "none",
      color: "#000000",
    },
  })
);

interface IProps {
  name?: string;
}

interface PropsFromState {
  language: string;
  loading: boolean;
  errors?: string;
  products: ProductsData[];
  text: MenuTextData;
  breadCrumb: string[];
}

type AllProps = PropsFromState & IProps;

const ProductBreadcrumb: React.FC<AllProps> = ({
  name,
  language,
  loading,
  text,
  breadCrumb,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const buildPath = () => {
    let title = intl.formatMessage({ id: "title" });
    breadCrumb?.map((item) => (title += " - " + text[item]?.value[language]));
    if (name) {
      title += " - " + name;
    }
    return title;
  };

  return (
    <Box pb={2}>
      <LocalHelmet title={buildPath()} />
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link className={classes.breadcrumbLink} to={"/"}>
          SAKUMS
        </Link>
        {!loading
          ? breadCrumb.map((item, index) => (
              <Link
                key={index}
                className={classes.breadcrumbLink}
                to={"/product/category/" + item}
              >
                {text[item]?.value[language]}
              </Link>
            ))
          : null}
      </Breadcrumbs>
    </Box>
  );
};

const mapStateToProps = ({ language, products, menu }: ApplicationState) => ({
  language: language.language,
  loading: products.loading,
  errors: products.errors,
  products: products.products,
  text: menu.text,
  breadCrumb: menu.breadcrumb,
});

export default connect(mapStateToProps)(ProductBreadcrumb);
