/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import RegisterBusiness from "./RegisterBusiness";
import RegisterPrivate from "./RegisterPrivate";
import { FormattedMessage } from "react-intl";
import {
  register,
  registerSmartId,
  registerSmartIdClean,
  registerSmartIdStatus,
} from "../store/users/action";
import { SelectData, SmartId } from "../models/types";
import SmartIdInput from "./SmartIdInput";
import { getCounties, getCountries } from "../store/menu/action";
import LocalHelmet from "./Helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    boxLine: {
      border: "1px solid #ebdcc6",
      borderRadius: "4px",
      boxShadow: "none",
      padding: 10,
      backgroundColor: "#ffffff",
    },
    boxTitle: {
      paddingBottom: 10,
      paddingTop: 10,
      fontWeight: "bold",
      fontSize: "18px",
    },
    largeTitle: {
      paddingBottom: 15,
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "30px",
    },
    text: {
      fontSize: "14px",
      lineHeight: "25px",
    },
    link: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#000000",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#da2232",
      },
    },
    linkText: {
      fontSize: "15px",
      fontWeight: "bold",
    },
    textField: {
      width: "40%",
      minWidth: "250px",
      fontSize: "10px",
      backgroundColor: "#ffffff",
      border: "1px solid #e3e3e3",
      margin: "0px",
    },
    focused: {},
    outlinedInput: {
      "&$focused $notchedOutline": {
        border: "1px solid #e3e3e3",
      },
    },
    button: {
      width: "100%",
      maxWidth: "140px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    buttonSkip: {
      width: "100%",
      maxWidth: "200px",
      backgroundColor: "#333333",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#333333",
        color: "#ffffff",
      },
    },
    title: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#000000",
      paddingBottom: "16px",
    },
    information: {
      fontSize: "13px",
      fontWeight: "normal",
      color: "#000000",
      paddingBottom: "16px",
      paddingTop: "18px",
      textAlign: "center",
    },
    back: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#333333",
      paddingBottom: "16px",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
      },
    },
    code: {
      width: "100%",
      fontSize: "35px",
      backgroundColor: "#ffffff",
      textDecoration: "bold",
      textAlign: "center",
      color: "#333333",
      margin: "0px",
      paddingTop: "20px",
    },
    codeText: {
      width: "100%",
      fontSize: "20px",
      backgroundColor: "#ffffff",
      textDecoration: "bold",
      textAlign: "center",
      color: "#333333",
      margin: "0px",
      paddingTop: "20px",
    },
    backIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#858585",
      paddingBottom: "16px",
    },
  })
);

interface IProps {}

interface PropsFromState {
  smartId?: SmartId;
  registerLoading: boolean;
  registerError: string;
  countries: SelectData[];
  counties: SelectData[];
}

interface propsFromDispatch {
  registerSmartId: (userData: any) => any;
  registerSmartIdStatus: (hash: string) => any;
  registerSmartIdClean: () => any;
  getCountries: () => any;
  getCounties: () => any;
  register: (payload: any) => any;
}

type AllProps = IProps & PropsFromState & propsFromDispatch;

const Register: React.FC<AllProps> = ({
  smartId,
  registerLoading,
  registerError,
  countries,
  counties,
  registerSmartId,
  registerSmartIdStatus,
  registerSmartIdClean,
  getCountries,
  getCounties,
  register,
}) => {
  const classes = useStyles();
  const [registerLoop, setRegisterLoop] = useState(0);
  const [formState, setFormState] = useState({
    selected: "INDIVIDUAL",
    step: "step1",
    skipSmartId: false,
    ssn: "",
    name: "",
    surname: "",
    code: "",
    email: "",
    hash: "",
  });
  const [errors, setErrors] = useState({});
  const [formEnabled, setFormEnabled] = useState(true);

  useEffect(() => {
    getCountries();
    getCounties();
  }, []);

  useEffect(() => {
    if (smartId?.status === "REQUESTED" && smartId?.flow === "REGISTER") {
      setRegisterLoop(
        window.setInterval(() => {
          registerSmartIdStatus(smartId?.hash);
        }, 6000)
      );
      setTimeout(() => clearInterval(registerLoop), 90000);
    } else {
      clearInterval(registerLoop);
    }
  }, [smartId?.status]);

  useEffect(() => {
    if (smartId?.status === "ERROR" && smartId?.flow === "REGISTER") {
      setTimeout(() => {
        setTimeout(() => registerSmartIdClean(), 1000);
        setTimeout(() => clearInterval(registerLoop));
      }, 5000);
    }
  }, [smartId?.status]);

  useEffect(() => {
    setFormEnabled(!registerLoading);
    if (
      registerLoading === false &&
      registerError &&
      formState.step === "step3"
    ) {
      let error = { duplicate: registerError };
      setErrors({ ...error });
      setFormState({ ...formState, step: "step3" });
    }

    if (
      registerLoading === false &&
      !registerError &&
      formState.step === "step3"
    ) {
      setFormState({ ...formState, step: "step4" });
      setTimeout(() => {
        setFormState({
          selected: "",
          step: "step1",
          skipSmartId: false,
          ssn: "",
          name: "",
          surname: "",
          code: "",
          email: "",
          hash: "",
        });
      }, 30000);
    }
  }, [registerLoading]);

  useEffect(() => {
    if (smartId?.status === "SUCCESS" && smartId?.flow === "REGISTER") {
      setFormState({
        ...formState,
        name: smartId?.name,
        surname: smartId?.surname,
        code: smartId?.code,
        ssn: smartId?.code.substring(6),
        skipSmartId: false,
        step: "step3",
      });
    }
  }, [smartId?.status]);

  const skipSmartId = () =>
    setFormState({
      ...formState,
      skipSmartId: true,
      step: "step3",
    });
  const onClick = (selected: string) =>
    setFormState({ ...formState, selected: selected, step: "step2" });

  const onBack = (step: string) => setFormState({ ...formState, step: step });

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value, type } = e.currentTarget;
    const setValue = type === "checkbox" ? checked : value;
    const limitLength = ["companyRegistrationNumber", "companyVAT", "ssn"];
    if (limitLength.includes(name) && value?.length > 14) {
      return;
    } else {
      setFormState({ ...formState, [name]: setValue });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleValidation = () => {
    let formData: any = formState;
    let formIsValid = true;
    let error = {};

    if (!formData.code) {
      setFormState({
        ...formState,
        code: formState.ssn,
      });
    }

    //Name
    if (!formData.name) {
      formIsValid = false;
      error = { ...error, name: "field.empty" };
    }

    if (typeof formData.name !== "undefined") {
      if (!formData.name.match(/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+$/)) {
        formIsValid = false;
        error = { ...error, name: "field.letters" };
      }
    }

    //Surame
    if (!formData.surname) {
      formIsValid = false;
      error = { ...error, surname: "field.empty" };
    }

    if (typeof formData.surname !== "undefined") {
      if (!formData.surname.match(/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+$/)) {
        formIsValid = false;
        error = { ...error, surname: "field.letters" };
      }
    }

    //Email
    if (!formData.email) {
      formIsValid = false;
      error = { ...error, email: "field.empty" };
    }

    if (typeof formData.email !== "undefined") {
      let lastAtPos = formData.email.lastIndexOf("@");
      let lastDotPos = formData.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          formData.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          formData.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        error = { ...error, email: "field.emailInvalid" };
      }
    }

    //Phone
    if (!formData.phone) {
      formIsValid = false;
      error = { ...error, phone: "field.empty" };
    }

    if (typeof formData.phone !== "undefined") {
      if (!formData.phone.match(/^[0-9 +]+$/)) {
        formIsValid = false;
        error = { ...error, phone: "field.numbers" };
      }
    }

    //Address
    if (!formData.address) {
      formIsValid = false;
      error = { ...error, address: "field.empty" };
    }

    //City
    if (!formData.city) {
      formIsValid = false;
      error = { ...error, city: "field.empty" };
    }

    //Index
    if (!formData.postalCode) {
      formIsValid = false;
      error = { ...error, postalCode: "field.empty" };
    }

    //County
    if (!formData.county) {
      formIsValid = false;
      error = { ...error, county: "field.empty" };
    }

    //Country
    if (!formData.county) {
      formIsValid = false;
      error = { ...error, country: "field.empty" };
    }

    //SSN
    if (!formData.ssn) {
      formIsValid = false;
      error = { ...error, ssn: "field.empty" };
    }

    if (formData.selected === "BUSINESS") {
      //Company
      if (!formData.companyName) {
        formIsValid = false;
        error = { ...error, companyName: "field.empty" };
      }
      //registration number
      if (!formData.companyRegistrationNumber) {
        formIsValid = false;
        error = { ...error, companyRegistrationNumber: "field.empty" };
      }
    }

    setErrors({ ...error });
    return formIsValid;
  };

  const formSubmit = () => {
    if (handleValidation()) {
      register(formState);
    }
  };

  const smartHandler = () => {
    const userData = {
      ssn: formState.ssn,
      country: "LV",
    };
    registerSmartId(userData);
  };

  return (
    <Box pl={2} pt={4} pr={2} pb={12}>
      <LocalHelmet title="STATS Rent - Registracija" />
      <Box display={formState.step === "step1" ? "flex" : "none"} pt={4} style={{minHeight: "50vh"}}>
        <Grid container direction="row" justify="center" alignContent="center">
          <Grid item xs={12} sm={12}>
            <Box className={classes.largeTitle}>
              <FormattedMessage id="register" />
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} className={classes.link}>
            <Box pt={6} textAlign="center" onClick={() => onClick("BUSINESS")}>
              <img src="/images/register/business.png" alt="BUSINESS" />
              <Typography className={classes.linkText}>
                <FormattedMessage id="register.business" />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} className={classes.link}>
            <Box
              pt={6}
              textAlign="center"
              onClick={() => onClick("INDIVIDUAL")}
            >
              <img src="/images/register/individual.png" alt="Individual" />
              <Typography className={classes.linkText}>
                <FormattedMessage id="register.individual" />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box display={formState.step === "step2" ? "flex" : "none"}>
        <Grid container direction="row" justify="center" alignContent="center">
          <Grid item xs={12} sm={12}>
            <Box className={classes.largeTitle}>
              <FormattedMessage id="register" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box pt={5} textAlign="center">
              <img src="/images/smart-id.png" alt="Smart ID" />
            </Box>
            <Box pt={5} className={classes.information}>
              <FormattedMessage id="register.smartId" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box textAlign="center" pt={4}>
              <SmartIdInput
                title=""
                value={formState.ssn || ""}
                formChange={handleFormChange}
              />
            </Box>
            <Box className={classes.information}>
              <FormattedMessage id="register.smartIdMore" />{" "}
              <a
                href="https://www.smart-id.com/lv/"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                <FormattedMessage id="register.smartIdHere" />
              </a>
              .
            </Box>
            <Box
              display={smartId?.status === "REQUESTED" ? "block" : "none"}
              className={classes.codeText}
            >
              <FormattedMessage id="smartId.code" />
            </Box>
            <Box
              display={smartId?.status === "REQUESTED" ? "block" : "none"}
              className={classes.code}
            >
              {smartId?.code}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box pt={12} textAlign="center">
              <Grid
                container
                spacing={1}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={6} md={6}>
                  <Box textAlign="center">
                    <span
                      onClick={() => onBack("step1")}
                      className={classes.backIcon}
                    >
                      {" "}
                      &lt;{" "}
                    </span>
                    <span
                      onClick={() => onBack("step1")}
                      className={classes.back}
                    >
                      <FormattedMessage id="register.back" />
                    </span>
                  </Box>
                </Grid>
                <Grid xs={6} item md={6}>
                  <Box textAlign="center">
                    <Button
                      variant="contained"
                      onClick={smartHandler}
                      size="large"
                      className={classes.button}
                    >
                      <FormattedMessage id="smartId.authenticate" />
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      onClick={skipSmartId}
                      size="large"
                      className={classes.buttonSkip}
                    >
                      <FormattedMessage id="smartId.no" />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {formState.step === "step3" && formState.selected === "BUSINESS" ? (
        <>
          <Box className={classes.largeTitle}>
            <FormattedMessage id="register" />
          </Box>
          <RegisterBusiness
            smartId={smartId || undefined}
            countries={countries}
            counties={counties}
            back={onBack}
            show={formState.selected === "BUSINESS" ? "flex" : "none"}
            formState={formState}
            skipSmartId={formState.skipSmartId}
            registerEnabled={formEnabled}
            formErrors={errors}
            formChange={handleFormChange}
            selectChange={handleSelectChange}
            formSubmit={formSubmit}
          />
        </>
      ) : null}
      {formState.step === "step3" && formState.selected === "INDIVIDUAL" ? (
        <>
          <Box className={classes.largeTitle}>
            <FormattedMessage id="register" />
          </Box>
          <RegisterPrivate
            smartId={smartId || undefined}
            countries={countries}
            counties={counties}
            back={onBack}
            show={formState.selected === "INDIVIDUAL" ? "flex" : "none"}
            formState={formState}
            skipSmartId={formState.skipSmartId}
            formErrors={errors}
            formChange={handleFormChange}
            selectChange={handleSelectChange}
            formSubmit={formSubmit}
          />
        </>
      ) : null}
      {formState.step === "step4" ? (
        <>
          <Box className={classes.largeTitle}>
            <FormattedMessage id="register" />
          </Box>
          {registerError ? (
            <Box p={12} textAlign="center">
              <FormattedMessage id="register.error" />
            </Box>
          ) : (
            <Box p={12} textAlign="center">
              <FormattedMessage id="register.success" />
            </Box>
          )}
        </>
      ) : null}
    </Box>
  );
};

const mapStateToProps = ({ user, menu }: ApplicationState) => ({
  smartId: user.smartId,
  registerLoading: user.registerLoading,
  registerError: user.errors,
  countries: menu.countries,
  counties: menu.counties,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    registerSmartId: (userData: any) => {
      dispatch(registerSmartId(userData));
    },
    registerSmartIdStatus: (hash: string) => {
      dispatch(registerSmartIdStatus(hash));
    },
    registerSmartIdClean: () => {
      dispatch(registerSmartIdClean());
    },
    getCountries: () => {
      dispatch(getCountries());
    },
    getCounties: () => {
      dispatch(getCounties());
    },
    register: (payload: any) => {
      dispatch(register(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
