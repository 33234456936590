import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import LocalHelmet from "./Helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    largeTitle: {
      paddingBottom: 15,
      fontWeight: "bold",
      fontSize: "25px",
    },
    text: {
      fontSize: "16px",
      lineHeight: "25px",
      textAlign: "left",
    },
    banner: {
      width: "100%",
    },
    about: {
      textAlign: "left",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    },
    link: {
      color: "#da2232",
      height: "25px",
      fontSize: "16px",
      fontWeight: "bold",
      marginBottom: "5px",
    },
    bannerBox: {
      textAlign: "center",
      alignSelf: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    },
  })
);

const Lend: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box alignItems="center" display="block">
      <LocalHelmet
        title={
          intl.formatMessage({ id: "title" }) +
          " - " +
          intl.formatMessage({ id: "lend.1" })
        }
      />
      <Box className={classes.about}>
              <Box className={classes.bannerBox}>
                <img
                  src="/images/rent.jpeg"
                  style={{ maxWidth: "50%" }}
                  alt={intl.formatMessage({ id: "rent.title" })}
                />
              </Box>
        <Grid container className={classes.about} spacing={9}>
          <Grid item xs={12} sm={12}>
            <Box pt={1} className={classes.text}>
              <b>
                <FormattedMessage id="lend.1" />
              </b>
              <p>
                <FormattedMessage id="lend.2" />
              </p>
              <b>
                <FormattedMessage id="lend.3" />
              </b>
              <li>
                <FormattedMessage id="lend.4" />
              </li>
              <li>
                <FormattedMessage id="lend.5" />
              </li>
              <li>
                <FormattedMessage id="lend.6" />
              </li>
              <li>
                <FormattedMessage id="lend.7" />
              </li>
              <li>
                <FormattedMessage id="lend.8" />
              </li>
              <li>
                <FormattedMessage id="lend.9" />
              </li>
              <br />
              <p>
                <FormattedMessage id="lend.10" />
                <Link to="/remonts" className={classes.link}>
                  <FormattedMessage id="lend.11" />
                </Link>
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {};
};

export default connect(null, mapDispatchToProps)(Lend);
