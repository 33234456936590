import React, { useEffect, useState } from "react";
import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigatePrevIcon from "@material-ui/icons/NavigateBefore";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentWindow: {
      fontSize: "16px",
      fontWeight: "bold",
      maxWidth: "90%",
      width: "90%",
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "95%",
          maxWidth: "95%",
        },
      },
    },
    documentContent: {
      maxWidth: "90%",
      width: "90%",
    },
    button: {
      width: "37px",
      height: "37px",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    documentPage: {
      height: "50px",
      width: "50px",
      textAlign: "center",
      align: "center",
    },
    document: {
      overflowX: "hidden",
    },
  })
);

interface IProps {
  fileName: string;
  isDocumentOpen: boolean;
  closeHandler: () => any;
}

const ProfileDocumentViewer: React.FC<IProps> = ({
  fileName,
  isDocumentOpen,
  closeHandler,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const width = window.innerWidth;
  return (
    <Dialog
      open={isDocumentOpen}
      onBackdropClick={closeHandler}
      className={classes.documentWindow}
    >
      <DialogContent className={classes.documentContent}>
        <div>
          <nav>
            <Grid container>
              <Grid md={4}></Grid>
              <Grid md={4}>
                <Grid container justify="center" alignItems="center">
                  <Grid item className={classes.documentPage}>
                    <IconButton
                      className={classes.button}
                      aria-label="Previous"
                      onClick={goToPrevPage}
                      color="inherit"
                    >
                      <NavigatePrevIcon />
                    </IconButton>
                  </Grid>
                  <Grid item className={classes.documentPage}>
                    <Box pt={1}>
                      {pageNumber} no {numPages}
                    </Box>
                  </Grid>
                  <Grid item className={classes.documentPage}>
                    <IconButton
                      className={classes.button}
                      aria-label="Next"
                      onClick={goToNextPage}
                      color="inherit"
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={4} style={{ textAlign: "end" }}>
                <IconButton
                  className={classes.button}
                  aria-label="Next"
                  onClick={closeHandler}
                  color="inherit"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </nav>
          <Document
            file={
              process.env.REACT_APP_IMAGE_HOST +
                "/files/documents/" +
                fileName || ""
            }
            onLoadSuccess={onDocumentLoadSuccess}
            className={classes.document}
            renderMode="svg"
          >
            <Page pageNumber={pageNumber} width={Math.min(width * 0.75)} />
          </Document>
          <nav>
            <Grid container>
              <Grid md={4}></Grid>
              <Grid md={4}>
                <Grid container justify="center" alignItems="center">
                  <Grid item className={classes.documentPage}>
                    <IconButton
                      className={classes.button}
                      aria-label="Previous"
                      onClick={goToPrevPage}
                      color="inherit"
                    >
                      <NavigatePrevIcon />
                    </IconButton>
                  </Grid>
                  <Grid item className={classes.documentPage}>
                    <Box pt={1}>
                      {pageNumber} no {numPages}
                    </Box>
                  </Grid>
                  <Grid item className={classes.documentPage}>
                    <IconButton
                      className={classes.button}
                      aria-label="Next"
                      onClick={goToNextPage}
                      color="inherit"
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={4} style={{ textAlign: "end" }}>
                <IconButton
                  className={classes.button}
                  aria-label="Next"
                  onClick={closeHandler}
                  color="inherit"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </nav>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProfileDocumentViewer;
