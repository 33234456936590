/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { ProductsData, UserData } from "../models/types";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { searchProduct, searchProducts } from "../store/products/action";
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { appendTreeSelection } from "../store/menu/action";
import ProductBreadcrumb from "./ProductBreadcrumb";
import { addCart } from "../store/users/action";
import DatePicker, { registerLocale } from "react-datepicker";
import "./styles/datepicker.css";
import lv from "date-fns/locale/lv";
import { getDay } from "date-fns";
import moment from "moment";
import ProductPhotoSliderVertical from "./ProductPhotoSliderVertical";
import ProductPhotoSliderHorizontal from "./ProductPhotoSliderHorizontal";
import ProductPriceCalculator from "./ProductPriceCalculator";
import { FormattedMessage, useIntl } from "react-intl";
import ProductSlider from "./ProductSlider";
import LocalHelmet from "./Helmet";

registerLocale("lv", {
  ...lv,
  options: {
    weekStartsOn: 1,
    lastDayOfWeek: 0,
    firstWeekContainsDate: 1,
  },
});

const isWeekday = (date: Date) => {
  const day: number = getDay(date);
  return day !== 0;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 2,
      minHeight: "60px",
      fontSize: "24px",
      fontWeight: "bold",
      color: "black",
    },
    title2: {
      fontSize: "22px",
      fontWeight: "bold",
      color: "black",
    },
    description: {
      marginBottom: 2,
      height: "60px",
      fontSize: "14px",
      fontWeight: "bold",
      color: "black",
    },
    priceBox: {
      paddingTop: "30px",
      textAlign: "center",
      borderSpacing: "4px",
    },
    priceText: {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#da2232",
    },
    price: {
      marginBottom: 5,
      fontSize: "18px",
      fontWeight: "bold",
      color: "#da2232",
    },
    priceVAT: {
      fontSize: "11px",
      color: "#c2c2c2",
    },
    iconText: {
      paddingBottom: 5,
    },
    pos: {
      marginBottom: 12,
      color: "black",
    },
    link: {
      textDecoration: "none",
    },
    heading: {
      flexBasis: "33.33%",
      flexShrink: 0,
      fontSize: "14px",
      fontWeight: "bold",
      color: "black",
    },
    accordion: {
      border: "1px solid #ebdcc6",
      boxShadow: "none",
    },
    sliderBox: {
      backgroundColor: "#f9f9f9",
      color: "#000000",
      fontSize: 18,
      fontWeight: "bold",
    },
    tableBorders: {
      borderTop: "1px solid #e7e7e7",
      borderLeft: "1px solid #e7e7e7",
      borderRight: "1px solid #e7e7e7",
      borderRadius: "0px",
    },
    tableText: {
      backgroundColor: "#f5f5f5",
      fontWeight: "bold",
      fontSize: "13px",
      color: "#333333",
      minHeight: "40px",
      display: "flex",
      justifyContent: "center",
      borderRight: "1px solid #e7e7e7",
      borderBottom: "1px solid #e7e7e7",
      borderRadius: "0px",
    },
    tablePrice: {
      backgroundColor: "#ffffff",
      fontSize: "13px",
      minHeight: "40px",
      color: "#333333",
      paddingLeft: "15px",
      borderBottom: "1px solid #e7e7e7",
      borderRadius: "0px",
      display: "flex",
      justifyContent: "center",
    },
    tableImage: {
      minHeight: "40px",
      display: "flex",
      justifyContent: "center",
    },
    tableLink: {
      fontSize: "13px",
      minHeight: "40px",
      color: "#333333",
      paddingTop: "10px",
      justifyContent: "left",
    },
    imageTitle: {
      verticalAlign: "middle",
      display: "inline-flex",
      fontSize: "18px",
      fontWeight: "bold",
      paddingBottom: "10px",
    },
    lineTitle: {
      verticalAlign: "middle",
      display: "inline-flex",
      fontSize: "18px",
      fontWeight: "bold",
      paddingBottom: "4px",
    },
    boxLine: {
      backgroundColor: "#e3e3e3",
      width: "47px",
      height: "3px",
    },
    imageSubscribe: {
      height: "3px",
    },
    bonuss: {
      verticalAlign: "middle",
      display: "inline-flex",
      fontSize: "12px",
      paddingTop: "20px",
      textAlign: "center",
    },
    inputInput: {
      padding: theme.spacing(2, 1.5, 1.5, 0),
      transition: theme.transitions.create("width"),
      paddingLeft: `calc(0.1em + ${theme.spacing(1)}px)`,
      width: "94%",
      borders: "0px",
      background: "#f0f0f0",
      borderTop: "0px",
      borderRight: "0px",
      borderLeft: "0px",
      fontSize: "18px",
      color: "#333333",
      borderBottomColor: "#333333",
    },
    document: {
      fontSize: "16px",
      textDecoration: "underline",
    },
    mainImage: {
      maxWidth: "100%",
      alignSelf: "center",
    },
    button: {
      width: "100%",
      backgroundColor: "#da2232",
      color: "#ffffff",
      borderRadius: "0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#9b1b25",
        color: "#ffffff",
      },
    },
    labelError: {
      color: "red",
      alignSelf: "center",
      fontSize: "12px",
    },
  })
);

interface PropsFromState {
  language: string;
  loading: boolean;
  errors?: string;
  products: ProductsData[];
  recentProducts: ProductsData[];
  recentProductsLoading: boolean;
  token: UserData["token"];
}

interface propsFromDispatch {
  searchProduct: (type: string, id: string) => any;
  searchProducts: (type: string) => any;
  addToCart: (request: any) => any;
  appendTreeSelection: (type: string, id: string) => any;
}

interface IProps {
  type: string;
  id?: string;
}

type AllProps = IProps & PropsFromState & propsFromDispatch;

const ProductDetails: React.FC<AllProps> = ({
  id,
  type,
  language,
  loading,
  products,
  recentProducts,
  recentProductsLoading,
  token,
  searchProduct,
  searchProducts,
  addToCart,
  appendTreeSelection,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [imageSelected, setImageSelected] = useState(0);
  const [startDateError, setStartDateError] = useState<String>();
  const [endDateError, setEndDateError] = useState<String>();
  const [cartMessage, setCartMessage] = useState<String | undefined>();

  useEffect(() => {
    setImageSelected(0);
    appendTreeSelection(type, id || "");
    searchProduct(type, id || "");
    searchProducts("recent");
  }, [token, type, id]);

  const addCart = () => {
    setStartDateError(undefined);
    setEndDateError(undefined);
    if (!startDate) {
      setStartDateError(intl.formatMessage({ id: "field.empty" }));
      return;
    }
    if (!endDate) {
      setEndDateError(intl.formatMessage({ id: "field.empty" }));
      return;
    }
    let request = {
      productCode: id,
      startDate: moment(startDate).format("DD.MM.YYYY"),
      endDate: moment(endDate).format("DD.MM.YYYY"),
    };
    addToCart(request);
    setCartMessage(intl.formatMessage({ id: "cart.12" }));
    setTimeout(() => setCartMessage(undefined), 5000);
  };

  return (
    <>
      {!loading
        ? products?.map((product) => (
            <Box pl={2} pr={2} pt={3} pb={2}>
              <ProductBreadcrumb name={product?.title[language]} />
              <Grid container spacing={0} direction="row" key="ProductMain">
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={5} direction="row">
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container spacing={5} direction="row">
                        <Hidden smDown>
                          <Grid item md={3}>
                            <ProductPhotoSliderVertical
                              images={product?.image}
                              clickHandler={setImageSelected}
                              currentSelection={imageSelected}
                            />
                          </Grid>
                        </Hidden>
                        <Grid item md={9}>
                          <Box textAlign="center">
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_HOST +
                                  "/files/product_images/" +
                                  product?.image[imageSelected]?.img || ""
                              }
                              className={classes.mainImage}
                              alt={product?.title[language] || ""}
                            />
                          </Box>
                        </Grid>

                        <Hidden smUp>
                          <Grid item xs={12}>
                            <ProductPhotoSliderHorizontal
                              images={product?.image}
                              clickHandler={setImageSelected}
                              currentSelection={imageSelected}
                            />
                          </Grid>
                        </Hidden>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography component="h1" className={classes.title}>
                        {product?.title[language] || ""}
                      </Typography>

                      <Grid container spacing={8} direction="row">
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography className={classes.imageTitle}>
                            <img
                              src="/images/product/pricelist.svg"
                              alt=""
                              width="22px"
                            />
                            &nbsp;&nbsp;&nbsp;
                            <FormattedMessage id="products.fullList" />
                          </Typography>
                          <Grid
                            container
                            spacing={0}
                            direction="row"
                            className={classes.tableBorders}
                          >
                            {product?.price.month ? (
                              <>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  className={classes.tableText}
                                >
                                  <Box alignSelf="center">
                                    <FormattedMessage id="products.month" />
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  className={classes.tablePrice}
                                >
                                  <Box alignSelf="center">
                                    €{" "}
                                    {parseFloat(product.price.month).toFixed(2)}{" "}
                                    <FormattedMessage id="products.day" />
                                  </Box>
                                </Grid>
                              </>
                            ) : null}
                            {product?.price.seven ? (
                              <>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  className={classes.tableText}
                                >
                                  <Box alignSelf="center">
                                    <FormattedMessage id="products.week" />
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  className={classes.tablePrice}
                                >
                                  <Box alignSelf="center">
                                    €{" "}
                                    {parseFloat(product.price.seven).toFixed(2)}{" "}
                                    <FormattedMessage id="products.day" />
                                  </Box>
                                </Grid>
                              </>
                            ) : null}
                            {product?.price.three ? (
                              <>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  className={classes.tableText}
                                >
                                  <Box alignSelf="center">
                                    <FormattedMessage id="products.3days" />
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  className={classes.tablePrice}
                                >
                                  <Box alignSelf="center">
                                    €{" "}
                                    {parseFloat(product.price.three).toFixed(2)}{" "}
                                    <FormattedMessage id="products.day" />
                                  </Box>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>

                          {product?.price.day ? (
                            <Box className={classes.priceBox}>
                              <span className={classes.priceText}>
                                <FormattedMessage id="products.day" />
                              </span>
                              &nbsp;
                              <span className={classes.price}>
                                €{parseFloat(product.price.day).toFixed(2)}
                              </span>
                              &nbsp;
                              <span className={classes.priceVAT}>
                                {parseFloat(product.price.dayVAT).toFixed(2)}
                                <FormattedMessage id="products.withVAT" />
                              </span>
                            </Box>
                          ) : null}
                          {product?.locations ? (
                            <Box pt={6}>
                              <Typography className={classes.imageTitle}>
                                <img
                                  src="/images/product/pricelist.svg"
                                  alt=""
                                  width="22px"
                                />
                                &nbsp;&nbsp;&nbsp; Pieejamība
                              </Typography>
                              <Grid
                                container
                                spacing={0}
                                direction="row"
                                className={classes.tableBorders}
                              >
                                {product?.locations.map((location) => (
                                  <>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      className={classes.tableText}
                                    >
                                      <Box alignSelf="center">
                                        {location.location}
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      className={classes.tablePrice}
                                    >
                                      <Box alignSelf="center">
                                        {location.amount}
                                      </Box>
                                    </Grid>
                                  </>
                                ))}
                              </Grid>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography className={classes.imageTitle}>
                            <img
                              src="/images/product/calendar.svg"
                              alt=""
                              width="22px"
                            />
                            &nbsp;&nbsp;&nbsp;
                            <FormattedMessage id="products.period" />
                          </Typography>
                          <Box pb={2}>
                            <DatePicker
                              selected={startDate}
                              onChange={(date: Date) => setStartDate(date)}
                              selectsStart
                              locale={lv}
                              startDate={startDate}
                              endDate={endDate}
                              minDate={moment().toDate()}
                              filterDate={isWeekday}
                              dateFormat="dd.MM.yyyy"
                              placeholderText={intl.formatMessage({
                                id: "products.startDate",
                              })}
                              monthsShown={1}
                              className={classes.inputInput}
                            />
                            {startDateError ? (
                              <span className={classes.labelError}>
                                {startDateError}
                              </span>
                            ) : null}
                          </Box>
                          <Box pb={2}>
                            <DatePicker
                              selected={endDate}
                              onChange={(date: Date) => setEndDate(date)}
                              selectsEnd
                              monthsShown={2}
                              locale={lv}
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate || moment().toDate()}
                              filterDate={isWeekday}
                              dateFormat="dd.MM.yyyy"
                              placeholderText={intl.formatMessage({
                                id: "products.endDate",
                              })}
                              className={classes.inputInput}
                            />
                            {endDateError ? (
                              <span className={classes.labelError}>
                                {endDateError}
                              </span>
                            ) : null}
                          </Box>
                          <ProductPriceCalculator
                            code={product?.code}
                            startDate={
                              moment(startDate).format("DD.MM.YYYY") || ""
                            }
                            endDate={moment(endDate).format("DD.MM.YYYY") || ""}
                          />
                          <Button
                            variant="contained"
                            size="large"
                            className={classes.button}
                            onClick={() => addCart()}
                          >
                            <FormattedMessage id="products.addToCart" />
                          </Button>
                          {cartMessage ? (
                            <Box pt={2} textAlign="center">
                              <Typography className={classes.price}>
                                {cartMessage}
                              </Typography>
                            </Box>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Box>
                        <Typography className={classes.lineTitle}>
                          <FormattedMessage id="products.description" />
                        </Typography>
                      </Box>

                      <Box className={classes.boxLine}></Box>

                      <Box pt={2}>{product?.description[language] || ""}</Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography className={classes.lineTitle}>
                        <FormattedMessage id="products.specification" />
                      </Typography>

                      <Box className={classes.boxLine}></Box>
                      <Box pt={2}>
                        {product?.attributes ? (
                          <Grid
                            container
                            spacing={0}
                            direction="row"
                            className={classes.tableBorders}
                          >
                            {product?.attributes.map((attr) => (
                              <>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  className={classes.tableText}
                                >
                                  <Box alignSelf="center" textAlign="center">
                                    {attr.name[language]}
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  className={classes.tablePrice}
                                >
                                  <Box alignSelf="center" textAlign="center">
                                    {attr.value}
                                  </Box>
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography className={classes.lineTitle}>
                        <FormattedMessage id="products.documentation" />
                      </Typography>
                      <Box className={classes.boxLine}></Box>
                      <Box pt={2}>
                        {product?.file ? (
                          <Grid container spacing={0} direction="row">
                            {product?.file.map((file) => (
                              <>
                                <Grid
                                  item
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  className={classes.tableImage}
                                >
                                  <Box alignSelf="right">
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={
                                        process.env.REACT_APP_IMAGE_HOST +
                                        "/files/product_files/" +
                                        file.name
                                      }
                                    >
                                      <img
                                        src={
                                          "/images/product/" +
                                          file.type +
                                          ".png"
                                        }
                                        alt={product?.title[language] || ""}
                                        height="40px"
                                      />
                                    </a>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  sm={10}
                                  md={10}
                                  className={classes.tableLink}
                                >
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={
                                      process.env.REACT_APP_IMAGE_HOST +
                                      "/files/product_files/" +
                                      file.name
                                    }
                                  >
                                    <Box
                                      alignSelf="center"
                                      className={classes.document}
                                      justifyContent="center"
                                    >
                                      {file.name}
                                    </Box>
                                  </a>
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        ) : null}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ))
        : null}
      {recentProducts.length > 0 ? (
        <Box
          pt={2}
          pb={1}
          pl={2}
          pr={2}
          bgcolor="#f9f9f9"
          className={classes.sliderBox}
        >
          <ProductSlider
            title={intl.formatMessage({ id: "byRecent" })}
            dataLoading={recentProductsLoading}
            data={recentProducts}
          />
        </Box>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ language, products, user }: ApplicationState) => ({
  language: language.language,
  loading: products.loadingId,
  errors: products.errors,
  products: products.product,
  recentProducts: products.viewedProducts,
  recentProductsLoading: products.viewedLoading,
  token: user.user["token"]
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    searchProduct: (type: string, id: string) => {
      dispatch(searchProduct(type, id));
    },
    searchProducts: (type: string) => {
      dispatch(searchProducts(type));
    },
    appendTreeSelection: (type: string, id: string) => {
      dispatch(appendTreeSelection(type, id));
    },
    addToCart: (request: any) => {
      dispatch(addCart(request));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
