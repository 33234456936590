import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: '170px',
            fontSize: '20px',
            backgroundColor: '#ffffff',
            border: '1px solid #e3e3e3',
            margin: '0px',
            maxHeight: '28px',
            padding: theme.spacing(1, 1, 1, 1),
        },
        focused: {
            border: '1px solid #e3e3e3',
            letterSpacing: '5px',
            margin: '0px'
        },
        outlinedInput: {
            '&$focused $notchedOutline': {
                border: '1px solid #e3e3e3'
            },
        },
        notchedOutline: {},
        labelError: {
            color: 'red',
            alignSelf: 'center',
            fontSize: '12px'
        },
    }),
);

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
            style={{ letterSpacing: 6 }}

        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

interface IProps {
    title: string,
    value: string,
    formChange: (e: ChangeEvent<HTMLInputElement>) => any,
}

const SmartIdInput: React.FC<IProps> = ({
    title,
    value,
    formChange
}) => {

    const classes = useStyles();

    return (
        <TextField
            value={value}
            onChange={formChange}
            name="ssn"
            id="ssn"
            type="text"
            size="small"
            variant="outlined"
            className={classes.textField}
            style={{ letterSpacing: 10 }}
            InputProps={{
                classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.notchedOutline,
                },
                inputComponent: TextMaskCustom as any,
            }}
        />
    );
}

export default (SmartIdInput);