import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../index";

export type AppThunk = ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
>;

export const getLink = async (invoice?: string): Promise<any> => {
  const token: string = localStorage.getItem("token") || "";
  if (token) {
    return await fetch(
      process.env.REACT_APP_API_HOST + "/bank/payment/order/" + invoice,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    );
  }
};

export const getStatus = async (
  order?: string,
  reference?: string
): Promise<any> => {
  const token: string = localStorage.getItem("token") || "";
  if (token) {
    return await fetch(
      process.env.REACT_APP_API_HOST +
        "/bank/payment/status/" +
        order +
        "/" +
        reference,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then((response) =>
      response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
      })
    );
  }
};
