import React from "react";
import { Route, Switch } from "react-router-dom";
import HeaderLine from "./components/HeaderLine";
import Landing from "./components/Landing";
import Footer from "./components/Footer";
import ProductList from "./components/ProductList";
import ProductDetails from "./components/ProductDetails";
import Contacts from "./components/Contacts";
import About from "./components/About";
import Profile from "./components/Profile";
import Register from "./components/Register";
import Gallery from "./components/Gallery";
import UserCart from "./components/Cart";
import Error404 from "./components/404";
import Rent from "./components/Rent";
import Transport from "./components/Transport";
import Repair from "./components/Repair";
import LocalHelmet from "./components/Helmet";
import Lend from "./components/Lend";
import Feedback from "./components/Feedback";
import Callback from "./components/Callback";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    },
  })
);

const Routes: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <HeaderLine />
      <Box alignItems="center" display="block">
        <Box className={classes.container}>
          <Switch>
            <Route exact path="/zinas" render={() => <Gallery />} />
            <Route exact path="/callback" render={() => <Callback />} />
            <Route exact path="/kontakti" render={() => <Contacts />} />
            <Route exact path="/atsauksmes" render={() => <Feedback />} />
            <Route exact path="/ka-iznomat" render={() => <Rent />} />
            <Route exact path="/iznoma-savu-tehniku" render={() => <Lend />} />
            <Route exact path="/par-mums" render={() => <About />} />
            <Route exact path="/transports" render={() => <Transport />} />
            <Route exact path="/remonts" render={() => <Repair />} />
            <Route exact path="/registracija" render={() => <Register />} />
            <Route exact path="/profile" render={() => <Profile />} />
            <Route
              exact
              path="/profile/:tab"
              render={(match) => <Profile tab={match.match.params.tab} />}
            />
            <Route
              exact
              path="/profile/:tab"
              render={(match) => <Profile tab={match.match.params.tab} />}
            />
            <Route
              exact
              path="/profile/:tab/:operation"
              render={(match) => (
                <Profile
                  tab={match.match.params.tab}
                  operation={match.match.params.operation}
                />
              )}
            />
            <Route exact path="/cart" render={() => <UserCart />} />
            <Route
              exact
              path="/product/category/:id"
              render={(match) => (
                <ProductList type="category" id={match.match.params.id} />
              )}
            />
            <Route
              exact
              path="/product/category"
              render={(match) => <ProductList type="category" />}
            />
            <Route
              exact
              path="/product/:id"
              render={(match) => (
                <ProductDetails type="id" id={match.match.params.id} />
              )}
            />
            <Route
              exact
              path="/"
              render={() => (
                <>
                  <LocalHelmet />
                  <Landing />
                </>
              )}
            />
            <Route
              path="*"
              render={() => (
                <>
                  <LocalHelmet />
                  <Error404 />
                </>
              )}
            />
          </Switch>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Routes;
